import axios from 'axios';
import { store } from 'redux/store';
import { createNotification } from 'react-redux-notify';
import { errorNotif, successNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';

export const upsertContacts = async ({ list_ids, contacts }) => {
  const { dispatch } = store;

  const data = { list_ids, contacts };
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/lists/contacts`;

  return axios
    .post(url, data, { headers: { token } })
    .then((res) => {
      dispatch(createNotification(successNotif('Contacts added to list')));
      dispatch(createNotification(successNotif('This can take a few minutes')));
      gtag.event({
        feature: 'Marketing',
        action: 'Contacts Upserted to List',
        message: list_ids
      });
      return res;
    })
    .catch((e) => {
      BugTracker.notify(e);

      const { errors } = e.response.data.response.body;
      errors.forEach((error) => {
        dispatch(createNotification(errorNotif(error.message)));
      });
    });
};
