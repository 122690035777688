import { Calculation } from 'types/calculatorInterfaces';
import { FunderDoc } from '../../interfaces';
import { EVatProfile } from './interface';

const isLease = (product: string) => product.toLowerCase().startsWith('lease');

const FunderFacility = ({
  FUNDER_DOC,
  Output
}: {
  FUNDER_DOC: FunderDoc;
  Output: Calculation;
}) => {
  //? This should Purchase Price (Without VAT) - NonVATableItems
  let amount = 0;
  if (!Output.FinanceProduct.includes('Loan')) {
    amount = Output['_NetAdvance'].Subsidy;
  }

  FUNDER_DOC.SUBSIDY = Output['_NetAdvance'].Subsidy;
  FUNDER_DOC.COST_EXCL_VAT_AND_NON_VATABLE_ITEMS =
    Output['_NetAdvance']?.Purchase_Price - amount;

  FUNDER_DOC.VAT = Output['_NetAdvance']?.Purchase_Vat;
  FUNDER_DOC.VAT_UPFRONT =
    Output['_NetAdvance']?.Vat_Payment === EVatProfile.VAT_UPFRONT;
  FUNDER_DOC.NON_VATABLE_ITEMS = Output['_NetAdvance']?.NonVATableItemsCost;

  FUNDER_DOC.SETTLEMENT_FIGURE = Output['_NetAdvance']?.Settlement;

  //? Includes VAT should include NonVATableItems
  FUNDER_DOC.I_COST_INC_VAT =
    Output['_NetAdvance']?.Purchase_Price +
    Output['_NetAdvance']?.Purchase_Vat +
    Output['_NetAdvance']?.NonVATableItemsCost;

  // this is the sum of the monthly payments based on the repayment profile so for example if the profile was a 1 x 36 it would be 1 monthly payment, if it was a 3 x 35 profile it would be the sum of 3 monthly payments
  FUNDER_DOC.II_ADVANCE_REPAYMENTS =
    Output['InitialRentals'] * Output['MonthlyPayment'];

  // this would be any additional payment he client decided to pay effectively additional deposit over the advanced repayments.
  FUNDER_DOC.III_ADDITIONAL_REPAYMENT =
    Output['Deposit'] - FUNDER_DOC.II_ADVANCE_REPAYMENTS;

  if (FUNDER_DOC.III_ADDITIONAL_REPAYMENT <= 0)
    FUNDER_DOC.III_ADDITIONAL_REPAYMENT = 0;

  // Requested By Lucy we are meant to be -ing Settlement Fee From Net Part Exchange Excluding Vat
  FUNDER_DOC.IV_NET_PART_EXCHANGE_EXCL_VAT =
    Output['_NetAdvance']?.Part_Exchange - Output['_NetAdvance']?.Settlement;

  FUNDER_DOC.V_VAT_ON_NET_PART_EXCHANGE =
    Output['_NetAdvance']?.Part_Exchange_Vat -
    Output['_NetAdvance']?.Settlement_Vat;

  if (Output['_NetAdvance']?.Vat_Payment === EVatProfile.VAT_UPFRONT) {
    FUNDER_DOC.VI_NET_VAT_PAID_UPFRONT =
      Output['_NetAdvance']?.Purchase_Vat -
      FUNDER_DOC.V_VAT_ON_NET_PART_EXCHANGE;
  }

  if (
    Output['_NetAdvance']?.Vat_Payment === EVatProfile.ADD_VAT_TO_NET ||
    Output['_NetAdvance']?.Vat_Payment === EVatProfile.DEFER_VAT
  ) {
    FUNDER_DOC.VI_NET_VAT_PAID_UPFRONT = 0; // Requested by Shamila to be 0 Because we don't pay upfront
    // Was this before: FUNDER_DOC.VI_NET_VAT_PAID_UPFRONT = Output['_NetAdvance']?.VatTotal;
  }

  // Total Initial Payment  = Total Purchase price + VAT and any non vatable items Less the NetAdvance ((effectively the total cost less any deposits, trade ins or subsidies)
  // Requested By Lucy we are meant to be -ing Settlement Fee From Total Init Payment

  // if (Output['_NetAdvance']?.Vat_Payment === 'DEFER_VAT') {
  //   FUNDER_DOC.TOTAL_INIT_PAYMENT =
  //     Output?.['_NetAdvance']?.Purchase_Price +
  //     Output?.['_NetAdvance']?.NonVATableItemsCost -
  //     Output?.['_NetAdvance']?.NetAdvance;
  // } else {
  //   FUNDER_DOC.TOTAL_INIT_PAYMENT =
  //     Output?.['_NetAdvance']?.Purchase_Price +
  //     Output?.['_NetAdvance']?.Purchase_Vat +
  //     Output?.['_NetAdvance']?.NonVATableItemsCost -
  //     Output?.['_NetAdvance']?.NetAdvance;
  // }

  if (isLease(FUNDER_DOC.FINANCE_PRODUCT)) {
    FUNDER_DOC.TOTAL_INIT_PAYMENT =
      FUNDER_DOC.II_ADVANCE_REPAYMENTS +
      FUNDER_DOC.III_ADDITIONAL_REPAYMENT +
      FUNDER_DOC.IV_NET_PART_EXCHANGE_EXCL_VAT;
  } else {
    FUNDER_DOC.TOTAL_INIT_PAYMENT =
      FUNDER_DOC.II_ADVANCE_REPAYMENTS +
      FUNDER_DOC.III_ADDITIONAL_REPAYMENT +
      FUNDER_DOC.IV_NET_PART_EXCHANGE_EXCL_VAT +
      FUNDER_DOC.V_VAT_ON_NET_PART_EXCHANGE +
      FUNDER_DOC.VI_NET_VAT_PAID_UPFRONT;
  }

  // AmountToFinance = NetAdvance + VatTotal if ADD_VAT_TO_NET true else = NetAdvance
  if (Output?.['_NetAdvance']?.Vat_Payment === EVatProfile.VAT_UPFRONT) {
    // Requested by Shamila we are meant to be -ing the Amount to Fiance with Advance Repayments

    //? This should just stay the same no matter if it's a Lease or a HP
    if (isLease(FUNDER_DOC.FINANCE_PRODUCT)) {
      FUNDER_DOC.AMOUNT_TO_FINANCE =
        FUNDER_DOC.COST_EXCL_VAT_AND_NON_VATABLE_ITEMS -
        FUNDER_DOC.TOTAL_INIT_PAYMENT;
    } else {
      FUNDER_DOC.AMOUNT_TO_FINANCE =
        FUNDER_DOC.I_COST_INC_VAT - FUNDER_DOC.TOTAL_INIT_PAYMENT;
    }
  } else if (
    Output?.['_NetAdvance']?.Vat_Payment === EVatProfile.ADD_VAT_TO_NET
  ) {
    FUNDER_DOC.AMOUNT_TO_FINANCE =
      FUNDER_DOC.I_COST_INC_VAT -
      FUNDER_DOC.II_ADVANCE_REPAYMENTS -
      FUNDER_DOC.III_ADDITIONAL_REPAYMENT -
      FUNDER_DOC.IV_NET_PART_EXCHANGE_EXCL_VAT -
      FUNDER_DOC.V_VAT_ON_NET_PART_EXCHANGE;
  } else if (Output?.['_NetAdvance']?.Vat_Payment === EVatProfile.DEFER_VAT) {
    FUNDER_DOC.AMOUNT_TO_FINANCE =
      FUNDER_DOC.I_COST_INC_VAT -
      FUNDER_DOC.II_ADVANCE_REPAYMENTS -
      FUNDER_DOC.III_ADDITIONAL_REPAYMENT -
      FUNDER_DOC.IV_NET_PART_EXCHANGE_EXCL_VAT -
      FUNDER_DOC.V_VAT_ON_NET_PART_EXCHANGE;
  }

  FUNDER_DOC.REPAYMENTS = `${Output['FundingProfile']} x £${Output['MonthlyPayment']}`;
  if (Output['_NetAdvance']?.Vat_Payment === EVatProfile.DEFER_VAT) {
    FUNDER_DOC.VAT_DEFERRAL_PERIOD = Output['_NetAdvance']?.VatDeferral;
  }

  return FUNDER_DOC;
};

export default FunderFacility;
