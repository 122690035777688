import { useState } from 'react';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useParams, useHistory } from 'react-router-dom';
import { IoMdContact } from 'react-icons/io';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import Table from 'components/SimpleTable';
import { TableFilters } from '../../components/TableFilters';
import { Status, SelectedFilters, Contact } from '../../types';
import { useMarketing } from '../../../context';
import { mapUserDefinitionIdToName } from 'helpers';
import CustomerTypeRow from '../../components/CustomerTypeRow';
import CreateCampaignInfo from './CreateCampaignInfo';
import {
  removeListContacts,
  fetchList,
  fetchListContacts
} from '../../functions';
import { SuccessButton } from 'common';
import { QueryKeys } from '../../../types';

const ListDetail = () => {
  const { listId } = useParams() as { listId: string };
  const queryClient = useQueryClient();
  const history = useHistory();
  const { loading: marketingDataLoading, marketingData } = useMarketing();

  const showCreateCampaignInfo = useLocation<{
    showCreateCampaignInfo: boolean;
  }>()?.state?.showCreateCampaignInfo;

  const [open, setOpen] = useState(showCreateCampaignInfo);

  const [hasSelected, setHasSelected] = useState<boolean>(
    !!showCreateCampaignInfo
  );
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    userGroup: [],
    customerType: [],
    status: []
  });

  const defaultList = {
    name: '',
    id: '',
    contact_count: 0
  };

  const { data: list = defaultList, isLoading } = useQuery(
    [QueryKeys.FetchList, listId],
    () => fetchList(listId),
    {
      enabled: !!listId
    }
  );

  const contactsQuery = [QueryKeys.FetchListContacts, listId];
  const { data: contacts = [], isLoading: contactsLoading } = useQuery(
    contactsQuery,
    () => fetchListContacts(listId),
    {
      enabled: !!(listId && marketingData.length && !marketingDataLoading),
      // transform data before its returned:
      select: (sendgridContacts) => {
        return marketingData
          .map((m) => {
            const sendgridContact = sendgridContacts.find(
              (c) => m.UserInstanceEmail.toLowerCase() === c.email.toLowerCase()
            ) as any;

            if (sendgridContact) {
              return {
                ...m,
                // NOTE: PendingContacts will not have an id, use userInstanceId instead (so mui table doesn't complain)
                id: sendgridContact.id || m.Id,
                status: sendgridContact.status
              };
            }
          })
          .filter(Boolean) as Contact[];
      }
    }
  );

  const {
    data: updatedContacts,
    mutate,
    isLoading: removeLoading
  } = useMutation(removeListContacts);

  const loading =
    isLoading || contactsLoading || marketingDataLoading || removeLoading;

  const columns = [
    { title: 'Name', field: 'Title' },
    { title: 'Email', field: 'UserInstanceEmail' },
    {
      title: 'User Group',
      field: 'UserDefinitionId',
      render: ({ UserDefinitionId }) =>
        mapUserDefinitionIdToName(UserDefinitionId),
      customFilterAndSearch: () => null // disables search filter on this column
    },
    {
      title: 'Customer Type',
      field: 'CustomerType',
      customFilterAndSearch: () => null,
      render: (rowData: Contact) => {
        return (
          <CustomerTypeRow
            currentCustomerType={rowData.CustomerType}
            userInstanceId={rowData.Id}
          />
        );
      }
    },
    {
      title: 'Status',
      field: 'status',
      customFilterAndSearch: () => null,
      render: (d) => {
        const StatusWithBlip = ({
          color,
          text
        }: {
          color: string;
          text: string;
        }) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: color,
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '5px'
                }}
              />
              <span style={{ color }} data-testid="list-status">
                {text}
              </span>
            </div>
          );
        };

        if (d.status === Status.Adding) {
          return <StatusWithBlip color={'#FFB347'} text={'Adding'} />;
        }

        if (d.status === Status.Deleting) {
          return <StatusWithBlip color={'#FF6347'} text={'Deleting'} />;
        }

        return <StatusWithBlip color={'#66CDAA'} text={'Ready'} />;
      }
    }
  ];

  const actions = [
    {
      icon: 'delete',
      tooltip: 'Remove contact',
      onClick: async (event, data: Contact[]) => {
        const contactsToRemove = data.map((d) => ({
          id: d.id,
          email: d.UserInstanceEmail.toLowerCase(),
          first_name: d.Title
        }));

        await mutate({
          contactsToRemove,
          totalToDelete: data.length,
          list,
          contacts
        });
      }
    },
    {
      icon: 'refresh',
      tooltip: 'Refresh Contacts',
      isFreeAction: true,
      onClick: () => queryClient.invalidateQueries(contactsQuery)
    },
    {
      icon: () => <SuccessButton>Add Contacts</SuccessButton>,
      tooltip: 'Add contacts',
      isFreeAction: true,
      onClick: (event, data) =>
        history.push({
          pathname: `/marketing/lists/${listId}/add-contacts`,
          state: { listName: list.name, listId: listId }
        })
    }
  ];

  const contactsData = updatedContacts ? updatedContacts : contacts;

  const filteredData = contactsData.filter((d: Contact) => {
    const { status, userGroup, customerType } = selectedFilters;

    return (
      (!userGroup.length || userGroup.includes(d.UserDefinitionId)) &&
      (!customerType.length || customerType.includes(d.CustomerType)) &&
      (!status?.length || status.includes(d.status))
    );
  });

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0',
          opacity: 0.8
        }}>
        <IoMdContact
          style={{
            marginRight: 10,
            fontSize: 50,
            color: 'grey'
          }}
        />
        <Typography
          variant="h3"
          style={{
            color: 'grey'
          }}>
          {list.name}
        </Typography>
      </div>

      <Alert severity="info" style={{ marginBottom: 15 }}>
        <Typography>{`It may take up to a few minutes for Contacts to be added to your List. Please click the refresh icon and keep an eye on the status.`}</Typography>
      </Alert>

      <Table
        title={`Contacts (${filteredData.length})`}
        searchPlaceholder={'Name or Email'}
        actions={actions}
        columns={columns}
        isLoading={loading}
        onSelectionChange={() => setHasSelected(true)}
        data={filteredData.sort((a, b) => {
          // Ready status (will be last)
          if (!a.status) return 1;
          if (!b.status) return -1;
          // 'Adding' status first, followed by 'Deleting'
          return a.status.localeCompare(b.status);
        })}
        options={{
          selection: true,
          pageSize: 10
        }}
        components={{
          Toolbar: (toolbarProps) => (
            <TableFilters
              {...toolbarProps}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              hasSelectedRows={hasSelected}
              renderStatus
            />
          )
        }}
      />
      <CreateCampaignInfo
        open={open}
        setIsOpen={() =>
          setOpen((prev) => {
            if (prev) return false;
            return true;
          })
        }
      />
    </>
  );
};

export default ListDetail;
