import { firebase } from 'redux/firebase';
import { Domain } from '../types';
import { BugTracker } from 'Utils/Bugtracker';

interface FetchDomains {
  isAFS: boolean;
  isSynergy: boolean;
  isSuperAdmin: boolean;
}

// NOTE: Only admins will be able to see the domain auth tab
export const fetchDomains = async ({
  isAFS,
  isSynergy,
  isSuperAdmin
}: FetchDomains): Promise<Domain[]> => {
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
  const global = 'global';
  const isDual = isSynergy && isAFS;

  try {
    // Super admins see ALL domains
    let ref: firebase.firestore.Query = firebase
      .firestore()
      .collection('domain');

    const isRegularAdmin = !isSuperAdmin;

    // Admins can see only domains for their instance or global domains
    if (isRegularAdmin) {
      ref = ref.where('hostname', 'in', [hostname, 'global']);
    }

    const snapshot = await ref.get();
    const domains: Domain[] = [];

    snapshot.forEach((doc) => {
      const domain = doc.data() as Domain;

      // If hostname is global, Filter based on AFS or Synergy
      if (domain.hostname === global) {
        if (!isDual) {
          if (isAFS && !domain.domain.includes('afs')) return;
          if (isSynergy && !domain.domain.includes('synergy')) return;
        }
      }

      domains.push(domain);
    });

    return domains;
  } catch (error) {
    BugTracker.notify(error);
    console.error('Error fetching domains: ', error);
    return [];
  }
};
