import { Typography, CircularProgress, Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { CustomTooltip } from '../../components';
import { snakeToTitleCase } from 'Utils';
import { EventDetails, EventNames, IStats } from '../types';

const useStyles = makeStyles((theme: Theme) => {
  const event = {
    height: '7rem',
    width: '7rem',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    border: `1px solid ${theme.palette.grey.A100}`,
    boxShadow: `0 0 0 5px white, 0 0 0 8px ${theme.palette.grey.A100}`,
    padding: theme.spacing(3),
    borderRadius: '100%',
    textAlign: 'center' as const,
    margin: '0 auto'
  };

  return createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: 10
    },
    event,
    eventHover: {
      ...event,
      '&:hover': {
        transition: 'all 0.3s ease-in-out',
        boxShadow: `0 0 10px 0px ${theme.palette.primary.main}`,
        cursor: 'pointer'
      }
    },
    eventPositive: {
      ...event,
      border: `1px solid #66CDAA`,
      boxShadow: `0 0 0 5px white, 0 0 0 8px #66CDAA`
    },
    eventNegativeStat: {
      ...event,
      border: `1px solid #FF6347`,
      boxShadow: `0 0 0 5px white, 0 0 0 8px #FF6347`
    },
    number: {
      fontSize: 30
    },
    date: {
      color: '#BEBEBE',
      marginTop: theme.spacing(1)
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
      gap: '30px',
      margin: '30px 0'
    }
  });
});

const spam_report_drops: keyof IStats = 'spam_report_drops';
const bounce_drops: keyof IStats = 'bounce_drops';
const clicks: keyof IStats = 'clicks';
const opens: keyof IStats = 'opens';
const unique_clicks: keyof IStats = 'unique_clicks';
const unique_opens: keyof IStats = 'unique_opens';

const mergeStats = (stats: IStats): IStats => {
  return {
    ...stats,
    // merge bounce_drops and spam_report_drops:
    bounces: stats.bounces + stats.bounce_drops,
    spam_reports: stats.spam_reports + stats.spam_report_drops
  };
};

interface Props {
  stats: IStats;
  loading: boolean;
  fetchEventDetails?: (event: EventNames, title: string) => void;
}

const EventStats = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const { loading, fetchEventDetails } = props;
  let { stats } = props;

  stats = mergeStats(stats);

  const isDashboard =
    history.location.pathname === '/marketing/dashboard' ||
    history.location.pathname === '/marketing';

  const keysToExclude: string[] = [spam_report_drops, bounce_drops];
  if (!isDashboard) keysToExclude.push(unique_clicks, unique_opens);

  return (
    <Box className={classes.gridContainer}>
      {Object.entries(EventDetails)
        .filter(([key]) => {
          return !keysToExclude.includes(key);
        })
        // stats with values first
        .sort(([aKey], [bKey]) => {
          const aValue = stats[aKey];
          const bValue = stats[bKey];
          if (aValue > 0 && bValue <= 0) return -1;
          if (aValue <= 0 && bValue > 0) return 1;
          return 0;
        })
        .map(([name, detail]) => {
          let title = snakeToTitleCase(name);

          if (isDashboard && (name === clicks || name === opens)) {
            title = `Total ${title}`;
          }

          const { event, isNegative, description } = detail;

          const stat = stats?.[name];

          const negativeOrPositiveClass =
            stat &&
            (isNegative ? classes.eventNegativeStat : classes.eventPositive);

          const isClickable = !!event && !isDashboard;

          const className = `${
            isClickable ? classes.eventHover : classes.event
          } ${negativeOrPositiveClass}`;

          const GridItem = (
            <CustomTooltip
              title={description}
              arrow
              enterDelay={500}
              key={name}>
              <Box
                className={className}
                onClick={() => {
                  if (!isClickable || loading) return;

                  fetchEventDetails?.(event, title);
                }}>
                <Typography>{title.toUpperCase()}</Typography>
                {!loading ? (
                  <Typography className={classes.number}>{stat}</Typography>
                ) : (
                  <div style={{ color: '#BEBEBE' }}>
                    <CircularProgress color="inherit" size={30} thickness={3} />
                  </div>
                )}
              </Box>
            </CustomTooltip>
          );

          if (isClickable)
            return (
              <a href="#campaign-table" key={name}>
                {GridItem}
              </a>
            );
          return GridItem;
        })}
    </Box>
  );
};

export default EventStats;
