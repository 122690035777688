import axios from 'axios';

import { BugTracker } from 'Utils/Bugtracker';
import { firebase } from 'redux/firebase';
import { List } from '../types';

export const fetchList = async (listId: string): Promise<List | undefined> => {
  try {
    const token = await firebase.auth().currentUser?.getIdToken();
    const listsUrl = `${process.env.REACT_APP_PROXY}/marketing/lists/${listId}`;

    const r = await axios.get(listsUrl, { headers: { token } });

    const sendgridData = r.data;

    // NOTE: names that have dashes will be problematic:
    const nameWithoutUserInstanceId = sendgridData.name.split('-')[0];

    const data = {
      ...sendgridData,
      name: nameWithoutUserInstanceId
    };

    return data as List;
  } catch (error) {
    BugTracker.notify(error);
  }
};
