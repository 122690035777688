import {
  ICosmeticsCreateProposalResponse,
  ICreateProposal
} from 'redux/database/Lender API/interface';
import {
  CompleteObjectInstance,
  FbFileRef,
  ObjectInstance
} from 'types/interfaces';
import {
  Aldermore,
  CAS,
  DLL,
  Fleximize,
  FundingCircle,
  Investec,
  MannIsland,
  Northridge,
  Novuna,
  Praetura,
  PremiumCredit,
  UltimateFinance
} from '../LenderAPI/consts';

export enum ELenderAPIType {
  // Options
  Dropdown = 'dropdown',
  Currency = 'currency',
  Number = 'number',
  Date = 'date',
  DateTime = 'DateTime',
  Boolean = 'boolean',
  Phone = 'phone',
  Percentage = 'Percentage',
  Float = 'float',

  // Unique Features
  // String[]
  OptionsList = 'OptionsList',
  // Boolean that will allow for Patterns or anything Required
  Requirement = 'Requirement',
  // Will make Object Array so we have a keyValue Pair
  CascadingDropdown = 'cascadingDropdown',

  // Will Convert our Entity Type to Lender APIs Entity NEEDS Typeof Dropdown
  Conversion = 'Conversion',

  //! ENTITY DEPENDENT PARENT IS LINKED "EntityDependent"
  // Used to find out which entity we are looking for.
  EntityDependentParent = 'entityDependentParent',

  // Hides Fields if they aren't for that Entity
  EntityDependent = 'entityDependent',
  //! END

  //! FINANCE PARENT IS LINKS "FianceDependent" & "FinanceDependentRequired"
  // This is what FianceDependent Looks for
  FinanceParent = 'financeParent',
  // Changes IsReadOnly to Those That are Contained Inside FinanceDependent
  FianceDependent = 'fianceDependent',
  // Changes Required to Those that are Contained Inside FinanceDependent
  FinanceDependentRequired = 'fianceDependentRequired',
  //! END
  // Allows for Splitting Up FirstName & Surname into 2 Fields
  ConcatenatedBySpace = 'concatenatedBySpace',
  // EntityChange have different FieldDefinitionIds for each Entity
  EntityChange = 'entityChange',
  // OnBlur will check the value that has been entered the correct format based on the pattern "Needs Pattern"
  OnBlur = 'onBlur'
}

// Used for when a field has a requirement, this will only work for String
export interface IRequirement {
  minLength?: number;
  maxLength?: number;
  dateLength?: number;
  pattern?: string;
  message?: string;
  maxAmount?: number;
  minAmount?: number;
}

export interface ILabelValueOption {
  label: string;
  value: number | string | null;
}

export interface LenderFieldDefinition {
  type: ELenderAPIType | ELenderAPIType[];
  options?: string[] | ILabelValueOption[];
  dependsOn?: string[];
  getOptions?: (...args: any[]) => string[];
  newIdList?: {
    entityType: string;
    FieldDefinitionId: number;
    additionalTypes?: ELenderAPIType[];
    forcedValue?: string | number | boolean;
  }[];
  requirement?: IRequirement;
  condition?: string;
}
export interface IOptionalKeywords {
  forcedValue?: string | number | boolean;
  required?: boolean;
  info?: string;

  FieldDefinition?: LenderFieldDefinition;
  entityFieldDefinitionId?: {
    entity: string;
    FieldDefinitionId: number;
  }[];
}
export interface IMappedLenderIds extends IOptionalKeywords {
  FieldDefinitionId: number;
  isReadonly: boolean;
}

export interface INameFieldConfig {
  firstNameKeys: string[];
  lastNameKeys: string[];
  middleNameKeys: string[];
}

export type TLenderAPIInterfaces =
  | typeof Aldermore
  | typeof CAS
  | typeof DLL
  | typeof Fleximize
  | typeof FundingCircle
  | typeof Investec
  | typeof MannIsland
  | typeof Northridge
  | typeof Novuna
  | typeof Praetura
  | typeof PremiumCredit
  | typeof UltimateFinance;

export interface IStructuredField {
  value: string | number | boolean;
  FieldDefinitionId: number;
  ObjectInstanceId: number;
  FieldDefinition?: LenderFieldDefinition;
  infoField?: string;
  isReadonly: boolean;
  isRequired: boolean;
  isHidden: boolean;
  error?: string | null;
}

/**
 * Defines a rule for conditional field visibility in a proposal form
 */
interface IFieldVisibilityRule {
  /** The path to the field that should be conditionally shown/hidden (e.g., 'client.soleTrader') */
  field: string;

  /** The path to the field whose value determines visibility (e.g., 'client.companyType') */
  dependsOn: string;

  /** Array of values that, when present in the dependsOn field, will show the target field */
  showWhen: string[];
}

/**
 * Defines the structure and configuration for a lender's proposal
 */
export interface IProposalStructure {
  /** Default values and field structure for the proposal form */
  defaultValues: TLenderAPIInterfaces;

  /**
   * Maps fields to their corresponding lender API IDs
   * Can be undefined for imported data where mapping isn't needed
   */
  mappedIds: TMappedIds;

  /**
   * Indicates if the lender integration is active/enabled
   * @todo Consider changing 'any' to a more specific type
   */
  status: boolean;

  /** Whether the proposal supports document uploads */
  uploadDocuments: boolean;

  /**
   * Minimum requirements for various entity counts
   * Used to validate proposal completeness
   */
  requirements: {
    /** Minimum number of directors required */
    minDirectors?: number;
    /** Minimum number of shareholders required */
    minShareholders?: number;
    /** Minimum number of assets required */
    minAssets?: number;
    /** Minimum number of address required */
    minAddress?: number;
  };

  /** The identifier/name of the lender */
  lenderTitle: ELender;

  /**
   * Array of deal flow IDs that are allowed for this proposal type
   * Used to restrict proposal creation to specific deal flows
   */
  allowedDealFlow: number[];

  /**
   * Optional array of entity types that are allowed for this proposal
   * If undefined, all entity types are allowed
   */
  allowedEntities?: string[];

  /**
   * Optional array of visibility rules that determine when certain fields
   * should be shown or hidden based on other field values
   * Example: Show soleTrader fields only when companyType is 'SOLETRADER' or 'PRIVATEINDIVIDUAL'
   */
  fieldVisibility?: IFieldVisibilityRule[];
}

export interface IProposal extends IProposalStructure {
  id: string;
  lenderId: number;
  lenderTitle: ELender;
  decision: ELenderDecision;
  requestedStatus: ELenderStatus;
  CompleteObjectInstance: CompleteObjectInstance;
  files: FbFileRef[] | null;
  isImported?: boolean;
  uniqueId: string;
}

export interface IProposalObjects {
  targetObjectInstance: ObjectInstance;
  selectedObjectInstance: ObjectInstance | null;
}

export type TAttachedFile = File | FbFileRef;
export interface IProposalAttachedFiles {
  name: string;
  fileId?: string;
  key: string;
  url: string;
  type: TAttachedFile;
}

export type TMappedIds = TTypeSafeRecord<TLenderAPIInterfaces>;
export type TTypeSafeRecord<T> = {
  [K in keyof T]?: T[K] extends (infer U)[]
    ? TTypeSafeRecord<U>[]
    : T[K] extends object
    ? TTypeSafeRecord<T[K]>
    : IMappedLenderIds;
};

export type JSONValue =
  | string
  | number
  | boolean
  | null
  | JSONValue[]
  | { [key: string]: JSONValue };

export type JSONObject = { [key: string]: JSONValue };
export enum ELender {
  Aldermore = 'Aldermore',
  CAS = 'CAS',
  Investec = 'Investec',
  Quantum = 'Quantum',
  Northridge = 'Northridge',
  Novuna = 'Novuna',
  Praetura = 'Praetura',
  PremiumCredit = 'PremiumCredit',
  DLL = 'DLL',
  Fleximize = 'Fleximize',
  FundingCircle = 'FundingCircle',
  Hitachi = 'Hitachi',
  MannIsland = 'MannIsland',
  MetroBank = 'MetroBank',
  Paragon = 'Paragon',
  Shawbrook = 'Shawbrook',
  UltimateFinance = 'UltimateFinance'
}

export enum ELenderStatus {
  APPROVED = 'APPROVED',
  APPROVED_EXPIRED = 'APPROVED_EXPIRED',
  CANCELLED = 'CANCELLED',
  DEPOSITED = 'DEPOSITED',
  FAILED = 'FAILED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  TEST = 'TEST',
  IN_PROGRESS = 'IN_PROGRESS',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  DECISION_MADE = 'DECISION_MADE',
  DRAFT = 'DRAFT'
}

export enum ELenderDecision {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  OFFERED = 'OFFERED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING'
}
