import { useState, Fragment, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import {
  PercentageField,
  CurrencyField,
  NewCurrencyField,
  roundToTwo,
  calculateAdvance
} from '../Utils/inputFields';
import MainField from '../components/MainField';
import { _NetAdvance } from 'types/calculatorInterfaces';

import { Tools } from '../Calculator/interfaces';
import { useCalculator } from '../Calculator/hooks/useCalculator';
import { useProcess } from 'hooks';
import { CompleteProcessDefinition } from 'types/interfaces';
import _ from 'lodash';

const isNotLease = (product: string) =>
  !product.toLowerCase().startsWith('lease');

const NetAdvance = ({
  tools,
  currentProcess,
  isLoan
}: {
  tools: Tools;
  currentProcess: CompleteProcessDefinition;
  isLoan: boolean;
}) => {
  const { props, state, setState, config } = tools;
  const { setSnackBar, handleChange, clearNetAdvance, isforAmount } =
    useCalculator(tools);

  const [open, setOpen] = useState(false);
  const shouldOpen = () => setOpen(true);
  let calculate = () => onSubmit();

  const [vatTotal, setVatTotal] = useState<number>(
    state._NetAdvance.Purchase_Vat
  );

  const [grossAdvance, setGrossAdvance] = useState<number>(0);
  const [percent, setPercent] = useState<number>(0);
  const [pounds, setPounds] = useState<number>(0);

  const onSubmit = () => {
    if (isforAmount) {
      setOpen(false);
    } else {
      if (state._NetAdvance.Purchase_Price > 0) {
        setOpen(false);
      } else {
        setSnackBar({
          open: true,
          variant: 'warning',
          message: 'A Purchase Price is required.'
        });
      }
    }
  };

  useEffect(() => {
    if (state._NetAdvance.Deposit <= 0) {
      if (state._NetAdvance.NetAdvance <= 0) {
        setPercent(0);
      }
    } else {
      const isGrossAdvance =
        state._NetAdvance.Vat_Payment === 'ADD_VAT_TO_NET' ||
        state._NetAdvance.Vat_Payment === 'DEFER_VAT';

      // Shamila / Mike Has Requested Deposit To Be Calculated Based On The Purchase Price
      // const Advance = calculateAdvance(state, true, isGrossAdvance);

      const Purchase_Price = state._NetAdvance.Purchase_Price;
      if (
        (state._NetAdvance.Deposit > 0 && pounds <= 0) ||
        isNaN(Number(percent)) ||
        percent <= 0
      ) {
        setPounds(state._NetAdvance.Deposit);
        if (Purchase_Price > 0) {
          let newPercent = (state._NetAdvance.Deposit / Purchase_Price) * 100;
          setPercent(roundToTwo(newPercent));
        }
      }
    }
  }, [state._NetAdvance.Purchase_Price]);

  const purchaseVis = config.Amount && config.Amount.show;
  useEffect(() => {
    if (
      state._NetAdvance.Vat_Payment === 'ADD_VAT_TO_NET' ||
      state._NetAdvance.Vat_Payment === 'DEFER_VAT'
    ) {
      let VatTotal: number =
        state._NetAdvance.Purchase_Vat +
        state._NetAdvance.Settlement_Vat -
        state._NetAdvance.Deposit_Vat -
        state._NetAdvance.Part_Exchange_Vat -
        state._NetAdvance.Subsidy_Vat;

      setGrossAdvance(state._NetAdvance.NetAdvance + VatTotal);
    } else {
      setGrossAdvance(0);
    }
  }, [state._NetAdvance.Vat_Payment, state._NetAdvance.NetAdvance]);

  // const isGrossAdvance =
  //   state._NetAdvance.Vat_Payment === 'ADD_VAT_TO_NET' ||
  //   state._NetAdvance.Vat_Payment === 'DEFER_VAT';

  const calculateValues = _.debounce(() => {
    // Shamila / Mike Has Requested Deposit To Be Calculated Based On The Purchase Price
    //const Advance = calculateAdvance(state, true, isGrossAdvance);

    const Purchase_Price = state._NetAdvance.Purchase_Price;
    if (pounds > 0 && percent > 0) {
      let newPounds = (Number(percent) / 100) * Purchase_Price;

      setPounds(newPounds);
      let myEventTargetObject = {
        target: {
          value: newPounds
        }
      };

      handleChange('Deposit')(myEventTargetObject);
    }
  }, 500);

  useEffect(() => {
    if (!state._NetAdvance.isLoading) {
      calculateValues();
    } else {
      setPounds(state._NetAdvance.Deposit);

      // Shamila / Mike Has Requested Deposit To Be Calculated Based On The Purchase Price
      // const Advance = calculateAdvance(state, true, isGrossAdvance);
      const Purchase_Price = state._NetAdvance.Purchase_Price;
      let newPercent = (state._NetAdvance.Deposit / Purchase_Price) * 100;
      setPercent(newPercent);
    }

    return () => {
      calculateValues.cancel();
    };
  }, [state._NetAdvance.Purchase_Price]);

  useEffect(() => {
    try {
      let settlementVat = 0;
      if (
        state._NetAdvance?.Settlement_Vat === null &&
        state._NetAdvance?.Settlement
      ) {
        const settlementVatRate = state._NetAdvance?.Settlement_VatRate ?? 20;
        settlementVat =
          parseFloat(state._NetAdvance.Settlement.toString()) *
          (settlementVatRate / 100);
      } else {
        settlementVat =
          parseFloat(state._NetAdvance?.Settlement_Vat?.toString() ?? '0') || 0;
      }

      let partExchangeVat = 0;
      if (
        state._NetAdvance?.Part_Exchange_Vat === null &&
        state._NetAdvance?.Part_Exchange
      ) {
        const partExchangeVatRate =
          state._NetAdvance?.Part_Exchange_VatRate ?? 20;
        partExchangeVat =
          parseFloat(state._NetAdvance.Part_Exchange.toString()) *
          (partExchangeVatRate / 100);
      } else {
        partExchangeVat =
          parseFloat(state._NetAdvance?.Part_Exchange_Vat?.toString() ?? '0') ||
          0;
      }

      let purchaseVat = 0;
      if (
        state._NetAdvance?.Purchase_Vat === null &&
        state._NetAdvance?.Purchase_Price
      ) {
        const purchaseVatRate = state._NetAdvance?.Purchase_VatRate ?? 20;
        purchaseVat =
          parseFloat(state._NetAdvance.Purchase_Price.toString()) *
          (purchaseVatRate / 100);
      } else {
        purchaseVat =
          parseFloat(state._NetAdvance?.Purchase_Vat?.toString() ?? '0') || 0;
      }

      const getVAT = Math.abs(settlementVat - partExchangeVat);
      const totalVat = Math.abs(purchaseVat - getVAT);

      setVatTotal(parseFloat(totalVat.toFixed(2)));
    } catch (error) {
      console.error('Error calculating VAT:', error);
      setVatTotal(0);
    }
  }, [
    state._NetAdvance.Settlement,
    state._NetAdvance.Part_Exchange,
    state._NetAdvance.Purchase_Vat
  ]);

  // Requested By Lucy that Motor Finance Product Linked here: https://support.bips.tech/scp/tickets.php?id=6410
  useEffect(() => {
    const createEventObject = (value: number) => ({
      target: { value }
    });

    const determineVatRate = () => {
      const isMotorFinance =
        state.FinanceProduct.toLowerCase().startsWith('motor');
      if (isMotorFinance || !isNotLease(state.FinanceProduct)) {
        return 0;
      }

      return 20;
    };

    const vatRate = determineVatRate();
    handleChange('Purchase_VatRate')(createEventObject(vatRate));
    state._NetAdvance.Purchase_VatRate = vatRate;

    // Handle Lease specific logic
    if (!isNotLease(state.FinanceProduct)) {
      setState((prevState) => ({
        ...prevState,
        _NetAdvance: {
          ...prevState._NetAdvance,
          Vat_Payment: 'VAT_UPFRONT',
          VatTotal: 0,
          VatDeferral: 0
        }
      }));
    }
  }, [state.FinanceProduct]);

  let isLoanDeal;
  if (Object.keys(currentProcess).length !== 0) {
    const Title = currentProcess?.ProcessDefinition?.Title;
    isLoanDeal = Title.includes('Loan');
  }

  return (
    <Fragment>
      <MainField
        isLoanDeal={isLoanDeal}
        name="Advance"
        onClick={shouldOpen}
        required={!isforAmount}
        value={state.Amount}
        valueToChange={state._NetAdvance.Purchase_Price}
        handleChange={handleChange}
        type={'Purchase_Price'}
        calculate={calculate}
      />

      <Dialog open={open}>
        <DialogTitle>{`Calculate ${
          isLoan ? 'Loan Amount' : 'Net Advance'
        }`}</DialogTitle>

        <DialogContent>
          <br />
          <Grid
            alignItems="flex-start"
            container
            direction="column"
            justifyContent="center">
            <Grid item style={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid item sm={4} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={purchaseVis ? false : true}
                    error={undefined}
                    name={isLoan ? 'Loan Amount' : 'Purchase Price'}
                    onBlur={undefined}
                    onChange={handleChange('Purchase_Price')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required
                    startAdornment={undefined}
                    value={state._NetAdvance.Purchase_Price}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <PercentageField
                    InputLabelProps={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={purchaseVis ? false : true}
                    name="VAT RATE %"
                    onBlur={undefined}
                    onChange={handleChange('Purchase_VatRate')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    style={undefined}
                    type={undefined}
                    value={state._NetAdvance.Purchase_VatRate}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="VAT"
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._NetAdvance.Purchase_Vat}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid item sm={4} xs={12}>
                  <NewCurrencyField
                    name="Deposit"
                    value={state._NetAdvance.Deposit}
                    onChange={handleChange}
                    calculate={calculate}
                    error={false}
                    adornment={undefined}
                    startAdornment={undefined}
                    InputLabelProps={undefined}
                    setPounds={setPounds}
                    setPercent={setPercent}
                    pounds={pounds}
                    percent={percent}
                    state={state}
                    setState={setState}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <PercentageField
                    InputLabelProps={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={undefined}
                    name="VAT RATE %"
                    onBlur={undefined}
                    onChange={handleChange('Deposit_VatRate')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    style={undefined}
                    type={undefined}
                    value={state._NetAdvance.Deposit_VatRate}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="VAT"
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._NetAdvance.Deposit_Vat}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!isLoanDeal && (
              <>
                <Grid item sm={4} xs={12} style={{ width: '100%' }}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={undefined}
                    error={undefined}
                    name="Non Vatable Item"
                    onBlur={undefined}
                    onChange={handleChange('NonVATableItemsCost')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._NetAdvance.NonVATableItemsCost}
                  />
                </Grid>

                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        error={undefined}
                        name="Settlement figure"
                        onBlur={undefined}
                        onChange={handleChange('Settlement')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._NetAdvance.Settlement}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <PercentageField
                        InputLabelProps={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        name="VAT RATE %"
                        onBlur={undefined}
                        onChange={handleChange('Settlement_VatRate')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        style={undefined}
                        type={undefined}
                        value={state._NetAdvance.Settlement_VatRate}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="VAT"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._NetAdvance.Settlement_Vat}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        error={undefined}
                        name="Part Exchange"
                        onBlur={undefined}
                        onChange={handleChange('Part_Exchange')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._NetAdvance.Part_Exchange}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <PercentageField
                        InputLabelProps={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        name="VAT RATE %"
                        onBlur={undefined}
                        onChange={handleChange('Part_Exchange_VatRate')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        style={undefined}
                        type={undefined}
                        value={state._NetAdvance.Part_Exchange_VatRate}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="VAT"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._NetAdvance.Part_Exchange_Vat}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        error={undefined}
                        name="Subsidy"
                        onBlur={undefined}
                        onChange={handleChange('Subsidy')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._NetAdvance.Subsidy}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <PercentageField
                        InputLabelProps={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        name="VAT RATE %"
                        onBlur={undefined}
                        onChange={handleChange('Subsidy_VatRate')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        style={undefined}
                        type={undefined}
                        value={state._NetAdvance.Subsidy_VatRate}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="VAT"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._NetAdvance.Subsidy_Vat}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item sm={grossAdvance > 0 ? 3 : 4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="Net Advance"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._NetAdvance.NetAdvance}
                      />
                    </Grid>

                    {isNotLease(state.FinanceProduct) && (
                      <>
                        {grossAdvance > 0 && (
                          <Grid item sm={3} xs={12}>
                            <CurrencyField
                              InputLabelProps={undefined}
                              adornment={undefined}
                              calculate={undefined}
                              classes={undefined}
                              disabled
                              error={undefined}
                              name="Gross Advance"
                              onBlur={undefined}
                              onChange={undefined}
                              onClick={undefined}
                              onKeyUp={undefined}
                              required={undefined}
                              startAdornment={undefined}
                              value={grossAdvance}
                            />
                          </Grid>
                        )}
                        <Grid item sm={grossAdvance > 0 ? 3 : 4} xs={12}>
                          <TextField
                            fullWidth
                            label="VAT Payment"
                            margin="dense"
                            onChange={handleChange('Vat_Payment')}
                            select
                            value={state._NetAdvance.Vat_Payment}>
                            <MenuItem value={'VAT_UPFRONT'}>
                              {' '}
                              Pay VAT upfront{' '}
                            </MenuItem>
                            <MenuItem value={'ADD_VAT_TO_NET'}>
                              Add VAT To Net Advance
                            </MenuItem>
                            <MenuItem value={'DEFER_VAT'}> Defer VAT </MenuItem>
                          </TextField>
                        </Grid>

                        <Grid item sm={grossAdvance > 0 ? 3 : 4} xs={12}>
                          <CurrencyField
                            InputLabelProps={undefined}
                            adornment={undefined}
                            calculate={undefined}
                            classes={undefined}
                            disabled
                            error={undefined}
                            name="VAT Total"
                            onBlur={undefined}
                            onChange={undefined}
                            onClick={undefined}
                            onKeyUp={undefined}
                            required={undefined}
                            startAdornment={undefined}
                            value={vatTotal}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>

                {state._NetAdvance.Vat_Payment === 'DEFER_VAT' && (
                  <Grid item style={{ paddingTop: 15 }}>
                    <Grid
                      alignItems="center"
                      container
                      justifyContent="center"
                      spacing={1}>
                      <Grid item>
                        <Typography>Pay VAT in month: </Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          fullWidth
                          name="VAT Deferal Period"
                          onChange={handleChange('VatDeferral')}
                          select
                          type="number"
                          value={state._NetAdvance.VatDeferral}>
                          {[0, 1, 2, 3, 4, 5, 6].map((e, i) => (
                            <MenuItem key={i} value={e}>
                              {e}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setPercent(0);
              setPounds(0);
              clearNetAdvance();
            }}>
            Clear All
          </Button>
          <div style={{ flexGrow: 1 }} />
          <Button color="primary" onClick={() => setOpen(false)}>
            Cancel
          </Button>

          <Button
            data-cy="done-net-advance-btn"
            color="primary"
            onClick={onSubmit}
            variant="contained">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default NetAdvance;

// useEffect(() => {
//   let NetAdvance: number =
//     state.Purchase_Price +
//     state.Settlement +
//     state.NonVATableItemsCost -
//     state.Deposit -
//     state.Part_Exchange -
//     state.Subsidy;
//   let VatTotal: number =
//     state.Purchase_Vat +
//     state.Settlement_Vat -
//     state.Deposit_Vat -
//     state.Part_Exchange_Vat -
//     state.Subsidy_Vat;
//   let TotalDeposit: number =
//     state.Deposit + state.Part_Exchange + state.Subsidy;

//   if ([3, 2, 1, 0].indexOf(Calculation) > -1) {
//     NetAdvance = 0;
//     VatTotal = 0;
//   }
//   setState((s) => ({ ...s, NetAdvance, VatTotal, TotalDeposit }));
// }, [
//   state.Purchase_Vat,
//   state.Deposit_Vat,
//   state.Settlement_Vat,
//   state.Part_Exchange_Vat,
//   state.Subsidy_Vat,
//   state.NonVATableItemsCost,
//   state.Purchase_Price,
//   state.Deposit
// ]);
