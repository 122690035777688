import DLLProposal from '.';
import { ELender, ELenderAPIType } from '../../interface';
import { createTypeSafeIds } from '../../utils';

export const getDLLIds = () => {
  return createTypeSafeIds(DLLProposal, (_) => ({
    financeProduct: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Dropdown,
        options: ['hire-purchase', 'financial-lease']
      }
    },
    lessee: {
      id: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: false
      },
      legalEntity: {
        id: {
          FieldDefinitionId: 21745,
          isReadonly: false,
          info: 'id of the legal entity',
          required: true
        }
      },

      preferredLocale: {
        FieldDefinitionId: 0,
        isReadonly: true,
        forcedValue: 'en-GB'
      },
      uniqueIdentifier: {
        FieldDefinitionId: 18315,
        isReadonly: true
      },
      uniqueIdentifierType: {
        FieldDefinitionId: 0,
        isReadonly: true,
        forcedValue: 'organization-number'
      },
      countryOfRegistration: {
        FieldDefinitionId: 0,
        isReadonly: true,
        forcedValue: 'GB'
      },
      businessType: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: [ELenderAPIType.Dropdown, ELenderAPIType.EntityChange],
          options: [
            'association-of-physicians',
            'government',
            'private-physicians',
            'other'
          ]
        }
      },
      businessActivity: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: [
            'biotechnology-company',
            'city-hospital',
            'consultant',
            'dental-laboratory',
            'dental-specialist',
            'dentist',
            'diagnostic-center',
            'general-practitioner',
            'medical-care-center',
            'ophthalmologist',
            'optician',
            'private-hospital',
            'radiologist',
            'veterinarians',
            'other'
          ]
        }
      },
      externalIdentifiers: [
        {
          type: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: ['medical', 'dental', 'public-service-number', 'other']
            }
          },

          id: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            info: 'The registration number or identity number as per the records of authority mentioned in the type.'
          }
        }
      ],
      phoneNumbers: [
        {
          type: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: ['mobile', 'home', 'business', 'other']
            }
          },
          number: {
            FieldDefinitionId: 18525,
            required: true,
            isReadonly: false,
            info: 'The actual phone number. This should include country code, area code and number',
            FieldDefinition: {
              type: [ELenderAPIType.Phone, ELenderAPIType.EntityChange],
              newIdList: [
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 23625
                },

                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 21874
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 21874
                }
              ]
            }
          }
        }
      ],
      email: {
        FieldDefinitionId: 18525,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
          requirement: {
            maxLength: 255,
            pattern:
              '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
            message: 'Please enter a valid email address'
          },
          newIdList: [
            {
              entityType: 'Sole Trader',
              FieldDefinitionId: 21873
            },
            {
              entityType: 'Partnership',
              FieldDefinitionId: 21873
            }
          ]
        }
      },
      organization: {
        name: {
          FieldDefinitionId: 18317,
          isReadonly: false,
          required: true,
          info: 'The commercial name for the company',
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Sole Trader',
                FieldDefinitionId: 21866
              },
              {
                entityType: 'Partnership',
                FieldDefinitionId: 21866
              }
            ]
          }
        },
        establishmentDate: {
          FieldDefinitionId: 18450,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        cityOfRegistration: {
          FieldDefinitionId: 0,
          isReadonly: true,
          forcedValue: 'UK'
        }
      },
      individual: {
        givenName: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        familyName: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 21810
              }
            ]
          }
        },
        birthDate: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: [
              ELenderAPIType.Date,
              ELenderAPIType.Requirement,
              ELenderAPIType.EntityChange
            ],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            },
            newIdList: [
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 21810
              }
            ]
          }
        },
        sex: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Dropdown],
            options: ['female', 'male', 'would-rather-not-say']
          }
        }
      },
      address: {
        street: {
          FieldDefinitionId: 18431,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 23962
              },

              {
                entityType: 'Sole Trader',
                FieldDefinitionId: 23662
              },
              {
                entityType: 'Partnership',
                FieldDefinitionId: 23662
              }
            ]
          }
        },
        country: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: `The official (ISO) two-letter country code, e.g "gb"`,
          forcedValue: 'gb'
        },
        postalCode: {
          FieldDefinitionId: 18438,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 12,
              pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
              message:
                "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
            }
          }
        },
        city: {
          FieldDefinitionId: 18435,
          isReadonly: false,
          required: true
        }
      },
      invoiceAddress: {
        street: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        country: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: `The official (ISO) two-letter country code, e.g "gb"`,
          forcedValue: 'gb'
        },
        postalCode: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 12,
              pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
              message:
                "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
            }
          }
        },
        city: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        email: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
            requirement: {
              maxLength: 255,
              pattern:
                '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
              message: 'Please enter a valid email address'
            }
          }
        }
      },
      previousAddress: {
        street: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        postalCode: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 12,
              pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
              message:
                "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
            }
          }
        },
        city: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        country: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: `The official (ISO) two-letter country code, e.g "gb"`,
          forcedValue: 'gb'
        }
      },
      contacts: [
        {
          name: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            info: 'Name of the contact',
            FieldDefinition: {
              type: ELenderAPIType.EntityChange,
              newIdList: [
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 21810
                },

                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 23976
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 23976
                }
              ]
            }
          },
          email: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
              requirement: {
                maxLength: 255,
                pattern:
                  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                message: 'Please enter a valid email address'
              },
              newIdList: [
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 21810
                },

                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 23976
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 23976
                }
              ]
            }
          },
          phoneNumbers: [
            {
              type: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['mobile', 'home', 'business', 'other']
                }
              },
              number: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                info: 'The actual phone number. This should include country code, area code and number',
                FieldDefinition: {
                  type: ELenderAPIType.Phone
                }
              }
            }
          ]
        }
      ],
      bankDetails: {
        accountNumber: {
          FieldDefinitionId: 21803,
          isReadonly: false,
          info: 'The account number at the given bank; required if paymentMethod = direct-debit'
        },
        accountNumberType: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['iban', 'bban']
          }
        },
        bankIdentifierType: {
          FieldDefinitionId: 21804,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Dropdown],
            options: ['bic', 'sort-code']
          }
        },
        paymentMethod: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'standing-order',
              'direct-debit',
              'cheque',
              'state-payment',
              'telegraphic-transfer'
            ]
          }
        }
      },
      partners: [
        {
          givenName: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            info: 'The given name of the individual'
          },
          familyName: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            info: 'The family name of the individual'
          },

          birthDate: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          mobileNumber: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Phone
            }
          },
          address: {
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            country: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              info: `The official (ISO) two-letter country code, e.g "gb"`,
              forcedValue: 'gb'
            },
            postalCode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 12,
                  pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                  message:
                    "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                }
              }
            },
            city: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            }
          },
          externalIdentifiers: [
            {
              id: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                info: 'The registration number or identity number as per the records of authority mentioned in the type.'
              },
              type: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: [
                    'medical',
                    'dental',
                    'public-service-number',
                    'other'
                  ]
                },
                info: 'The registration number or identity number as per the records of authority mentioned in the type.'
              }
            }
          ]
        }
      ],
      directors: [
        {
          givenName: {
            FieldDefinitionId: 18427,
            isReadonly: false,
            required: true,
            info: 'The given name of the individual'
          },
          familyName: {
            FieldDefinitionId: 18420,
            isReadonly: false,
            required: true,
            info: 'The family name of the individual'
          },

          birthDate: {
            FieldDefinitionId: 18416,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          }
        }
      ]
    },
    proposals: [
      {
        startDate: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'currentDate',
          FieldDefinition: {
            type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        endDate: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
            requirement: {
              pattern: '^\\d{4}-\\d{2}-\\d{2}$'
            }
          }
        },
        preferred: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        },
        financialProduct: {
          vatFinancing: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: 'Specifies if the VAT on the asset will be funded. Specify true if the VAT should be funded. Omit or specify false if this should not be the case.',
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          contractType: {
            FieldDefinitionId: 22740,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: [
                'financial-lease',
                'operating-lease',
                'loan',
                'renting',
                'loan-with-pledge',
                'contract-hire',
                'hire-purchase',
                'fair-market-value'
              ]
            }
          }
        },
        calculation: {
          financeInput: {
            financeStructure: {
              numberOfMonths: {
                FieldDefinitionId: 23271,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              numberOfPayments: {
                FieldDefinitionId: 23905,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              delayPayment: [
                {
                  type: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: ['regular', 'all']
                    }
                  },
                  initialPause: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Number
                    }
                  },
                  initialPauseUnit: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: ['days', 'months']
                    }
                  },
                  startDate: {
                    FieldDefinitionId: 0,
                    isReadonly: true,
                    forcedValue: 'currentDate',
                    FieldDefinition: {
                      type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                      requirement: {
                        pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                      }
                    }
                  }
                }
              ],
              terminalPauseRegularPayments: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              paymentFrequency: {
                FieldDefinitionId: 18333,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['monthly', 'quarterly', 'semi-annually', 'annually']
                }
              },
              paymentTiming: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['in-advance', 'in-arrears']
                }
              },
              paymentMethod: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: [
                    'standing-order',
                    'direct-debit',
                    'cheque',
                    'state-payment',
                    'telegraphic-transfer'
                  ]
                }
              },
              delayVatPayment: {
                FieldDefinitionId: 23839,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              finance: {
                financeAmount: {
                  FieldDefinitionId: 23958,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                initialPaymentMultiplier: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                initialPaymentAmount: {
                  FieldDefinitionId: 23957,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                balloon: {
                  FieldDefinitionId: 21325,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                netDeposit: {
                  amount: {
                    FieldDefinitionId: 23820,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  downPayment: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  settlement: {
                    FieldDefinitionId: 23827,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  settlementContractType: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: [
                        'financial-lease',
                        'operating-lease',
                        'loan',
                        'renting',
                        'loan-with-pledge',
                        'contract-hire',
                        'hire-purchase',
                        'fair-market-value'
                      ]
                    }
                  },
                  tradeIn: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  }
                },
                payments: {
                  custom: [
                    {
                      paymentDate: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true,
                        FieldDefinition: {
                          type: [
                            ELenderAPIType.Date,
                            ELenderAPIType.Requirement
                          ],
                          requirement: {
                            pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                          }
                        }
                      },
                      type: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true,
                        FieldDefinition: {
                          type: ELenderAPIType.Dropdown,
                          options: [
                            'regular',
                            'non-regular-initial',
                            'non-regular-balloon'
                          ]
                        }
                      },
                      amount: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        FieldDefinition: {
                          type: ELenderAPIType.Currency
                        }
                      },
                      multiplier: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        FieldDefinition: {
                          type: ELenderAPIType.Number
                        }
                      },
                      percentage: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        FieldDefinition: {
                          type: ELenderAPIType.Percentage
                        }
                      },
                      percentageOf: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true,
                        FieldDefinition: {
                          type: [ELenderAPIType.Dropdown],
                          options: ['sale-price']
                        }
                      }
                    }
                  ]
                },
                rates: {
                  effectiveCustomerRate: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Percentage
                    }
                  },
                  calculate: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: [
                        'vendor-subsidy',
                        'dealer-subsidy',
                        'manufacturer-subsidy',
                        'margin'
                      ]
                    }
                  },
                  variableNominalInterestRate: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Boolean
                    }
                  }
                },
                paymentProfile: {
                  type: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true,
                    FieldDefinition: {
                      type: ELenderAPIType.Dropdown,
                      options: ['seasonal-pattern', 'stepped-pattern']
                    }
                  }
                },
                commissions: [
                  {
                    amount: {
                      FieldDefinitionId: 18335,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    }
                  }
                ],
                subsidies: [
                  {
                    type: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      required: true,
                      FieldDefinition: {
                        type: ELenderAPIType.Dropdown,
                        options: [
                          'vendor-subsidy',
                          'dealer-subsidy',
                          'manufacturer-subsidy'
                        ]
                      }
                    },
                    amount: {
                      FieldDefinitionId: 23833,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    }
                  }
                ]
              }
            },
            fees: [
              {
                amount: {
                  FieldDefinitionId: 18327,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                }
              }
            ],
            insurances: [
              {
                amount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                insuranceTaxPercentage: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Percentage
                  }
                }
              }
            ],
            maintenance: [
              {
                amount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                quantity: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  forcedValue: '1',
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                }
              }
            ],
            assets: [
              {
                id: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  info: 'id of the asset. Either id or modelIdentifier is required to identify the asset. If both are provided the asset id is leading and modelIdentifier ignored.'
                },
                modelIdentifier: {
                  masterAssetTypeName: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    required: true
                  },
                  categoryName: {
                    FieldDefinitionId: 0,
                    isReadonly: false
                  },
                  typeName: {
                    FieldDefinitionId: 0,
                    isReadonly: false
                  },
                  brandName: {
                    FieldDefinitionId: 0,
                    isReadonly: false
                  }
                },
                quantity: {
                  FieldDefinitionId: 18313,
                  isReadonly: false,
                  forcedValue: '1',
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                salePrice: {
                  FieldDefinitionId: 18314,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                listPrice: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                vatPercentage: {
                  FieldDefinitionId: 20693,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Percentage
                  }
                },
                vatAmount: {
                  FieldDefinitionId: 21166,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                condition: {
                  FieldDefinitionId: 18311,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: ['new', 'used', 'ex-demo']
                  }
                },
                yearOfManufacturing: {
                  FieldDefinitionId: 18312,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: [ELenderAPIType.Number]
                  }
                },
                subLeased: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Boolean
                  }
                },
                meterRead: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                usageUnitsPerPeriod: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Number
                  }
                },
                residualValue: {
                  value: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  }
                },
                repurchaseAmount: {
                  amount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  }
                },
                excessiveUsageLimit: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                excessiveUsagePenaltyAmount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                fees: [
                  {
                    amount: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    }
                  }
                ],
                subsidies: [
                  {
                    type: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      required: true,
                      FieldDefinition: {
                        type: ELenderAPIType.Dropdown,
                        options: [
                          'vendor-subsidy',
                          'dealer-subsidy',
                          'manufacturer-subsidy'
                        ]
                      }
                    },
                    amount: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    }
                  }
                ],
                insurances: [
                  {
                    amount: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    },
                    insuranceTaxPercentage: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Percentage
                      }
                    },
                    replacement: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Boolean
                      }
                    },
                    excessAmount: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    }
                  }
                ],
                maintenance: [
                  {
                    amount: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      FieldDefinition: {
                        type: ELenderAPIType.Currency
                      }
                    },
                    quantity: {
                      FieldDefinitionId: 0,
                      isReadonly: false,
                      forcedValue: '1',
                      FieldDefinition: {
                        type: ELenderAPIType.Number
                      }
                    }
                  }
                ],
                instances: [
                  {
                    serialNumber: {
                      FieldDefinitionId: 18454,
                      isReadonly: false
                    },
                    chassisNumber: {
                      FieldDefinitionId: 18454,
                      isReadonly: false
                    },
                    licenseNumber: {
                      FieldDefinitionId: 21938,
                      isReadonly: false
                    },
                    location: {
                      street: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true
                      },
                      country: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true,
                        forcedValue: 'GB'
                      },
                      postalCode: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true,
                        FieldDefinition: {
                          type: ELenderAPIType.Requirement,
                          requirement: {
                            maxLength: 12,
                            pattern:
                              '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                            message:
                              "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                          }
                        }
                      },
                      city: {
                        FieldDefinitionId: 0,
                        isReadonly: false,
                        required: true
                      }
                    }
                  }
                ]
              }
            ]
          },
          financeCalculation: {
            financeAmount: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            financeDeviation: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            futureValue: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            netPresentValue: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            rates: {
              nominalInterestRate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              effectiveInterestRate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              nominalCustomerRate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              nominalCustomerRateIncludingFees: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              effectiveCustomerRate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Percentage
                }
              },
              effectiveCustomerRateIncludingFees: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              compoundingFrequency: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['monthly', 'quarterly', 'semi-annually', 'annually']
                }
              }
            }
          },
          consolidatedPayments: [
            {
              numberOfPayments: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              amount: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              type: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: [
                    'regular',
                    'non-regular-initial',
                    'non-regular-balloon'
                  ]
                }
              },
              startDate: {
                FieldDefinitionId: 0,
                isReadonly: true,
                required: true,
                forcedValue: 'currentDate',
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              }
            }
          ],
          commissions: [
            {
              amount: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              }
            }
          ],
          subsidies: [
            {
              type: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: [
                    'vendor-subsidy',
                    'dealer-subsidy',
                    'manufacturer-subsidy'
                  ]
                }
              },
              amount: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              }
            }
          ],
          payments: [
            {
              paymentDate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              },
              balanceOutstanding: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Currency
                }
              },
              rentalPayment: {
                amount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                principal: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                },
                interestAmount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                }
              },
              vatPayment: {
                amount: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Currency
                  }
                }
              },
              fees: [
                {
                  amount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  vatAmount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  }
                }
              ],
              insurances: [
                {
                  amount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  vatAmount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  insuranceTaxAmount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  }
                }
              ],
              maintenance: [
                {
                  amount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  },
                  vatAmount: {
                    FieldDefinitionId: 0,
                    isReadonly: false,
                    FieldDefinition: {
                      type: ELenderAPIType.Currency
                    }
                  }
                }
              ]
            }
          ]
        }
      }
    ]
  }));
};
