import { useContext, useEffect, useState } from 'react';
import { MessageHubProvider } from '../../';
import {
  Message,
  FileAttachment,
  NullableOption,
  Recipient
} from '@microsoft/microsoft-graph-types';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  useTheme,
  Typography
} from '@material-ui/core';
import Time from 'common/Time';
import Xpansion from 'common/Xpansion';
import { getAttachmentsList } from '../../functions';
import { useToken } from '../../hooks';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { useTypedSelector } from 'redux/reducers';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Thread, IEmail, IEmailObject } from '../../interfaces';
import { UserInstance } from 'types/interfaces';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

import { getCurrentEmail } from 'redux/actions/email';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { EmailEditor } from '../../components';
import { theme } from 'theme';

import { SystemInbox } from './SystemInbox';
import { MicrosoftInbox } from './MicrosoftInbox';
import { useProcess } from 'hooks';
import {
  getCompleteUserInstanceDetail,
  getQuickLiteUser
} from 'redux/actions/GraphQlActions';

const useConversationStyles = makeStyles((theme: Theme) =>
  createStyles({
    TitleBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.background.default,
      color: 'white',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(2)
    },
    Title: {
      color: theme.palette.grey[500]
    }
  })
);

const Conversation = () => {
  const classes = useConversationStyles();
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const [fixedToContact, setFixedToContact] = useState<UserInstance>(
    {} as UserInstance
  );

  const { deal } = useTypedSelector((s) => s.dealSummary);

  const MessageProvider = useContext(MessageHubProvider);
  const {
    threads,
    selectedThreadKey,
    conversation,
    getConversation,
    systemEmailLog
  } = MessageProvider;
  let thread = {} as Thread;
  if (selectedThreadKey && threads) thread = threads?.[selectedThreadKey];
  const fixedTo: number | undefined = thread.fixedTo;

  const getFixedToUser = async (fixedTo: number) => {
    const response = await getQuickLiteUser({
      baseUrl,
      UserInstanceId: fixedTo,
      action: 'GET'
    });

    const UserInstance = response?.UserInstance;
    if (UserInstance) setFixedToContact(UserInstance);
  };

  useEffect(() => {
    selectedThreadKey && getConversation();
  }, [selectedThreadKey]);

  useEffect(() => {
    if (fixedTo) getFixedToUser(fixedTo);
  }, [fixedTo]);

  const filterByConversationID = (
    conversation: Message[] | null,
    currentThreadType?: string,
    currentMemberID?: string
  ) => {
    if (!deal.ruleConversations || !currentMemberID || !currentThreadType)
      return [];

    const ruleForCurrentMember = deal.ruleConversations[currentMemberID];
    if (!ruleForCurrentMember) return [];

    const conversationIDForThread = ruleForCurrentMember[currentThreadType];
    if (!conversationIDForThread) return [];

    return (
      conversation?.filter(
        (message: Message) => message.conversationId === conversationIDForThread
      ) ?? []
    );
  };

  const removeDuplicates = (conversation: Message[] | null) => {
    if (!conversation) return [];

    const map = new Map<string, Message>();
    conversation.forEach((obj: Message) => {
      const hasAttachments = obj.hasAttachments ? 'true' : 'false';
      const uniqueKey = obj.hasAttachments
        ? `${obj.subject}-${obj.sender?.emailAddress?.address}-${hasAttachments}-${obj.sentDateTime}`
        : `${obj.subject}-${obj.sender?.emailAddress?.address}-${obj.sentDateTime}`;

      if (!map.has(uniqueKey)) {
        map.set(uniqueKey, obj);
      }
    });

    return [...map.values()];
  };

  let filteredMessages: Message[] | null = [];
  if (selectedThreadKey !== null) {
    const key = threads?.[selectedThreadKey];
    if (!deal.ruleConversations) filteredMessages = conversation;
    else {
      if (key) {
        const ruleList = deal.ruleConversations[key && key?.members[1]];
        if (key?.members && ruleList) {
          if (ruleList[thread.type]) {
            filteredMessages = filterByConversationID(
              conversation,
              key?.type,
              key?.members[1]
            );
          }
        }
      }
    }
  }

  const filteredAndUniqueMessages = removeDuplicates(filteredMessages);
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <div className={classes.TitleBox}>
          <Typography variant="h3" className={classes.Title}>
            {`Microsoft 365 Inbox`.toUpperCase()}
          </Typography>
        </div>

        <MicrosoftInbox thread={thread} fixedToContact={fixedToContact} />
        {filteredAndUniqueMessages.map((message, index) => (
          <MessageCard message={message} key={index} thread={thread} />
        ))}
      </Grid>
      <Grid item xs={12}>
        <div className={classes.TitleBox}>
          <Typography variant="h3" className={classes.Title}>
            {`System Inbox`.toUpperCase()}
          </Typography>
        </div>

        <SystemInbox thread={thread} fixedToContact={fixedToContact} />
      </Grid>
    </Grid>
  );
};

export default Conversation;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white'
    }
  })
);

export const MessageCard = ({
  message,
  thread,
  isSystem
}: {
  message: Message;
  thread: Thread;
  isSystem?: boolean;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isAuth = useIsAuthenticated();
  const { accounts, instance, inProgress } = useMsal();
  const { user: UserInstance } = useProcess();
  const { hasAttachments, id } = message;
  const { deal } = useTypedSelector((s) => s.dealSummary);

  const [expanded, setExpanded] = useState(false);
  const [user, setUser] = useState<any>([]);
  const [attachments, setAttachments] = useState<FileAttachment[]>([]);
  const [newBody, setNewBody] = useState<string>('');

  const loggedInUser = useTypedSelector((s) => s.user);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const getSystemEmailBody = async () => {
    const data: IEmail = await getCurrentEmail({ Id: message.id });
    if (data) setNewBody(data.EmailBody);
  };

  const getConversationIdsForThread = (thread, deal) => {
    if (!deal.ruleConversations) return [];
    const threadType = thread.type;
    const memberId = thread.members[1];
    const ruleForCurrentMember = deal.ruleConversations[memberId];
    if (!ruleForCurrentMember) return [];
    const conversationIds = Object.values(ruleForCurrentMember).filter(
      () => threadType === 'broker.contact'
    );
    return conversationIds;
  };
  const conversationIdForThread =
    thread.type === 'broker.contact'
      ? getConversationIdsForThread(thread, deal)
      : [thread.conversationId];

  const isMessageInConversation = (message, conversationIdsForThread) => {
    return conversationIdsForThread.includes(message.conversationId);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    // if this is a system we need to fetch the html content
    if (isSystem) getSystemEmailBody();
    setExpanded(isExpanded ? panel : false);
  };

  const listAttachments = async () => {
    const accessToken = await useToken({
      isAuth,
      accounts,
      instance,
      inProgress
    });

    if (hasAttachments && id) {
      const attachmentsList = await getAttachmentsList(accessToken, id);
      setAttachments(attachmentsList);
    } else {
      // some Basic JS for Strings. Both "InlineImg" & "ReplaceInline" Find & Remove Img from Inline.
      let bodyContent = message?.body?.content;
      if (bodyContent != null) {
        let InlineImg = String(bodyContent).match(
          /<img.*?src="([^"]*)"[^>]*>(?:<\/img>)?/m
        );

        if (InlineImg && id) {
          let replaceInlineWithAttachments = String(bodyContent).replace(
            /<img.*?src="([^"]*)"[^>]*>(?:<\/img>)?/g,
            ''
          );

          const attachmentsList = await getAttachmentsList(accessToken, id);
          setAttachments(attachmentsList);
          setNewBody(replaceInlineWithAttachments);
        }
      }
    }
  };

  useEffect(() => {
    handleCopyTo();
  }, [thread]);

  const handleCopyTo = async () => {
    let copyToArray: string[] = [];
    thread.members.forEach(async (element) => {
      const UserInstance = (await getCompleteUserInstanceDetail({
        baseUrl,
        UserInstanceId: parseInt(element),
        action: 'UserInstance'
      })) as UserInstance;

      if (UserInstance) {
        copyToArray.push(UserInstance.UserInstanceEmail);
      }
    });
    return setUser(copyToArray);
  };

  const downloadAttachment = async (a: FileAttachment) => {
    if (a.contentBytes) {
      let contentType = a.contentType || '';
      const sliceSize = 1024;
      const byteCharacters = atob(a.contentBytes);
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);
        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      const file = new Blob(byteArrays, { type: contentType });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  };

  useEffect(() => {
    listAttachments();
  }, [message]);

  const iSentEmail =
    loggedInUser.user?.UserInstanceEmail ===
    message?.from?.emailAddress?.address;

  const Links = () => {
    return (
      <Grid container style={{ padding: theme.spacing(1) }} spacing={2}>
        {attachments?.map((a: FileAttachment, i: number) => (
          <Grid item key={i}>
            <Button
              variant="contained"
              style={{ color: 'blue' }}
              onClick={() => downloadAttachment(a)}>
              {a.name}
              <AttachFileIcon />
            </Button>
          </Grid>
        ))}
      </Grid>
    );
  };

  const spx = `${theme.spacing(1)}px`;
  const bspx = `${theme.spacing(8)}px`;

  const margin = iSentEmail
    ? `${spx} ${spx} ${spx} ${bspx}`
    : `${spx} ${bspx} ${spx} ${spx}`;

  const isInConversation =
    thread.type === 'broker.contact'
      ? isMessageInConversation(message, conversationIdForThread)
      : message.conversationId === thread.conversationId;
  //const isInConversation = message.conversationId === thread.conversationId;
  const background = isInConversation
    ? iSentEmail
      ? '#128C7E'
      : '#075E54'
    : isSystem
    ? `${theme.palette.primary.light}`
    : theme.palette.error.dark;

  return (
    <Paper elevation={3} style={{ margin: 8 }}>
      <Card style={{ background }}>
        <CardHeader
          classes={{ title: classes.root }}
          title={message.subject}
          subheader={hasAttachments && <Links />}
          action={
            <Time
              time={message.createdDateTime}
              type="timeago"
              style={{ color: 'white' }}
            />
          }
        />

        <CardContent>
          <ToAndFrom message={message} UserInstance={UserInstance} />
          <div>
            <Xpansion
              summary={message.subject}
              expanded={expanded}
              onChange={handleChange(message.id)}>
              <Body
                body={message.body}
                attachments={attachments}
                newBody={newBody}
                downloadAttachment={downloadAttachment}
              />
            </Xpansion>
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
};

const ToAndFrom = ({
  message,
  UserInstance
}: {
  message: Message;
  UserInstance: UserInstance;
}) => {
  const theme = useTheme();
  return (
    <Grid
      container
      style={{ color: 'white', fontSize: 12, padding: theme.spacing(1) }}
      direction="column">
      <Grid item>
        <p>
          From: {message.from?.emailAddress?.name}{' '}
          {`(${message.from?.emailAddress?.address})`}
        </p>
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <p style={{ marginRight: '3px' }}>To:</p>
          {message &&
            message.toRecipients &&
            message?.toRecipients.map(
              (recipient: NullableOption<Recipient>, idx: number, arr: any) => {
                if (recipient) {
                  return (
                    <span key={idx}>
                      {recipient.emailAddress?.name}{' '}
                      {`(${recipient.emailAddress?.address})`}
                      {idx < arr.length - 1 ? ',  ' : ''}
                    </span>
                  );
                }
                return null;
              }
            )}
        </Grid>
      </Grid>
      <Grid item>
        {message.ccRecipients && message.ccRecipients.length > 0 && (
          <Grid container direction="row" alignItems="center">
            <p style={{ marginRight: '3px' }}>CC:</p>
            {message.ccRecipients.map((recipient, idx, arr) => {
              return recipient ? (
                <span key={`cc-${idx}`}>
                  {recipient.emailAddress?.name}{' '}
                  {`(${recipient.emailAddress?.address})`}
                  {idx < arr.length - 1 ? ', ' : ''}
                </span>
              ) : null;
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const Body = ({ body, attachments, downloadAttachment, newBody }) => {
  const { contentType, content } = body;
  const transform = (node: any, index: number) => {
    switch (node.name) {
      case 'a': {
        if (node?.children?.[0]?.data?.includes('http')) {
          const link = node?.children?.[0]?.data;
          const split = link.split('/landingpage');
          const host = location.host;
          const connect =
            process.env.NODE_ENV === 'development' ? 'http' : 'https';
          const mockLink = `${connect}://${host}/landingpage${split[1]}`;

          const showLink = [
            'http://localhost:3000',
            'https://test.bips.tech',
            'https://staging-bips.netlify.app'
          ].includes(location.origin);

          if (showLink) {
            return (
              <a href={mockLink} target="_blank" rel="noreferrer">
                <Button
                  data-cy="landing-page-link"
                  color="primary"
                  size="small"
                  style={{ margin: 10 }}
                  variant="contained">
                  Landing Page Link
                  <DoubleArrowIcon />
                </Button>
              </a>
            );
          } else {
            return <div />;
          }
        } else {
          return node?.children?.[0]?.data;
        }
      }

      case 'img': {
        const src = node.attribs?.src || '';
        const isBase64 = src.startsWith('data:image');
        const isExternalUrl = src.startsWith('http') || src.startsWith('https');

        const removeTrackingPixel = (url) => {
          if (url.includes('imageAPI.ashx')) {
            return false;
          }
          return url;
        };

        const originalStyle = node.attribs?.style || '';
        const width = node.attribs?.width;
        const height = node.attribs?.height;
        const border = node.attribs?.border;

        if (isBase64) {
          return (
            <div
              key={index}
              style={{
                backgroundImage: `url(${src})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}>
              <img
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                alt=""
                style={{
                  visibility: 'hidden',
                  height: '50%',
                  width: '50%'
                }}
              />
            </div>
          );
        } else if (isExternalUrl) {
          const processedSrc = removeTrackingPixel(src);
          if (!processedSrc) return null;

          const imgProps = {
            key: index,
            src: processedSrc,
            alt: node.attribs?.alt || '',
            ...(width && { width }),
            ...(height && { height }),
            ...(border && { border }),
            ...(originalStyle && { style: originalStyle })
          };

          return <img {...imgProps} />;
        }

        return null;
      }

      default:
        return convertNodeToElement(node, index, transform);
    }
  };

  switch (contentType) {
    case 'html':
      return (
        <div style={{ width: '100%' }}>
          <RenderContent
            contentType={contentType}
            attachments={attachments}
            newBody={newBody}
            content={content}
            downloadAttachment={downloadAttachment}
            transform={transform}
          />
        </div>
      );
    default:
      return <div>{newBody != '' ? newBody : content}</div>;
  }
};

const InlineImages = ({ attachments, downloadAttachment }) => {
  return (
    <div
      style={{
        paddingBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadius
      }}>
      {Object.values(attachments)?.map((element: any, index: number) => {
        return (
          <Button
            key={index}
            style={{
              width: 300,
              height: 300,
              margin: 'auto'
            }}
            variant="contained"
            onClick={() => {
              downloadAttachment(element);
            }}>
            <img
              alt={element?.name || ''}
              style={{
                width: '300px',
                height: '300px',
                borderRadius: theme.shape.borderRadius
              }}
              src={`data:${element?.contentType};base64,${element?.contentBytes}`}
            />
          </Button>
        );
      })}
    </div>
  );
};

const RenderContent = ({
  contentType,
  attachments,
  newBody,
  content,
  downloadAttachment,
  transform
}) => {
  const inlineImages =
    attachments && attachments[0] && newBody !== '' ? (
      <InlineImages
        attachments={attachments}
        downloadAttachment={downloadAttachment}
      />
    ) : null;

  const htmlContent = (
    <div>
      {inlineImages}
      {ReactHtmlParser(newBody !== '' ? newBody : content, { transform })}
    </div>
  );

  const defaultContent = <div>{newBody !== '' ? newBody : content}</div>;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {contentType === 'html' ? htmlContent : defaultContent}
    </div>
  );
};
