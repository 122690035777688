import axios from 'axios';
import { store } from 'redux/store';
import { createNotification } from 'react-redux-notify';
import { successNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { Domain } from '../types';

export const deleteDomain = async (data: Domain) => {
  const { dispatch } = store;
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/domains/${data.id}`;

  try {
    await axios.delete(url, {
      headers: { token },
      data
    });

    dispatch(createNotification(successNotif('Domain deleted')));

    gtag.event({
      feature: 'Marketing',
      action: 'Domain Deletion: Success',
      message: `${data.id} deleted on ${hostname}`
    });
  } catch (e) {
    BugTracker.notify(e);
  }
};
