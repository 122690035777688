// src/redux/reducers/noticeboardReducer.ts
import {
  FETCH_NOTICEBOARD_REQUEST,
  FETCH_NOTICEBOARD_SUCCESS,
  FETCH_NOTICEBOARD_FAILURE,
  CLEAR_NOTICEBOARD,
  NoticeboardInstance
} from '../actions/types';

export interface NoticeboardState {
  host?: NoticeboardInstance;
  global?: NoticeboardInstance;
  brand?: NoticeboardInstance;
  loading: boolean;
  error: string | null;
}

const initialState: NoticeboardState = {
  host: undefined,
  global: undefined,
  brand: undefined,
  loading: false,
  error: null
};

export const noticeboardReducer = (
  state = initialState,
  action: any
): NoticeboardState => {
  switch (action.type) {
    case FETCH_NOTICEBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_NOTICEBOARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null
      };

    case FETCH_NOTICEBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CLEAR_NOTICEBOARD:
      return initialState;

    default:
      return state;
  }
};
