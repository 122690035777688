import React, { useEffect, useLayoutEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { firebase } from 'redux/firebase';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { useTypedSelector } from 'redux/reducers';
import NoticeBoard from 'views/Profile/NoticeBoard';
import { BugTracker } from 'Utils/Bugtracker';
import { useDispatch } from 'react-redux';
import { getNoticeboardData } from 'services/NoticeBoardService';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = () => {
  if (window.disableNoticeBoard) return null;
  const dispatch = useDispatch();
  const { user } = useTypedSelector((s) => s.user);
  const noticeboard = useTypedSelector((s) => s.noticeboard);
  const { BrandList } = useTypedSelector((s) => s.config.settings);

  const subSystemUser = user.SystemAccess <= 4;

  const [open, setOpen] = React.useState(false);
  const [dismissed, setDismissed] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const handleClose = () => setOpen(false);

  const handleDismissal = async () => {
    try {
      setDismissed(true);
      setOpen(false);
      const data = {
        AllBoardsModified: {
          InstanceBoardLastModified: noticeboard?.host?.timestamp || '',
          BrandBoardLastModified: noticeboard?.brand?.timestamp || '',
          GlobalBoardLastModified: noticeboard?.global?.timestamp || ''
        }
      };

      await firebase
        .firestore()
        .collection('userAccount')
        .doc(user.Id.toString())
        .set(data, { merge: true });
    } catch (error) {
      console.error('Error during dismissal:', error);
      BugTracker.notify(error);
    }
  };

  useEffect(() => {
    const checkNoticeBoard = async () => {
      if (!user.Id || !noticeboard || dismissed) return;

      try {
        const quickNoticeBoard = await getNoticeboardData({
          BrandList,
          dispatch,
          setIsLoading,
          BugTracker
        });

        const snapshot = await firebase
          .firestore()
          .collection('userAccount')
          .doc(user.Id.toString())
          .get();

        // if the user account does not exist, set the dismissed flag to true
        if (snapshot.exists) {
          const data = snapshot.data();
          const storedData = data?.AllBoardsModified || {};

          const stored = storedData.InstanceBoardLastModified;
          const config =
            quickNoticeBoard?.localhost?.currentNoticeboardInstance?.timestamp;

          const isEqual = stored === config;

          if (!isEqual) setOpen(true);
          if (isEqual) setDismissed(true);
        } else {
          setDismissed(true);
        }
      } catch (error) {
        console.error('Error checking notice board:', error);
        BugTracker.notify(error);
      } finally {
        setLoading(false);
      }
    };

    checkNoticeBoard();
  }, [user.Id, dismissed]);

  if (subSystemUser || loading || dismissed) return null;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent>{open && <NoticeBoard />}</DialogContent>
      <DialogActions>
        <Button onClick={handleDismissal} color="primary">
          Dismiss Noticeboard
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          data-cy="noticeboard_close_btn">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialogSlide;
