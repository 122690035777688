import { useState, useEffect, Dispatch, SetStateAction, useMemo } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { Box } from '@material-ui/core';

interface Props {
  itemsPerPage?: number;
  allItems: any[];
  setPaginatedItems: Dispatch<SetStateAction<any[]>>;
}

const Paginator = ({
  itemsPerPage = 9,
  allItems = [],
  setPaginatedItems
}: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(allItems.length / itemsPerPage);

  // Memoize paginated items to prevent unnecessary recalculations
  const paginatedItems = useMemo(() => {
    if (allItems.length === 0) return [];
    return allItems.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [currentPage, itemsPerPage, allItems]);

  // Update paginated items when calculated or on page change
  useEffect(() => {
    if (allItems.length > 0) {
      setPaginatedItems(paginatedItems);
    }
  }, [paginatedItems, allItems.length, setPaginatedItems]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, value) => setCurrentPage(value)}
      />
    </Box>
  );
};

export default Paginator;
