import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  Fade,
  withStyles
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useFileStorage } from 'hooks';
import { useTypedSelector } from 'redux/reducers';
import { FbFileRef } from 'types/interfaces';
import { CustomFilePond } from '../FilePond';
import DocumentGrid from './components/DocumentGrid';
import { theme } from 'theme';
import { DropTarget } from 'common';
import { formats } from 'data/formats';
import { Search, Warning } from '@material-ui/icons';
import { IFieldsProps } from 'components/Fields/InlineTextField/interface';

export const FileStorage = ({
  onSelect,
  inlineFieldProps,
  global,
  isDealSummary,
  hideControls
}: {
  onSelect?: ({ url, item }: { url: string; item: FbFileRef }) => Promise<void>;
  inlineFieldProps?: IFieldsProps;
  global?: boolean;
  isDealSummary?: boolean;
  hideControls?: boolean;
}) => {
  const { currentDeal } = useTypedSelector((s) => s.process);
  const { user } = useTypedSelector((s) => s.user);
  const { landingpage } = useTypedSelector((s) => s.utility);

  const { getFilesList, uploadProgress, resetUploadProgress } =
    useFileStorage();

  const [loading, setLoading] = useState<boolean>(true);
  const [searching, setSearching] = useState<boolean>(true);
  const [selected, setSelected] = useState<{ [key: string]: FbFileRef }>({});
  const [FileRefs, setFileRefs] = useState<{ [key: string]: FbFileRef }>({});

  const ProcessInstanceId = global ? 0 : currentDeal?.ProcessInstance?.Id || 0;
  const UserInstanceId = user.Id;
  const isProcessOwner =
    currentDeal?.ProcessInstance?.UserInstanceId === user.Id;
  const isASystemUser = user.SystemAccess >= 5;
  const isAdmin = user.SystemAccess >= 10;
  const isSystemUserAndProcessOwner = isProcessOwner && isASystemUser;

  const get = async () => {
    setLoading(true);
    setSearching(true);
    const getConfig = {
      ProcessInstanceId,
      UserInstanceId,
      isAdmin,
      global,
      isSystemUserAndProcessOwner
    };

    const list = await getFilesList(getConfig);

    if (list) {
      setFileRefs(list);
      setLoading(false);
      setSearching(false);
    } else {
      setTimeout(() => {
        setSearching(false);
        setLoading(false);
      }, 10000);
    }
  };

  const getNoDocumentsText = () => {
    if (landingpage) {
      return 'No documents available in the landing page';
    }
    if (searching) {
      return 'Searching for Documents';
    }
    return `No Documents Available in the ${
      inDeal ? 'Deal Tab' : 'Global Tab'
    }`;
  };

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (uploadProgress === 100) {
      resetUploadProgress();
    }
  }, [uploadProgress]);

  const inDeal = ProcessInstanceId > 0;
  return (
    <div>
      {Object.values(FileRefs).length <= 0 && (
        <Box mb={2}>
          <Alert
            severity={searching ? 'info' : 'warning'}
            icon={searching ? <Search /> : <Warning />}
            style={{ alignItems: 'flex-start' }}>
            <Box>
              <AlertTitle>{getNoDocumentsText()}</AlertTitle>
              <Typography>
                {searching
                  ? 'Please wait while we search for documents.'
                  : `Please add a document ${
                      landingpage
                        ? 'to landing page'
                        : 'or transfer one from the Global Tab.'
                    }`}
              </Typography>
              {searching && (
                <Box mt={2}>
                  <Typography
                    variant="body2"
                    style={{ marginBottom: theme.spacing(1) }}>
                    Searching for documents... Please Wait
                  </Typography>
                  <LinearProgress style={{ height: 4, borderRadius: 2 }} />
                </Box>
              )}
            </Box>
          </Alert>
        </Box>
      )}

      {!hideControls ? (
        <CustomFilePond
          inlineFieldProps={inlineFieldProps}
          global={global}
          refresh={get}
        />
      ) : (
        <Alert severity="info">
          <AlertTitle>Uploading Disabled</AlertTitle>
          <Typography variant="h6" color="textSecondary">
            Uploading has been disabled. This could be due to various reasons,
            such as being in Landing Page Preview mode or accessing a closed
            deal.
          </Typography>
        </Alert>
      )}

      {!hideControls && !global && !landingpage && !isDealSummary && (
        <div style={{ paddingTop: theme.spacing(2) }}>
          <Alert
            severity="error"
            style={{
              width: '100%',
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              border: '3px solid red'
            }}>
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              Important Information:
            </Typography>
            <Typography style={{ fontSize: '18px' }}>
              {`After Uploading Documents, Assign The Users To The Document(s) By Clicking
            'Viewers'.`}
              {onSelect && (
                <span style={{ paddingLeft: 1 }}>
                  <b> As You Have Access To Select Options,</b> Choose The
                  Document You Wish To Use By Clicking &apos;Select&apos;.
                </span>
              )}{' '}
              This Step Is Crucial To Ensure Secure And Proper Sharing With Your
              Intended Users.
            </Typography>
          </Alert>
        </div>
      )}

      <DocumentGrid
        selected={selected}
        setSelected={setSelected}
        FileRefs={FileRefs}
        get={get}
        onSelect={onSelect}
        global={global}
        loading={loading}
        isDealSummary={isDealSummary}
        hideControls={hideControls}
      />
    </div>
  );
};

export const FileUpload = ({
  handleFileUpload,
  keyId
}: {
  handleFileUpload: (files: File[]) => Promise<void>;
  keyId: string;
}) => {
  return (
    <DropTarget
      onUpload={handleFileUpload}
      count={2}
      formats={formats}
      title="Click OR drop files"
      keyId={keyId}
      maxBytes={1000000 * 50}
    />
  );
};

export const Progress = ({ progress }: { progress: number }) => {
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Box style={{ width: '100%', margin: 8 }}>
        <BorderLinearProgress variant="determinate" value={progress} />
      </Box>

      <Box style={{ minWidth: 40, margin: 8 }}>
        <Typography>{`${Math.round(progress)}`} %</Typography>
      </Box>
    </Box>
  );
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff'
  }
}))(LinearProgress);
