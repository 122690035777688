import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import { CircularProgress, Box } from '@material-ui/core';
import { createNotification } from 'react-redux-notify';
import { successNotif } from 'components/Notifications';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ClearIcon from '@material-ui/icons/Clear';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { MdDomainVerification } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Select, MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import DeleteDomainDialog from './deleteDomainDialog';
import { SuccessButton } from 'common/Button';
import { CustomTooltip } from '../components';
import {
  fetchDomains,
  deleteDomain,
  createDomain,
  verifyDomain
} from './functions';
import { useInstance } from 'hooks/useInstance';
import { useTypedSelector } from 'redux/reducers';
import { Domain, DNS_TYPES } from './types';
import { QueryKeys } from '../types';
import Paginator from 'components/Marketing/components/Paginator';

import './index.css';

const grey = '#68696b';
const green = '#66CDAA';

const useStyles = makeStyles((theme: Theme) => {
  const grey100 = theme.palette.grey[100];
  const grey300 = theme.palette.grey[300];
  const grey400 = theme.palette.grey[400];

  const fieldset = {
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center' as const,
    border: `2px solid ${grey400}`
  };

  return createStyles({
    container: {
      maxWidth: 900,
      marginBottom: 50,
      margin: '0 auto',
      border: `1px solid ${grey300}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: grey100,
      padding: 20
    },
    table: {
      width: '95%',
      padding: 10,
      margin: '0 auto'
    },
    copyable: {
      textAlign: 'center',
      padding: '0px 8px',
      '&:hover': {
        background: `${theme.palette.primary.light}66`,
        border: `1px ${theme.palette.primary.main} dashed`,
        borderRadius: theme.shape.borderRadius,
        opacity: 0.5,
        cursor: 'pointer'
      }
    },
    errorBox: {
      background: 'orange'
    },
    dnsFieldset: {
      ...fieldset,
      marginTop: 10,
      paddingBottom: 10
    },
    formContainer: {
      border: `1px dashed lightgrey`,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      maxWidth: 900,
      margin: '20px auto'
    }
  });
});

const DomainAuthentication = ({ testDomain }: { testDomain?: string }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { isAFS, isSynergy, isDual } = useInstance();
  const user = useTypedSelector((s) => s.user.user);

  const isSuperAdmin = user.SystemAccess === 15;
  const [newDomain, setNewDomain] = React.useState(testDomain || '');

  // Helper function to validate domain format
  const isValidDomain = (domain: string) => {
    const pattern =
      /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
    return pattern.test(domain);
  };

  // Add error state for validation
  const [domainError, setDomainError] = React.useState('');

  // Update domain and validate
  const handleDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setNewDomain(value);

    if (value && !isValidDomain(value)) {
      setDomainError('Please enter a valid domain (e.g., example.com)');
    } else {
      setDomainError('');
    }
  };

  const { data: domains = [], isLoading } = useQuery(
    [QueryKeys.FetchDomains],
    () =>
      fetchDomains({
        isAFS,
        isSynergy,
        isSuperAdmin
      }),
    {
      enabled: [isAFS, isSynergy, isDual].every((v) => v != null)
    }
  );

  const [paginatedDomains, setPaginatedDomains] = useState<Domain[]>(domains);

  const { mutate: create, isLoading: authenticateLoading } = useMutation(
    createDomain,
    { onSuccess: () => queryClient.invalidateQueries(QueryKeys.FetchDomains) }
  );

  const { mutate: remove, isLoading: deleteLoading } = useMutation(
    deleteDomain,
    { onSuccess: () => queryClient.invalidateQueries(QueryKeys.FetchDomains) }
  );

  const { mutate: verify, isLoading: verifyLoading } = useMutation(
    verifyDomain,
    { onSuccess: () => queryClient.invalidateQueries(QueryKeys.FetchDomains) }
  );

  const loading =
    isLoading || authenticateLoading || deleteLoading || verifyLoading;

  const validateDomainRow = (val) => {
    if (val) return <VerifiedUserIcon style={{ color: green }} />;
    return <ClearIcon style={{ color: 'red' }} />;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0',
          opacity: 0.8
        }}>
        <MdDomainVerification
          style={{
            marginRight: 10,
            fontSize: 50,
            color: 'grey'
          }}
        />
        <Typography
          variant="h3"
          style={{
            color: 'grey'
          }}>
          Email Domain Authentication
        </Typography>
      </div>

      <Alert severity="info">
        <Typography variant="h5">
          In order to send marketing emails you must authenticate your domain
          first:
        </Typography>
        <Typography>
          <ol>
            <li>
              Enter your domain name in the <b>Domain*</b> field (e.g.{' '}
              <b>my-business.com</b>).
            </li>
            <li>
              In most cases this is all you will need, press <b>Next</b> (If you
              need to authenticate a specific subdomain or use custom security
              this is possible).
            </li>
            <li>
              Once you have received your <b>cname</b> records, you will need to
              enter these into your email hosting providers DNS configuration.
            </li>
            <li>
              Use the refresh button to test the validity of your records.
            </li>
          </ol>
        </Typography>
      </Alert>
      <br />

      <Grid container className={classes.formContainer}>
        <Typography variant="h4">Add New Domain:</Typography>

        <CustomTooltip
          enterNextDelay={100}
          placement="right-end"
          title="Enter your domain name (e.g., my-business.com)">
          <FormControl variant="outlined" margin="dense" fullWidth required>
            <TextField
              data-testid="domain-input"
              label="Domain"
              variant="outlined"
              fullWidth
              value={newDomain}
              onChange={handleDomainChange}
              error={Boolean(domainError)}
              helperText={domainError}
              placeholder="Enter your domain (e.g., my-business.com)"
            />
          </FormControl>
        </CustomTooltip>

        <Box style={{ paddingTop: 4 }}>
          <SuccessButton
            data-testid="create-domain"
            fullWidth
            size="large"
            color="primary"
            disabled={Boolean(domainError) || !newDomain}
            onClick={() =>
              create({
                domain: newDomain,
                userInstanceId: user.Id
              })
            }
            variant="outlined">
            {loading ? (
              <span style={{ color: 'white ' }}>
                <CircularProgress color="inherit" size={20} />
              </span>
            ) : (
              'Submit'
            )}
          </SuccessButton>
        </Box>
      </Grid>

      <br />
      <>
        {paginatedDomains.map((domain, i: number) => {
          const { dns, id } = domain;

          const dnsData: any[] = DNS_TYPES.map((d, i) => {
            const dnsType = dns[d];

            if (!dnsType) return;

            return dnsType;
          }).filter(Boolean);

          const username = domain.subdomain || '';
          const rootDomain = domain.domain || '';

          const isGlobal = domain.hostname === 'global';

          return (
            <div
              key={i}
              className={classes.container}
              data-testid={domain.domain}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: -10
                }}>
                <div>{validateDomainRow(domain.valid)}</div>
                <div
                  style={{
                    padding: 8,
                    flexGrow: 1
                  }}>
                  <Typography variant="h4" style={{ color: grey }}>
                    {`${username}@${rootDomain} ${isGlobal ? ' (GLOBAL)' : ''}`}
                  </Typography>
                </div>
                {
                  <>
                    <div>
                      <CustomTooltip
                        placement="top"
                        title="Refresh Domain Status">
                        <IconButton
                          data-testid={`verify-domain-${id}`}
                          onClick={() => verify(domain)}
                          disabled={!isSuperAdmin && isGlobal}>
                          <RefreshIcon />
                        </IconButton>
                      </CustomTooltip>
                    </div>

                    <div>
                      <DeleteDomainDialog
                        domainId={id}
                        handleDeleteDomain={() => remove(domain)}
                        disabled={!isSuperAdmin && isGlobal}
                      />
                    </div>
                  </>
                }
              </div>
              {!isSuperAdmin && isGlobal ? null : (
                <fieldset className={classes.dnsFieldset}>
                  <legend>
                    <h3 style={{ padding: 10 }}>DNS records</h3>
                  </legend>

                  <table className={classes.table}>
                    <tr id="dns">
                      <th>Status</th>
                      <th>Type</th>
                      <th>Host</th>
                      <th>Value</th>
                    </tr>

                    {dnsData.map((d, i) => {
                      return (
                        <Fragment key={i}>
                          <tr id="dns" style={{ fontWeight: 500, color: grey }}>
                            <td id="dns">{validateDomainRow(d.valid)}</td>
                            <td id="dns">{d.type}</td>
                            <td
                              className={classes.copyable}
                              id="dns"
                              onClick={() => copy(d.host, dispatch)}>
                              {d.host}
                            </td>
                            <td
                              className={classes.copyable}
                              id="dns"
                              onClick={() => copy(d.data, dispatch)}>
                              {d.data}
                            </td>
                          </tr>
                          {d.reason && (
                            <tr id="dns">
                              <td colSpan={12} id="dns">
                                <Alert severity="error" variant="outlined">
                                  {d.reason}
                                </Alert>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      );
                    })}
                  </table>
                </fieldset>
              )}
            </div>
          );
        })}
        <Paginator
          allItems={domains}
          setPaginatedItems={setPaginatedDomains}
          itemsPerPage={3}
        />
      </>
    </>
  );
};

export default DomainAuthentication;

const copy = (value, dispatch) => {
  const fakeElement = document.createElement('input');
  fakeElement.value = value;
  document.body.appendChild(fakeElement);
  fakeElement.select();
  document.execCommand('copy');
  document.body.removeChild(fakeElement);
  return dispatch(createNotification(successNotif('Copied to clipboard')));
};
