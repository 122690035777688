import AldermoreProposal from '.';
import { ELenderAPIType } from '../../interface';
import { createTypeSafeIds } from '../../utils';
import { aldermoreAssets } from './assetDetailDropdown';

export const getAldermoreIds = () => {
  return createTypeSafeIds(AldermoreProposal, (_) => ({
    createProposalRequest: {
      context: {
        introducerIdentifier: {
          FieldDefinitionId: 0,
          isReadonly: true,
          forcedValue: 'ASSET FINANCE SOLUTIONS UK LTD'
        },
        submitterEmail: {
          FieldDefinitionId: 21187,
          isReadonly: true,
          required: true,
          forcedValue: 'ProcessOwnerEmail'
        },
        proposalIdentifier: {
          FieldDefinitionId: 0,
          isReadonly: true,
          forcedValue: 'randomNumberX'
        },
        informationSourceCode: {
          FieldDefinitionId: 0,
          isReadonly: true,
          forcedValue: 'ASSET FINANCE SOLUTIONS UK LTD'
        },
        clientFacetoFaceIndicator: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          info: `Indicates if the Client has been seen face to face by either an FCA regulated Introducer/intermediary (e.g. the Broker/FCA regulated car dealer etc.) or an Aldermore employee`,
          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        },
        userIdentifier: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'BrokerName'
        },
        ProposalCompletedDate: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.DateTime
          }
        },
        proposalCreateDate: {
          FieldDefinitionId: 0,
          isReadonly: true,
          required: true,
          forcedValue: 'currentDateTime',
          FieldDefinition: {
            type: ELenderAPIType.DateTime
          }
        },
        proposalReferenceIdentifier: {
          FieldDefinitionId: 0,
          isReadonly: true,
          forcedValue: 'ASSET FINANCE SOLUTIONS UK LTD'
        }
      },

      clientDetails: {
        clientTypeCode: {
          FieldDefinitionId: 21745,
          isReadonly: false,
          required: true,
          info: `Non-Limited Company is also the catch-all for Charities, Trusts, Schools, Churches, Club/Society/Association etc`,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'Limited Company',
              'Private Limited Company',
              'Non-Limited Company',
              'Private Individual',
              'Sole Trader',
              'Partnership',
              'Limited Liability Partnership',
              'Local Authority'
            ]
          }
        },
        existingClientIndicator: {
          FieldDefinitionId: 0,
          isReadonly: false,

          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        },
        consentToKYCIndicator: {
          FieldDefinitionId: 0,
          isReadonly: false,

          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        },
        participantDetails: [
          {
            roleDetail: {
              rolePlayerTypeCode: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: [
                    'Personal Guarantor',
                    'Corporate Guarantor',
                    'Director',
                    'Partner',
                    'Main Applicant'
                  ]
                }
              }
            },
            personalDetail: {
              nameTypeCode: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['Primary name', 'Registered name', 'Trading style']
                }
              },
              title: {
                FieldDefinitionId: 18428,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['Mr', 'Ms', 'Mrs', 'Miss', 'Dr']
                }
              },
              preferredName: {
                FieldDefinitionId: 0,
                isReadonly: false
              },
              surname: {
                FieldDefinitionId: 18427,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.EntityChange,
                  newIdList: [
                    {
                      entityType: 'Sole Trader',
                      FieldDefinitionId: 24399
                    },
                    {
                      entityType: 'Partnership',
                      FieldDefinitionId: 24402
                    },
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 24396
                    }
                  ]
                }
              },
              firstName: {
                FieldDefinitionId: 18420,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.EntityChange,
                  newIdList: [
                    {
                      entityType: 'Sole Trader',
                      FieldDefinitionId: 23497
                    },
                    {
                      entityType: 'Partnership',
                      FieldDefinitionId: 23977
                    },
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 21810
                    }
                  ]
                }
              },
              gender: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['Male', 'Female', 'Neutral']
                }
              },
              dateofBirth: {
                FieldDefinitionId: 18416,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: [
                    ELenderAPIType.Date,
                    ELenderAPIType.Requirement,
                    ELenderAPIType.EntityChange
                  ],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  },
                  newIdList: [
                    {
                      entityType: 'Sole Trader',
                      FieldDefinitionId: 23501
                    },
                    {
                      entityType: 'Partnership',
                      FieldDefinitionId: 23980
                    },
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 21811
                    }
                  ]
                }
              },
              nationality: {
                FieldDefinitionId: 18422,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.EntityChange,
                  newIdList: [
                    {
                      entityType: 'Sole Trader',
                      FieldDefinitionId: 23502
                    },
                    {
                      entityType: 'Partnership',
                      FieldDefinitionId: 23981
                    },
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 23968
                    }
                  ]
                }
              }
            },

            addressDetails: [
              {
                addressTypeCode: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: ELenderAPIType.Dropdown,
                    options: [
                      'Registered Address',
                      'Correspondence Address',
                      'Mailing Address',
                      'Physical Address'
                    ]
                  }
                },
                buildingName: {
                  FieldDefinitionId: 18431,
                  isReadonly: false,
                  required: true,
                  FieldDefinition: {
                    type: [ELenderAPIType.Requirement],
                    requirement: {
                      maxLength: 25
                    }
                  }
                },
                buildingNumber: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: ELenderAPIType.Requirement,
                    requirement: {
                      maxLength: 25
                    }
                  }
                },
                postalCode: {
                  FieldDefinitionId: 18425,
                  isReadonly: false,
                  required: true
                },
                streetName: {
                  FieldDefinitionId: 18432,
                  isReadonly: false,
                  required: true
                },
                town: {
                  FieldDefinitionId: 18429,
                  isReadonly: false,
                  required: true
                },
                county: {
                  FieldDefinitionId: 18417,
                  isReadonly: false
                },
                locality: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  info: "Suburb, Village in addition to the 'Town' field"
                },
                movedOutDate: {
                  FieldDefinitionId: 23700,
                  isReadonly: false,
                  FieldDefinition: {
                    type: [
                      ELenderAPIType.Date,
                      ELenderAPIType.Requirement,
                      ELenderAPIType.EntityChange
                    ],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    },
                    newIdList: [
                      {
                        entityType: 'Sole Trader',
                        FieldDefinitionId: 23972
                      },
                      {
                        entityType: 'Partnership',
                        FieldDefinitionId: 0
                      },
                      {
                        entityType: 'Private Individual',
                        FieldDefinitionId: 23963
                      }
                    ]
                  }
                },
                movedInDate: {
                  FieldDefinitionId: 0,
                  isReadonly: false,
                  FieldDefinition: {
                    type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                    requirement: {
                      pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                    },
                    condition: 'movedInDate > 3years movedOutDate'
                  }
                }
              }
            ],
            contactDetails: {
              contactTelephoneNumber: {
                FieldDefinitionId: 18525,
                isReadonly: false,
                FieldDefinition: {
                  type: [ELenderAPIType.Phone, ELenderAPIType.EntityChange],
                  newIdList: [
                    {
                      entityType: 'Sole Trader',
                      FieldDefinitionId: 23500
                    },
                    {
                      entityType: 'Partnership',
                      FieldDefinitionId: 23500
                    },
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 23625
                    }
                  ]
                }
              },
              contactMobileNumber: {
                FieldDefinitionId: 18525,
                isReadonly: false,
                FieldDefinition: {
                  type: [ELenderAPIType.Phone, ELenderAPIType.EntityChange],
                  newIdList: [
                    {
                      entityType: 'Sole Trader',
                      FieldDefinitionId: 23500
                    },
                    {
                      entityType: 'Partnership',
                      FieldDefinitionId: 23500
                    },
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 23624
                    }
                  ]
                }
              },
              contactEmailAddress: {
                FieldDefinitionId: 18452,
                isReadonly: false,
                FieldDefinition: {
                  type: [
                    ELenderAPIType.Requirement,
                    ELenderAPIType.EntityChange
                  ],
                  requirement: {
                    maxLength: 255,
                    pattern:
                      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                    message: 'Please enter a valid email address'
                  },
                  newIdList: [
                    {
                      entityType: 'Sole Trader',
                      FieldDefinitionId: 23499
                    },
                    {
                      entityType: 'Partnership',
                      FieldDefinitionId: 23499
                    },
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 24410
                    }
                  ]
                }
              },
              preferredContactType: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Dropdown,
                  options: ['Phone', 'Mobile', 'Email']
                }
              }
            }
          }
        ],
        businessDetails: {
          companiesHouseNumber: {
            FieldDefinitionId: 18315,
            isReadonly: true
          },
          industryCode: {
            FieldDefinitionId: 21881,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.EntityChange,

              newIdList: [
                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 21867
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 21867
                },
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 0
                }
              ]
            }
          },
          industryTypeCode: {
            FieldDefinitionId: 23280,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
              requirement: {
                minLength: 5,
                maxLength: 5
              },
              newIdList: [
                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 0
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 0
                },
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 0
                }
              ]
            }
          },
          establishedDate: {
            FieldDefinitionId: 18450,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [
                ELenderAPIType.Date,
                ELenderAPIType.Requirement,
                ELenderAPIType.EntityChange
              ],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              },
              newIdList: [
                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 23533
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 23533
                },
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 0
                }
              ]
            }
          },
          vatStatus: {
            FieldDefinitionId: 23547,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Dropdown, ELenderAPIType.EntityChange],
              options: [
                'Registered',
                'Not Registered',
                'Charity',
                'Internal',
                'Overseas',
                'OverseasNotExempt'
              ],
              newIdList: [
                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 21875
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 21875
                },
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 0
                }
              ]
            }
          },
          businessName: {
            FieldDefinitionId: 18317,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.EntityChange,
              newIdList: [
                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 21866
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 21866
                },
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 0
                }
              ]
            }
          },
          businessTradingName: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.EntityChange,
              newIdList: [
                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 21866
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 21866
                },
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 0
                }
              ]
            }
          },
          clientBusinessRelationship: {
            directorIndicator: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Boolean
              }
            },
            highNetWorthClientIndicator: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Boolean
              }
            }
          }
        },

        addressDetails: [
          {
            addressTypeCode: {
              FieldDefinitionId: 18430,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: [
                  'Registered Address',
                  'Correspondence Address',
                  'Mailing Address',
                  'Physical Address'
                ]
              }
            },
            buildingName: {
              FieldDefinitionId: 18431,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Requirement],
                requirement: {
                  maxLength: 25
                }
              }
            },
            buildingNumber: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 25
                }
              }
            },
            streetName: {
              FieldDefinitionId: 18432,
              isReadonly: false,
              required: true
            },
            locality: {
              FieldDefinitionId: 18434,
              isReadonly: false
            },
            town: {
              FieldDefinitionId: 18435,
              isReadonly: false,
              required: true
            },
            postalCode: {
              FieldDefinitionId: 18438,
              isReadonly: false,
              required: true
            },
            county: {
              FieldDefinitionId: 18436,
              isReadonly: false
            },
            country: {
              FieldDefinitionId: 0,
              isReadonly: false,
              forcedValue: 'United Kingdom'
            },
            movedInDate: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                requirement: {
                  pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                },
                condition: 'movedInDate > 3years movedOutDate'
              },
              movedOutDate: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              }
            }
          }
        ]
      },

      productDetails: {
        productTypeCode: {
          FieldDefinitionId: 24155,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Dropdown, ELenderAPIType.FinanceParent],
            options: ['Lease', 'Hire Purchase', 'Loan']
          }
        },
        productSubTypeCode: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'Standard Lease',
              'Sale and Lease Back',
              'Finance Lease with RV',
              'Standard HP',
              'Variable Rate - Balanced Payments',
              'Sale and HP Back',
              'Standard Loan'
            ]
          }
        }
      },

      assetDetails: [
        {
          assetCategory: {
            FieldDefinitionId: 18308,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: Object.keys(aldermoreAssets)
            }
          },
          assetPurposeType: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: ['Business', 'Personal']
            }
          },
          assetType: {
            FieldDefinitionId: 18309,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.CascadingDropdown,
              dependsOn: ['assetCategory'],
              getOptions: (category) => aldermoreAssets[category]?.types || []
            }
          },
          assetManufacturerDetails: {
            FieldDefinitionId: 24063,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.CascadingDropdown,
              dependsOn: ['assetCategory', 'assetType'],
              getOptions: (category, type) => {
                const manufacturers = aldermoreAssets[category]?.manufacturers;
                return Array.isArray(manufacturers)
                  ? manufacturers
                  : manufacturers?.[type] || ['01-Other'];
              }
            }
          },
          fuelType: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: [
                'Petrol',
                'Diesel',
                'Petrol/LPG',
                'Petrol/CNG',
                'Electric',
                'Petrol/Electric Hybrid',
                'Petrol/Bio-Ethanol (E85)',
                'Diesel/Electric Hybrid',
                'Petrol/PlugIn Electric Hybrid',
                'Diesel/PlugIn Electric Hybrid'
              ]
            }
          },
          assetRegistrationNumber: {
            FieldDefinitionId: 21938,
            isReadonly: false,
            info: "For vehicles it's the DVLA registration number."
          },
          assetAge: {
            FieldDefinitionId: 18312,
            isReadonly: false,
            required: true
          },
          buyBackProviderType: {
            FieldDefinitionId: 0,
            required: true,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: [
                'No buy back provider',
                'Supplier',
                'Aldermore',
                'Manufacturer',
                'Other'
              ]
            }
          },

          assetQuantity: {
            FieldDefinitionId: 18313,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Number
            }
          },

          assetModel: {
            FieldDefinitionId: 18310,
            isReadonly: false,
            required: true
          },

          assetManufacturedYear: {
            FieldDefinitionId: 18312,
            isReadonly: false,
            required: true
          },
          assetDealerDetails: {
            FieldDefinitionId: 21944,
            isReadonly: false,
            required: true
          },
          assetRegistrationDate: {
            FieldDefinitionId: 20697,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Requirement, ELenderAPIType.Date],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          assetCAPId: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Number
            }
          },
          assetConditionType: {
            FieldDefinitionId: 18311,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: ['New', 'Used']
            }
          },
          assetNotesDetails: {
            FieldDefinitionId: 18455,
            isReadonly: false
          },
          assetSerialNumber: {
            FieldDefinitionId: 18454,
            isReadonly: false
          },
          assetsMaintenanceCostPerWeek: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Currency
            }
          },
          assetCost: {
            FieldDefinitionId: 18314,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Currency
            }
          },
          assetCostTaxAmount: {
            FieldDefinitionId: 21166,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Currency
            }
          },
          assetResidualPercentage: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            info: 'Mandatory but can be Zero if no Balloon/RV',
            FieldDefinition: {
              type: ELenderAPIType.Percentage
            }
          },
          assetResidualAmount: {
            FieldDefinitionId: 23949,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Currency
            }
          }
        }
      ],
      financialDetails: {
        totalAmountIncludingTax: {
          FieldDefinitionId: 23997,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.FianceDependent, ELenderAPIType.Currency],
            options: ['Lease', 'Hire Purchase']
          }
        },
        depositPercentage: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Percentage
          }
        },
        totalAmountexcludingTax: {
          FieldDefinitionId: 23820,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.FianceDependent, ELenderAPIType.Currency],
            options: ['Loan']
          }
        },
        netAdvanceAmount: {
          FieldDefinitionId: 23958,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.FianceDependent, ELenderAPIType.Currency],
            options: ['Lease', 'Hire Purchase']
          }
        },
        totalTaxAmount: {
          FieldDefinitionId: 23822,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        valueaddedTax: {
          FieldDefinitionId: 23838,
          isReadonly: false,
          required: true,
          info: 'The total monetary value of VAT. Mandatory but can be Zero',
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        subsidyAmount: {
          FieldDefinitionId: 23833,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        subsidyPercentage: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Percentage
          }
        },
        fundingRatePercentage: {
          FieldDefinitionId: 18345,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Percentage
          }
        },
        nominalMarginPercentage: {
          FieldDefinitionId: 18344,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Percentage
          }
        },
        totalResidualValue: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        yieldPercentage: {
          FieldDefinitionId: 18343,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Percentage
          }
        },
        partExchangeAmount: {
          FieldDefinitionId: 24386,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        taxDeferralPeriod: {
          FieldDefinitionId: 21323,
          isReadonly: false,
          info: 'Only add a Value is AssetType = Hard, Soft or New Vehicle. Used & Classic vehicles do not attract VAT',
          FieldDefinition: {
            type: [
              ELenderAPIType.Number,
              ELenderAPIType.Requirement,
              ELenderAPIType.FianceDependent
            ],
            requirement: {
              maxAmount: 3,
              minAmount: 0
            },
            options: ['Hire Purchase']
          }
        },
        depositAmount: {
          FieldDefinitionId: 18325,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.FianceDependent, ELenderAPIType.Currency],
            options: ['Lease', 'Hire Purchase']
          }
        },
        totalBalloonValue: {
          FieldDefinitionId: 23949,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        fundVatIndicator: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        }
      },
      paymentDetails: {
        paymentFrequencyType: {
          FieldDefinitionId: 18333,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Dropdown],
            options: ['Monthly', 'Quarterly', 'Half-Yearly', 'Annually']
          }
        },
        totalNumberOfPayments: {
          FieldDefinitionId: 23271,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        },
        paymentInAdvance: {
          FieldDefinitionId: 23824,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'In Arrears',
              'In advance',
              '2 In advance',
              '3 In advance',
              '4 In advance',
              '5 In advance',
              '6 In Advance'
            ]
          }
        },
        totalInitialPayments: {
          FieldDefinitionId: 18348,
          isReadonly: false,
          info: 'The total amount of Deposit + total of any payments in advance',
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        }
      },
      notesDetails: {
        brokerExternalNotes: [
          {
            type: {
              FieldDefinitionId: 21643,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 2500
                }
              }
            }
          }
        ]
      }
    }
  }));
};
