// src/services/noticeboardService.ts
import firebase from 'firebase';
import { Dispatch } from 'react';
import {
  fetchNoticeboardRequest,
  fetchNoticeboardSuccess,
  fetchNoticeboardFailure
} from 'redux/actions/noticeBoardActions';

interface NoticeboardFetchProps {
  BrandList?: string;
  dispatch: Dispatch<any>;
  setIsLoading: (isLoading: boolean) => void;
  BugTracker?: {
    notify: (error: any) => void;
  };
}

export const getNoticeboardData = async ({
  BrandList,
  dispatch,
  setIsLoading,
  BugTracker
}: NoticeboardFetchProps) => {
  dispatch(fetchNoticeboardRequest());

  try {
    setIsLoading(true);

    const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
    const brandsArray = BrandList?.split(',') || [];
    const brandId = brandsArray[0]; // Get the first brand

    const doc = await firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance')
      .get();

    if (doc.exists) {
      const data = doc.data();
      const noticeboardData: {
        host?: { timestamp?: number };
        global?: { timestamp?: number };
        brand?: { timestamp?: number };
      } = {};

      if (data) {
        // Host-specific noticeboard
        const instanceHost = data[hostname]?.currentNoticeboardInstance;
        if (instanceHost) {
          noticeboardData.host = {
            timestamp: instanceHost.timestamp
          };
        }

        // Global noticeboard
        const global = data.currentNoticeboardInstance;
        if (global) {
          noticeboardData.global = {
            timestamp: global.timestamp
          };
        }

        // Brand-specific noticeboard
        const brandNotice =
          brandId && data[brandId]?.currentNoticeboardInstance;
        if (brandNotice) {
          noticeboardData.brand = {
            timestamp: brandNotice.timestamp
          };
        }

        // Dispatch success with collected data
        dispatch(fetchNoticeboardSuccess(noticeboardData));
      }
    }

    setIsLoading(false);
    return doc.exists ? doc.data() : null;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    BugTracker?.notify(error);
    dispatch(fetchNoticeboardFailure(errorMessage));
    setIsLoading(false);
    throw error;
  }
};
