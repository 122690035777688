import { useHistory } from 'react-router-dom';
import StepZero from 'components/Stepper/StepZero';
import { useTypedSelector } from 'redux/reducers';

interface ILocationState {
  UserInstanceId: number;
  UserDefinitionId: number;
}

const Component = () => {
  const history = useHistory();
  const { currentProcess } = useTypedSelector((s) => s.process);

  const state = history.location.state as ILocationState;
  const UserInstanceId = state.UserInstanceId;
  const UserDefinitionId = state.UserDefinitionId;

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcoincentral.com%2Fwp-content%2Fuploads%2F2019%2F01%2Ffintech-blockchain.png&f=1&nofb=1")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}>
      <StepZero
        UserDefinitionId={UserDefinitionId}
        UserInstanceId={UserInstanceId}
        definition={currentProcess.ProcessDefinition}
      />
    </div>
  );
};

export default Component;
