import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Tools } from '../Calculator/interfaces';
import { IPeriodColumns } from '../Calculator/functions/setInitPeriods';
import { Button, useTheme, Typography, Grid } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Delete } from '@material-ui/icons';
import {
  calculateAndSetEndDate,
  calculateTotalMonthsAcrossAllRows,
  cascadeStartDates,
  createEmptyRow,
  getDateFormat,
  getDatePickerViews,
  getMinStartDateForNextRow,
  getMonthsMultiplier,
  getNumberHelperText,
  getTotalMonthsText,
  recalculatePeriodsFromIndex,
  validateRowSequence
} from './functions';
import moment from 'moment';
import NumericFormat from 'react-number-format';

export interface IRowData {
  [key: string]: number | string | undefined;
}

export interface IExtra {
  Amount: number;
  StartPeriod: number;
  EndPeriod: number;
  ExPayOverRide: string;
}

const SeasonalPayments = ({
  tools,
  handleClose
}: {
  tools: Tools;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  const { period, state, setRows, rows, setState, setExtras } = tools;

  const handleAddRow = () => {
    const newEmptyRow = createEmptyRow(period.columns, rows, state.StartDate);
    setRows(rows.concat(newEmptyRow));
  };

  const handleCellChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
    column: IPeriodColumns
  ) => {
    if (column.field === 'Number') {
      handleNumberChange(event, rowIndex);
      return;
    }

    const updatedRows = [...rows];
    const newValue = event.target.value;

    if (column.type === 'numeric') {
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [column.field]: newValue === '' ? '' : newValue
      };
    } else {
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [column.field]: newValue
      };
    }

    if (column.field === 'PaymentFrequency' || column.field === 'Number') {
      calculateAndSetEndDate(updatedRows, rowIndex);
      recalculatePeriodsFromIndex(updatedRows, rowIndex);
    }

    setRows(updatedRows);
  };

  const handleDeleteRow = (rowIndex: number) => {
    const updatedRows = [
      ...rows.slice(0, rowIndex),
      ...rows.slice(rowIndex + 1)
    ];
    if (rowIndex === rows.length - 1) {
      setRows(updatedRows);
      setExtras(updatedRows);
      return;
    }

    recalculatePeriodsFromIndex(updatedRows, rowIndex);
    setExtras(updatedRows);
    setRows(updatedRows);
  };

  const handleDateChange = (
    date: MaterialUiPickersDate,
    rowIndex: number,
    column: IPeriodColumns
  ) => {
    const updatedRows = [...rows];

    if (date && date.isValid()) {
      const dateString = [
        date.year().toString().padStart(4, '0'),
        (date.month() + 1).toString().padStart(2, '0'),
        date.date().toString().padStart(2, '0')
      ].join('-');

      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [column.field]: dateString
      };

      if (rowIndex === 0 && date.isBefore(state.StartDate)) {
        setState((prevState) => ({
          ...prevState,
          StartDate: dateString
        }));
      }

      const validateRowSequences = validateRowSequence(updatedRows);
      if (validateRowSequences) {
        recalculatePeriodsFromIndex(updatedRows, rowIndex);
      }
    } else {
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [column.field]: ''
      };
    }

    setRows(updatedRows);
  };

  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number
  ): void => {
    const updatedRows = [...rows];
    const newValue = event.target.value;

    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      Number: newValue
    };

    if (newValue && updatedRows[rowIndex]['StartPeriod']) {
      const number = parseInt(newValue);
      const frequency = updatedRows[rowIndex]['PaymentFrequency'] || '1';
      const monthMultiplier = getMonthsMultiplier(frequency.toString());
      const totalMonths = number * monthMultiplier;

      const startDate = moment(updatedRows[rowIndex]['StartPeriod']);
      const endDate = moment(startDate).add(totalMonths - 1, 'months');
      updatedRows[rowIndex]['EndPeriod'] = endDate.format('YYYY-MM-DD');

      cascadeStartDates(updatedRows, rowIndex);
    }

    setRows(updatedRows);
  };

  // Helper function to format numeric field values
  const getNumericFieldValue = (value: number | string | undefined) => {
    if (value === undefined || value === '') return '';
    return value.toString();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {period.columns.map((column: IPeriodColumns) => (
                <TableCell key={column.field}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => {
              const isStartPeriodValid =
                row['StartPeriod'] &&
                !isNaN(new Date(row['StartPeriod'] as string).getTime());

              return (
                <TableRow
                  key={rowIndex}
                  style={{
                    backgroundColor: !isStartPeriodValid ? '#ffcccc' : ''
                  }}>
                  {period.columns.map((column: IPeriodColumns) => {
                    const paymentFrequency = row['PaymentFrequency'] || '1';

                    if (column.type === 'date') {
                      const views = getDatePickerViews(
                        paymentFrequency.toString()
                      );

                      const dateFormat = getDateFormat(
                        paymentFrequency.toString()
                      );

                      const months = row['Number'] || '';
                      const minStartDate =
                        rowIndex > 0
                          ? getMinStartDateForNextRow(rows.slice(0, rowIndex))
                          : undefined;

                      return (
                        <TableCell key={column.field}>
                          <KeyboardDatePicker
                            size="small"
                            variant="inline"
                            views={views}
                            format={dateFormat}
                            value={
                              row[column.field]
                                ? moment(row[column.field])
                                : state.StartDate
                            }
                            onChange={(date: MaterialUiPickersDate) =>
                              handleDateChange(date, rowIndex, column)
                            }
                            minDate={
                              minStartDate
                                ? minStartDate
                                : new Date('1900-01-01')
                            }
                            disabled={column.disabled}
                            autoOk
                            helperText={
                              column.field === 'EndPeriod' &&
                              months !== null &&
                              months !== ''
                                ? getTotalMonthsText(
                                    paymentFrequency.toString(),
                                    parseInt(months.toString())
                                  )
                                : ''
                            }
                          />
                        </TableCell>
                      );
                    }

                    const helperText = getNumberHelperText(
                      paymentFrequency.toString()
                    );

                    return (
                      <TableCell key={column.field}>
                        {column.type === 'select' ? (
                          <TextField
                            select
                            value={row[column.field] || ''}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => handleCellChange(event, rowIndex, column)}
                            disabled={column.disabled}>
                            {column.lookup &&
                              (
                                Object.entries(column.lookup) as [
                                  string,
                                  string
                                ][]
                              ).map(([key, value]) => (
                                <MenuItem key={key} value={key}>
                                  {value}
                                </MenuItem>
                              ))}
                          </TextField>
                        ) : column.title === 'Amount' ? (
                          <CustomNumericInput
                            value={row[column.field]}
                            placeholder={column.title}
                            onChange={(event) =>
                              handleCellChange(
                                event as React.ChangeEvent<HTMLInputElement>,
                                rowIndex,
                                column
                              )
                            }
                            disabled={column.disabled}
                          />
                        ) : (
                          <TextField
                            size="small"
                            type={column.type === 'numeric' ? 'number' : 'text'}
                            value={getNumericFieldValue(row[column.field])}
                            placeholder={column.title}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => handleCellChange(event, rowIndex, column)}
                            disabled={
                              column.title === 'Number'
                                ? !isStartPeriodValid
                                : column.disabled
                            }
                            helperText={
                              column.title === 'Number'
                                ? !isStartPeriodValid
                                  ? 'Invalid Start Date'
                                  : helperText
                                : ''
                            }
                          />
                        )}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <Button
                      onClick={() => handleDeleteRow(rowIndex)}
                      size="small"
                      variant="text">
                      <Delete color="secondary" />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ paddingTop: theme.spacing(2) }}>
          <Typography variant="h5">
            {calculateTotalMonthsAcrossAllRows(rows)}
          </Typography>
        </Grid>
        <Grid item style={{ paddingBottom: theme.spacing(2) }}>
          <Button onClick={handleAddRow} color="primary" variant="contained">
            Add new Line
          </Button>
        </Grid>
        <Grid item style={{ paddingBottom: theme.spacing(2) }}>
          <Button onClick={handleClose} color="primary" variant="contained">
            Save Seasonal Payments
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SeasonalPayments;

interface ICustomNumericFormatProps {
  onChange: (event: { target: { value: string } }) => void;
  value: string | number | undefined;
  placeholder: string;
  disabled?: boolean;
}

const CustomNumericInput = React.forwardRef<
  HTMLInputElement,
  ICustomNumericFormatProps
>(({ onChange, value, disabled, placeholder, ...other }, ref) => {
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      value={value}
      placeholder={placeholder}
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale
      prefix="£"
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      customInput={TextField}
      disabled={disabled}
    />
  );
});
