export interface Domain {
  dns: any;
  default: boolean;
  hostname: string;
  id: number;
  key: string;
  legacy: boolean;
  user_id: number;
  username: string;
  valid: boolean;
  domain: string;
  subdomain: string;
  fulldomain: string;
  userInstanceId: number;
}

export const DNS_TYPES = [
  'dkim1',
  'dkim2',
  'mail_cname',
  'dkim',
  'mail_server',
  'subdomain_spf'
];
