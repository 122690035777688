import { useState } from 'react';
import { getCalculatorObjectsForProcess } from 'redux/actions/Calculator';
import { SubmitProcessStep } from 'redux/database';
import Dialog from 'components/Stepper/components/Dialog';
import {
  CompleteObjectDefinitionDict,
  CompleteObjectInstance,
  CompleteObjectInstanceDict,
  CompleteProcessInstance,
  FieldDefinition,
  FieldDefinitionDict,
  FieldInstance
} from 'types/interfaces';
import { useProcess } from 'hooks/useProcess';
import { selectObjectInstance } from 'redux/actions/objectUpdater';
import { IUseAuditLog, saveAuditLog } from './useAuditLog';
import { updateFieldInstance } from 'redux/actions/GraphQlActions';
import { useTypedSelector } from 'redux/reducers';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

interface ILPC {
  CompleteObjectDefinitionDict: CompleteObjectDefinitionDict;
  CompleteObjectInstanceDict: CompleteObjectInstanceDict;
}

/**
 * useDecline Usage
 * @param {FieldDefinitionDict} FieldDefinitionDict Required For Landing Page & Non-Landing Page
 * @param {CompleteObjectInstanceList} CompleteObjectInstanceList Required For Landing Page Only
 * @param {CompleteObjectInstance} object Required For Landing Page & Non-Landing Page
 * @param {any} parsed  Required For Landing Page Buttons (Decline Buttons) Only
 * @returns {boolean} "CalculatorDeclined" Known as IsDeclined
 * @returns {void} declineAll Which Declines All Quotes Or IndicativeQuotes
 */

const useDecline = ({
  FieldDefinitionDict,
  CompleteObjectInstanceList,
  object,
  parsed
}: {
  FieldDefinitionDict?: FieldDefinitionDict;
  CompleteObjectInstanceList?: CompleteObjectInstance[] | undefined;
  object?: CompleteObjectInstance;
  parsed?: any;
}) => {
  const {
    landingpage,
    token,
    currentDeal,
    currentStepId,
    currentProcess,
    user
  } = useProcess();
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const DeclinedFieldDefinition = Object.values(FieldDefinitionDict || []).find(
    (FieldDefinition: FieldDefinition) => {
      return FieldDefinition.Title.includes('Decline');
    }
  ) as FieldDefinition;

  let CalculatorDeclined: boolean | undefined = false;
  if (!landingpage) {
    if (DeclinedFieldDefinition && object) {
      const FieldInstanceList = getFieldInstances(object);
      const DeclinedFieldInstance =
        FieldInstanceList.find((FieldInstance: FieldInstance) => {
          return (
            FieldInstance.FieldDefinitionId === DeclinedFieldDefinition.Id &&
            FieldInstance.FieldValue.includes('true')
          );
        }) !== undefined;

      CalculatorDeclined = DeclinedFieldInstance;
    }
  } else {
    const IndicativeQuoteStepId = 1738;
    const IndicativeQuoteDeclineId = [23525, 23592];
    if (currentStepId === IndicativeQuoteStepId) {
      Object.values(currentDeal.CompleteObjectInstanceDict).some(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
          const FieldInstance: FieldInstance | undefined =
            FieldInstanceList.find((FieldInstance: FieldInstance) => {
              return IndicativeQuoteDeclineId.includes(
                FieldInstance.FieldDefinitionId
              );
            });

          if (FieldInstance) {
            CalculatorDeclined = FieldInstance
              ? FieldInstance.FieldValue.includes('true')
              : false;
            return CalculatorDeclined;
          }
        }
      );
    } else {
      CompleteObjectInstanceList?.some(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          if (!DeclinedFieldDefinition) return false;
          if (!object) return false;

          if (
            CompleteObjectInstance.ObjectInstance.Id ===
            object.ObjectInstance.Id
          ) {
            const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
            const FieldInstance: FieldInstance | undefined =
              FieldInstanceList.find((FieldInstance: FieldInstance) => {
                return (
                  FieldInstance.FieldDefinitionId === DeclinedFieldDefinition.Id
                );
              });

            CalculatorDeclined = FieldInstance
              ? FieldInstance.FieldValue.includes('true')
              : false;
            return CalculatorDeclined;
          }
        }
      );
    }
  }

  const [loadingDecline, setLoadingDecline] = useState<boolean>(false);
  const [dialogInfo, setDialogInfo] = useState<{
    title: string;
    message: string;
  }>({ title: '', message: '' });
  const [decline, setDecline] = useState<boolean>(false);

  const declineLandingPage = async () => {
    setLoadingDecline(true);

    let submitDeal: CompleteProcessInstance = {
      CompleteObjectInstanceDict: {},
      CompleteObjectInstanceList: [],
      CurrentUserInstanceCanSubmit: false,
      NextStepUserInstanceIdDict: {},
      ProcessInstance: {
        Guid: '',
        Id: 0,
        LastModified: '',
        ProcessDefinitionId: 0,
        ProcessStepDefinitionId: 0,
        Title: '',
        UserInstanceId: 0,
        CreatedOn: '',
        ClosedOn: '',
        Tag: '',
        Value: ''
      },
      ProcessStepBodyContentDictionary: {},
      ProcessStepInstanceList: []
    };
    submitDeal.ProcessInstance = currentDeal.ProcessInstance;

    if (parsed !== undefined) {
      submitDeal.ProcessInstance.ProcessStepDefinitionId = parsed?.psdid;
    }

    const res = await SubmitProcessStep({ submitDeal });
    if (res) {
      const customPayload: IUseAuditLog = {
        payload: currentStepId.toString(),
        firebaseStatus: 'Rejected',
        action:
          currentProcess.CompleteProcessStepDefinitionDict[currentStepId]
            .ProcessStepDefinition.Title,
        timestamp: new Date(),
        userInstanceId: user.Id,
        userInstanceEmail: user.UserInstanceEmail,
        dealId: currentDeal.ProcessInstance.Id,
        actionFunction: '',
        ProcessDefinitionId: currentProcess.ProcessDefinition.Id
      };

      const firebaseAuditLog = await saveAuditLog(customPayload);
      if (firebaseAuditLog) {
        setDecline(true);
        setDialogInfo({
          title: 'Declined',
          message:
            'The Necessary Information Has Been Communicated To The Individuals Involved.'
        });
      }
    }

    setLoadingDecline(false);
  };

  const declineAll = async () => {
    const IndicativeQuoteStepId = 1738;
    const QuoteStepId = 1502;

    const setFieldValue = async (
      CompleteObjectInstance: CompleteObjectInstance,
      fieldDefinitionId: number | number[],
      value: string
    ) => {
      const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
      const fieldInstancesToUpdate = FieldInstanceList.filter(
        (FieldInstance: FieldInstance) =>
          Array.isArray(fieldDefinitionId)
            ? fieldDefinitionId.includes(FieldInstance.FieldDefinitionId)
            : FieldInstance.FieldDefinitionId === fieldDefinitionId
      );

      await Promise.all(
        fieldInstancesToUpdate.map(async (FieldInstance: FieldInstance) => {
          FieldInstance.FieldValue = value;

          return await updateFieldInstance({
            baseUrl,
            ProcessInstanceId: currentDeal.ProcessInstance.Id,
            data: FieldInstance
          });
        })
      );
    };

    const deselectObjectInstances = async (
      completeObjectInstanceDict: Record<string, CompleteObjectInstance>
    ) => {
      await Promise.all(
        Object.values(completeObjectInstanceDict).map(
          (CompleteObjectInstance) => {
            if (CompleteObjectInstance?.ObjectInstance.Selected) {
              CompleteObjectInstance.ObjectInstance.Selected = false;
              return selectObjectInstance({
                token,
                data: CompleteObjectInstance,
                props: { ProcessInstanceId: currentDeal.ProcessInstance.Id },
                action: 'SELECT'
              });
            }
            return Promise.resolve();
          }
        )
      );
    };

    if (currentStepId === IndicativeQuoteStepId) {
      await Promise.all(
        Object.values(currentDeal.CompleteObjectInstanceDict).map(
          async (element: CompleteObjectInstance) => {
            const IndicativeQuoteDeclineId = [23525, 23592];
            const SelectIndicativeQuoteFieldDefinitionId = 22712;
            await setFieldValue(element, IndicativeQuoteDeclineId, 'true');
            await setFieldValue(
              element,
              SelectIndicativeQuoteFieldDefinitionId,
              ''
            );
          }
        )
      );

      await deselectObjectInstances(
        currentDeal.CompleteObjectInstanceDict as Record<
          string,
          CompleteObjectInstance
        >
      );
    } else if (currentStepId === QuoteStepId) {
      const res: ILPC | null = await getCalculatorObjectsForProcess({
        ProcessInstanceId: currentDeal.ProcessInstance.Id
      });

      if (res) {
        const DeclineFieldDefinition = Object.values(
          res.CompleteObjectDefinitionDict
        )
          .flatMap((element) => element.FieldDefinitionList || [])
          .find((FieldDefinition: FieldDefinition) =>
            FieldDefinition.Title.includes('Decline')
          );

        const AmountFieldDefinition = Object.values(
          res.CompleteObjectDefinitionDict
        )
          .flatMap((element) => element.FieldDefinitionList || [])
          .find((FieldDefinition: FieldDefinition) =>
            FieldDefinition.Title.includes('Amount')
          );

        if (DeclineFieldDefinition) {
          await Promise.all(
            Object.values(res.CompleteObjectInstanceDict).map(
              async (CompleteObjectInstance: CompleteObjectInstance) => {
                const FieldInstanceList = getFieldInstances(
                  CompleteObjectInstance
                );

                let isEmpty = FieldInstanceList.every(
                  (FieldInstance: FieldInstance) =>
                    AmountFieldDefinition?.Id !==
                      FieldInstance.FieldDefinitionId ||
                    FieldInstance.FieldValue === ''
                );

                if (!isEmpty) {
                  await setFieldValue(
                    CompleteObjectInstance,
                    DeclineFieldDefinition.Id,
                    'true'
                  );
                }
              }
            )
          );

          // Will Set "Quote ID" To ""
          await Promise.all(
            Object.values(currentDeal.CompleteObjectInstanceDict).map(
              async (element: CompleteObjectInstance) => {
                const QuoteIdField = 21394;
                await setFieldValue(element, QuoteIdField, '');
              }
            )
          );

          await deselectObjectInstances(
            res.CompleteObjectInstanceDict as Record<
              string,
              CompleteObjectInstance
            >
          );
        }
      }
    }
    await declineLandingPage();
  };

  const handleClose = () => setDecline(false);
  const declineDialog = (
    <Dialog
      handleClose={handleClose}
      message={dialogInfo.message}
      open={decline}
      title={dialogInfo.title}
    />
  );

  return {
    declineAll,
    isDeclined: CalculatorDeclined,
    declineDialog,
    loadingDecline
  };
};

export default useDecline;
