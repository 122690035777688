import { tableIcons } from './tableIcons';
import MaterialTable, {
  MTableToolbar,
  MTableEditRow
} from '@material-table/core';

interface Props {
  title?: any;
  data?: any;
  columns?: any;
  options?: any;
  editable?: any;
  cellEditable?: any;
  actions?: any;
  handleRowClick?: any;
  detailPanel?: any;
  isLoading?: boolean;
  onSelectionChange?: (rows: any) => void;
  components?: any;
  emptyDataSourceMessage?: any;
  searchPlaceholder?: string;
  customIcons?: {
    Add?: React.ComponentType;
    Check?: React.ComponentType;
    Clear?: React.ComponentType;
    Delete?: React.ComponentType;
    DetailPanel?: React.ComponentType;
    Edit?: React.ComponentType;
    Export?: React.ComponentType;
    Filter?: React.ComponentType;
    FirstPage?: React.ComponentType;
    LastPage?: React.ComponentType;
    NextPage?: React.ComponentType;
    PreviousPage?: React.ComponentType;
    ResetSearch?: React.ComponentType;
    Search?: React.ComponentType;
    SortArrow?: React.ComponentType;
    ThirdStateCheck?: React.ComponentType;
    ViewColumn?: React.ComponentType;
  };
}

const Table = (props: Props) => {
  return (
    <MaterialTable
      actions={props?.actions}
      cellEditable={props?.cellEditable}
      columns={props?.columns}
      data={props?.data}
      detailPanel={props?.detailPanel}
      editable={props?.editable}
      icons={tableIcons(props?.customIcons)}
      isLoading={props?.isLoading}
      onRowClick={(event, rowData, togglePanel) => {
        if (togglePanel && props?.handleRowClick) {
          props.handleRowClick ? props.handleRowClick(rowData) : togglePanel();
        }
      }}
      options={{ ...props?.options, search: true, headerStyle: { zIndex: 0 } }}
      title={props?.title}
      onSelectionChange={props?.onSelectionChange}
      components={props?.components}
      localization={{
        body: {
          emptyDataSourceMessage: props.emptyDataSourceMessage
        },
        toolbar: {
          searchPlaceholder: props.searchPlaceholder || 'Search'
        }
      }}
    />
  );
};

export default Table;

export { MTableToolbar, MTableEditRow };
