import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IconButton, Tooltip, Typography, useTheme } from '@material-ui/core';
import { Close, GetApp } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

/**
 ** Transition component for the dialog.
 *
 * @param {TransitionProps & { children?: React.ReactElement<any, any> }} props - Transition properties and children.
 * @param {React.Ref<any>} ref - React reference.
 * @returns {JSX.Element} - The Slide transition component.
 */

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<any>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IHasDownloadProps {
  tooltip: string;
  action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  boolean: boolean;
}

interface IAlertProps {
  title: string;
  description: string;
  type: 'info' | 'warning' | 'error';
  customColor?: string;
}

interface ICustomDialogProps {
  open: boolean | Boolean | undefined;
  handleClose:
    | ((
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        reason: 'backdropClick' | 'escapeKeyDown'
      ) => void)
    | null
    | undefined;
  children: React.ReactNode;
  hasDownload?: IHasDownloadProps;
  alert?: IAlertProps | JSX.Element;
  maxSize?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
  staticPosition?: boolean;
}

/**
 ** CustomDialog component to display a dialog with an alert and additional content if needed.
 *
 * @param {ICustomDialogProps} props - The properties for the CustomDialog component.
 * @returns {JSX.Element} - The rendered CustomDialog component.
 */

const CustomDialog = ({
  open,
  handleClose,
  children,
  alert,
  hasDownload = {
    tooltip: '',
    action: () => {},
    boolean: false
  },
  maxSize = 'md',
  staticPosition = false
}: ICustomDialogProps) => {
  if (!open) return null;

  return (
    <Dialog
      fullWidth
      maxWidth={maxSize}
      open={open.valueOf()}
      style={{ backdropFilter: open ? 'blur(3px)' : 'none' }}
      TransitionComponent={Transition}
      keepMounted
      onClose={
        handleClose
          ? (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason) =>
              handleClose(event, reason)
          : undefined
      }
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      {alert && (
        <RenderAlert
          hasDownload={hasDownload}
          alert={alert}
          handleClose={handleClose}
          staticPosition={staticPosition}
        />
      )}
      {children}
    </Dialog>
  );
};

/**
 * RenderAlert component to display an alert with an optional download button and close button.
 *
 * @param {object} props - The properties for the RenderAlert component.
 * @param {IHasDownloadProps} props.hasDownload - The properties for the download button.
 * @param {IAlertProps | JSX.Element} props.alert - The alert properties or JSX element.
 * @param {((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason: 'backdropClick' | 'escapeKeyDown') => void) | null | undefined} props.handleClose - The function to handle dialog close.
 * @returns {JSX.Element} - The rendered alert component.
 */

const RenderAlert = ({
  hasDownload,
  alert,
  handleClose,
  staticPosition
}: {
  hasDownload: IHasDownloadProps;
  alert: IAlertProps | JSX.Element;
  staticPosition: boolean;
  handleClose:
    | ((
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        reason: 'backdropClick' | 'escapeKeyDown'
      ) => void)
    | null
    | undefined;
}): JSX.Element => {
  const theme = useTheme();
  if (React.isValidElement(alert)) {
    return alert;
  }

  const handleIconButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    handleClose ? handleClose(event, 'backdropClick') : undefined;
  };

  const { title, description, type, customColor } = alert as IAlertProps;
  return (
    <Alert
      variant="filled"
      style={{
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: customColor !== '' ? customColor : 'inherit'
      }}
      severity={type}>
      <AlertTitle style={{ color: theme.palette.common.white }}>
        {title}
      </AlertTitle>
      <Typography variant="body1" style={{ color: grey[100] }}>
        {description}
      </Typography>

      {hasDownload.boolean && (
        <Tooltip title={hasDownload.tooltip} arrow>
          <IconButton
            style={{
              position: 'absolute',
              right: 50,
              top: 3,
              color: 'white'
            }}
            onClick={(e) => hasDownload.action(e)}>
            <GetApp />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="Close Dialog" arrow>
        <IconButton
          style={{ position: 'absolute', right: 3, top: 3, color: 'white' }}
          onClick={handleIconButtonClick}>
          <Close />
        </IconButton>
      </Tooltip>
    </Alert>
  );
};

export default CustomDialog;
