import {
  EDocumentResults,
  EDocumentType
} from 'graphql/FileStorageAPI/interface';
import { firebase } from 'redux/firebase';

export enum CustomerType {
  Prospect = 'Prospect',
  NewCustomer = 'New Customer',
  ExistingCustomer = 'Existing Customer',
  PotentialLead = 'Potential Lead',
  HotLead = 'Hot Lead',
  Referral = 'Referral',
  None = 'None'
}

export interface CompleteUserDefinition {
  UserDefinition: UserDefinition;
  CompleteObjectDefinitionList: CompleteObjectDefinition[];
}

export interface UserDefinition {
  Id: number;
  Title: string;
  ItemOrder?: number;
  Description: string;
  SystemAccess: number;
  SummaryFields?: string;
  HasDetails?: boolean;
  HasProcesses?: boolean;
  HasAuditLog?: boolean;
  HasFCA?: boolean;
  HasEmailLog?: boolean;
  HasRedFlag?: boolean;
  IsPublished?: boolean;
  HasRelationships?: boolean;
  HasOSTicket?: boolean;
  HasSuiteCRM?: boolean;
  ShowInNav?: boolean;
  CompleteObjectDefinitionList?: CompleteObjectDefinition[];
}

export interface CompleteObjectDefinition {
  ObjectDefinition: ObjectDefinition;
  FieldDefinitionList?: FieldDefinition[];
  FieldDefinitionDict: FieldDefinitionDict;
}

export interface ObjectDefinition {
  Id: number;
  Title: string;
  ItemOrder: number;
  UserDefinitionId: number;
  ObjectDescription: string;
  ObjectRepeat: number;
  ObjectProcessIndependent: boolean;
}

export interface FieldDefinitionDict {
  [key: number]: FieldDefinition;
}

export interface FieldDefinition {
  Id: number;
  Title: string;
  ItemOrder: number;
  ObjectDefinitionId: number;
  FieldDescription: string;
  FieldType: string;
  FieldOptions: string;
  FieldRequired: boolean;
  UserDefinition: number;
}

export interface CompleteUserInstance {
  UserInstance: UserInstance;
  ObjectDefinitionReplaceList: number[];
  CompleteObjectInstanceList: CompleteObjectInstance[];
  CompleteObjectInstanceDict: CompleteObjectInstanceDict;
  CurrentUserInstanceCanSubmit: boolean;
  SummaryFields: null;
}

export interface CompleteObjectInstance {
  ObjectInstance: ObjectInstance;
  FieldInstanceList: FieldInstance[];
  FieldInstanceDict?: FieldInstanceDict;
}

export interface ObjectInstance {
  Id: number;
  IsPublished?: boolean;
  ItemOrder: number;
  LastModified?: string;
  ObjectDefinitionId: number;
  ProcessInstanceId: number;
  Selected: boolean;
  Title: string;
  UserDefinitionId: number;
  UserInstanceId: number;
}

export interface FieldInstanceDict {
  [key: number]: FieldInstance;
}

export interface FieldInstance {
  Id: number;
  Title: string;
  ItemOrder?: number;
  LastModified?: Date | string;
  ProcessInstanceId: number;
  FieldDefinitionId: number;
  ObjectDefinitionId: number;
  ObjectInstanceId: number;
  UserInstanceId: number;
  UserDefinitionId: number;
  FieldValue: string;
  IsPublished?: boolean;
}

export interface UserEvent {
  Id: number;
  Title: string;
  UserDefinitionId: number;
  UserInstanceEmail: string;
  UserInstancePassword: string;
  OwnerUserInstanceId: number;
  SystemAccess: number;
  ProfilePicture: string;
  ProxyUserInstanceId: number;
  ThirdPartyId: string;
}

export interface UserInstance {
  ConsoleUserInstanceId: number;
  DefaultMessage: string;
  DocumentFooter: string;
  DocumentHeader: string;
  EmailFooter: string;
  FromAddress: string;
  FromName: string;
  Id: number;
  IsPublished: boolean;
  ItemOrder: number;
  LastModified: string;
  LogoURL: string;
  NickName: string;
  OwnerUserInstanceId: number;
  ProfilePicture: string;
  ProxyUserInstanceId: number;
  RelatedUserPermission: number;
  RelationshipStatus: number;
  SmtpHost: string;
  SmtpPassword: string;
  SmtpPort: number;
  SmtpSSL: boolean;
  SmtpUsername: string;
  SummaryFields: undefined;
  SystemAccess: number;
  TelephoneNumber: string;
  ThirdPartyId: string;
  Title: string;
  UserDefinitionId: number;
  UserInstanceEmail: string;
  UserInstancePassword: string;
  CustomerType?: CustomerType;
}

export interface CompleteObjectInstanceDict {
  [key: number]: CompleteObjectInstance;
}

export interface ProcessSummary {
  ProcessInstanceId: number;
  ProcessInstanceTitle: string;
  UserInstanceId: number;
  UserInstanceTitle: string;
  ProcessDefinitionId: number;
  ProcessDefinitionTitle: string;
  ProcessStepDefinitionId: number;
  ProcessStepDefinitionTitle: string;
  UserDefinitionId: number;
  UserDefinitionTitle: null;
  LastModified: string;
  Guid: string;
  ThirdPartyId: string;
  AssignedUserList: AssignedUser[];
  ProcessStepGroupList: ProcessStepGroup[];
  SelectedFieldList: [];
  ClosedOn: string;
  CreatedOn: string;
  Tag: string;
  Value: number;
}

export interface AssignedUser {
  UserInstanceId: number;
  UserInstanceTitle: string;
  UserDefinitionId: number;
  UserDefinitionTitle: string;
  ProxyUserInstanceId: number;
}

export interface ProcessInstance {
  Guid: string;
  Id: number;
  LastModified: string;
  ProcessDefinitionId: number;
  ProcessStepDefinitionId: number;
  Title: string;
  UserInstanceId: number;
  ClosedOn: string;
  CreatedOn: string;
  Value: string;
  Tag: string;
}

export interface CompleteProcessStepDefinitionDict {
  [key: number]: CompleteProcessStepDefinition;
}

export interface CompleteProcessStepDefinition {
  ProcessStepDefinition: ProcessStepDefinition;
  CompleteObjectDefinitionDict: CompleteObjectDefinitionDict;
  DocumentDict: { [key: number]: string };
}

export interface ProcessStepGroup {
  Id: number;
  Title: string;
  ProcessStepDefinitionIdList: number[];
  ProcessStepGroupLowestMandatoryStatus: number;
  ProcessStepGroupId: number;
}

export interface CompleteObjectDefinitionDict {
  [key: number]: CompleteObjectDefinition;
}

export interface ProcessStepDefinition {
  AttachPdfToEmail: boolean;
  HideLogo: boolean;
  Id: number;
  IsMandatoryForGroup: boolean;
  IsPublished: boolean;
  ItemOrder: number;
  LastModified: string;
  ProcessDefinitionId: number;
  ProcessStepDescription: string;
  ProcessStepEmailBody: string;
  ProcessStepEmailDocument: string;
  ProcessStepEmailSubject: string;
  ProcessStepGroupId: number;
  ProcessStepSelectionType: 'OnBehalfOf' | 'Proxy' | 'Single Select';
  RelatedUserDefinitionId: number;
  Title: string;
  UserDefinitionId: number;
}

export interface CompleteProcessInstance {
  CompleteObjectInstanceDict: CompleteObjectInstanceDict;
  CompleteObjectInstanceList: CompleteObjectInstance[];
  CurrentUserInstanceCanSubmit: boolean;
  NextStepUserInstanceIdDict: {
    [key: number]: string;
  };
  ProcessInstance: ProcessInstance;
  ProcessStepBodyContentDictionary: { [key: number]: string };
  ProcessStepInstanceList: ProcessStepInstance[];
}

export interface ProcessStepInstance {
  Id: number;
  Title: string;
  ItemOrder: number;
  LastModified: string;
  IsPublished: boolean;
  ProcessStepDefinitionId: number;
  ProcessInstanceId: number;
  ProcessDefinitionId: number;
  UserInstanceId: number;
  UserDefinitionId: number;
  Status: number;
  CustomTemplateId: number;
  ProxyUserInstanceId: number;
}

export interface ProcessDefinition {
  Description: string;
  Id: number;
  Title: string;
}

export interface Rule {
  ActionFunction: string;
  ActionValue: string;
  ComparisonFieldDefinitionId: number;
  ComparisonOperator: string;
  ComparisonValue: string;
  Description: string;
  FieldDefinitionId: number;
  Id: number;
  ItemOrder: number;
  ProcessStepDefinitionId: number;
  Title: string;
}

export interface RuleGroup {
  [Id: number]: Rule[];
}

export interface CompleteProcessDefinition {
  ProcessStepGroupDict: { [key: number]: ProcessStepGroup };
  ProcessStepDefinitionSequence: number[];
  CompleteProcessStepDefinitionDict: {
    [key: number]: CompleteProcessStepDefinition;
  };
  ProcessDefinition: ProcessDefinition;
  RuleList: Rule[];
}

export interface LPCInterface {
  CompleteObjectDefinitionDict: CompleteObjectDefinitionDict;
  CompleteObjectInstanceDict: CompleteObjectInstanceDict;
}

export interface Overview {
  ProcessInstanceId: number;
  ProcessInstanceTitle: string;
  UserInstanceId: number;
  ProcessDefinitionId: number;
  ProcessDefinitionTitle: string;
  ProcessStepDefinitionId: number;
  ProcessStepDefinitionTitle: string;
  LastModified: string;
  Guid: string;
}

export interface InteractiveRuleDict {
  [Id: number]: {
    Id: number;
    Title: string;
    ItemOrder: number;
    IsPublished: boolean;
    ProcessStepDefinitionId: number;
    Description: string;
    FieldDefinitionId: number;
    ComparisonOperator: string;
    ComparisonValue: string;
    ComparisonFieldDefinitionId: number;
    ActionFunction: string;
    ActionValue: string;
  };
}

export interface CurrentStep {
  ProcessStepDescription: string;
  ShortListSelected: boolean;
  CompleteObjectInstanceList: CompleteObjectInstance[];
  InstanceListForNextStep: [];
  UserInstanceDictForCurrentStep: { [UserInstanceId: number]: string };
  UserInstanceListForCurrentStep: UserInstanceListForCurrentStep;
  DocumentDict: {};
  ProcessStepGeneratedDocument: string;
  InteractiveRuleDict: InteractiveRuleDict;
}

export type UserInstanceListForCurrentStep = {
  ProcessDefinitionId: number;
  ProcessInstanceId: number;
  UserDefinitionId: number;
  UserInstanceId: number;
  Selected: boolean;
}[];

export enum EInstanceType {
  DEFAULT = 'default',
  FUNDER = 'funder',
  INTRODUCER = 'introducer',
  ACCOUNTS = 'accounts'
}

export type ProxyInstanceSettings = {
  PlatformInstanceId: string;
  hostname: string;
  instanceType?: EInstanceType;
  features: {
    address_lookup: boolean;
    afs_cribsheets: boolean;
    afs_legacydealviewer: boolean;
    audit: boolean;
    calculator: boolean;
    chatter: boolean;
    marketing: boolean;
    fca: boolean;
    redflag: boolean;
    rfgql: boolean;
    storage: boolean;
    test_flight: boolean;
    vrm: boolean;
    message_hub: boolean;
    bips_weekly: boolean;
    noticeboard_instance: boolean;
    dashboard: boolean;
    deal: boolean;
    data: boolean;
    extreme_caution: boolean;
  };
};

export type Extension = {
  name: string;
  description: string;
  Id: string;
  price: number;
  currency: 'GBP';
};

export interface IPlatformSettings {
  FFFViews: string[];
  processesThatUseQuotes: string[];
  IndicativeQuoteViews: string[];
  QuoteViews: string[];
  SpecialObjects: string[];
  SaveableObjects: string[];
  TimeStampFields: string[];
  SuperAdmins: string[];
}

export type EntityType =
  | 'Limited Company'
  | 'Unlimited Company'
  | 'Sole Trader'
  | 'Partnership of 3 and less'
  | 'Partnership (4+)'
  | 'Private Individual'
  | 'Private Individual - High Net Worth'
  | 'Limited Liability Partnership'
  | 'PLC'
  | 'Government Body'
  | 'Non-Profit'
  | 'Non-Regulated Partnership 4+'
  | 'Local Authority'
  | 'Other-Regulated'
  | 'Other-Non-Regulated';

export type CompanyEntity =
  | 'Limited Company'
  | 'Limited Liability Partnership'
  | 'PLC'
  | 'Private Individual';

export interface FbFileRef {
  nickName: string;
  UserInstanceId: number;
  link: string;
  name: string;
  size: number;
  uploaded: firebase.firestore.FieldValue;
  type: string;
  users: string[];
  deleteRequest?: firebase.firestore.FieldValue;
  fileId?: string;
  documentStatus: {
    documentType: EDocumentType;
    documentUUID: string;
    documentStatus: EDocumentResults;
  };
}

export interface IRegulated {
  hasEntity: boolean;
  isRegulatedParty: boolean;
}

type AtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export type TUserSummaryLite = AtLeastOne<{
  UserInstance?: UserInstance;
  CompleteObjectInstanceList?: CompleteObjectInstance[];
}>;
