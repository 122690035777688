import axios from 'axios';

import { firebase } from 'redux/firebase';
import { ReadyContact, PendingContact } from '../../types';

export const fetchListContacts = async (
  listId: string
): Promise<(ReadyContact | PendingContact)[]> => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const contactsUrl = `${process.env.REACT_APP_PROXY}/marketing/lists/${listId}/contacts`;

  const r = await axios.get(contactsUrl, { headers: { token } });

  return r?.data;
};
