import {
  withStyles,
  Theme,
  createStyles,
  Tabs,
  Tab,
  makeStyles
} from '@material-ui/core';
import { useProcess } from 'hooks/useProcess';
import { SyntheticEvent } from 'react';
import { ProcessStepInstance } from 'types/interfaces';
import { StyledBadge } from './';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useMobile } from 'hooks';
import React from 'react';
import { ChildTabs } from './ChildTabs';
import { render } from '@fullcalendar/core/preact';
import { useTypedSelector } from 'redux/reducers';

export const ConsumerDulyConfirmation = [2349, 3679, 2352, 2350, 2352, 2354];
export const NonRegulatedDulyConfirmation = [1586, 1726, 2249];
const AntTabs = withStyles({
  root: {},
  indicator: {}
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      background: theme.palette.background.paper,
      '&:hover': { opacity: 1 },
      '&$selected': {},
      '&:focus': {}
    },
    selected: {
      color: 'white',
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
      fontWeight: theme.typography.fontWeightMedium
    }
  })
)(Tab);

const useStyles = makeStyles({
  arrowIcon: {
    width: '100%',
    transform: 'scale(1.2)',
    '&:hover': {
      transform: 'scale(2.1)'
    }
  },
  tabRootMobile: {
    minWidth: '40%'
  }
});

export const ParentTabs = ({
  parsed,
  setStepGroupIndex,
  handleStepChange,
  stepGroupIndex,
  ProcessStepGroupDict,
  theme,
  currentSelectedTitle,
  setCurrentSelectedTitle,
  show,
  setShow,
  setVisibleTabContent
}) => {
  const classes = useStyles();
  const { currentDeal, currentProcess, landingpage, user } = useProcess();
  const isMobile = useMobile();
  const subSystemUser = user.SystemAccess <= 4;
  const { regulatedStatus } = useTypedSelector((s) => s.process);

  //function to pre load the child tabs

  const isProcessStepGroupList: boolean =
    Object.values(ProcessStepGroupDict).length > 0;

  const badgeClick = (Title: string) => {
    // update current title as previous title
    setCurrentSelectedTitle(Title.toLowerCase());
  };

  // stop click propagation so you can use the show & hide child tab
  const arrowIconClick = (event: SyntheticEvent, Title: string) => {
    if (subSystemUser) {
      return;
    }
    event.stopPropagation();
    const normalizedTitle = Title.toLowerCase();

    const updatedIndex = Object.values(ProcessStepGroupDict).findIndex(
      (item: any) => item.Title.toLowerCase() === Title.toLowerCase()
    );
    if (currentSelectedTitle === normalizedTitle) {
      if (show) {
        setShow(false);
        // setStepGroupIndex(updatedIndex);
        setVisibleTabContent(null);
      } else {
        setShow(true);
        setVisibleTabContent(normalizedTitle);
        setStepGroupIndex(updatedIndex);
      }
    } else {
      setVisibleTabContent(normalizedTitle);
      setShow(true);
      setCurrentSelectedTitle(normalizedTitle);
      setStepGroupIndex(updatedIndex);
    }

    /**toggle display only when they click the same title parent tab otherwise remain the same if they click on a different parent tab title */
    if (currentSelectedTitle.includes(Title)) {
      setShow(!show);
    }
  };

  /** show arrow icon on current focus parent tab otherwise show nothing
   * right arrow icon for false show state / down arrow icon for true show state
   */
  const showArrowIcon = (Title: string) => {
    if (Title.toLowerCase() === currentSelectedTitle) {
      return show ? (
        <ArrowDropDownIcon
          fontSize="medium"
          className={classes.arrowIcon}
          onClick={(event) => arrowIconClick(event, Title.toLowerCase())}
        />
      ) : (
        <ArrowRightIcon
          fontSize="medium"
          className={classes.arrowIcon}
          onClick={(event) => arrowIconClick(event, Title.toLowerCase())}
        />
      );
    } else {
      return (
        <ArrowRightIcon
          fontSize="medium"
          className={classes.arrowIcon}
          onClick={(event) => arrowIconClick(event, Title.toLowerCase())}
        />
      );
    }
  };
  return (
    <AntTabs
      style={{ borderRadius: 10 }}
      variant={isMobile ? 'scrollable' : 'fullWidth'}
      scrollButtons="auto"
      onChange={(e, v) => {
        //if not landing page
        if (!parsed) {
          // setStepGroupIndex(v);
          // handleStepChange(0, v);
        }
      }}
      value={stepGroupIndex}>
      {isProcessStepGroupList &&
        Object.values(ProcessStepGroupDict).map((StepGroup: any) => {
          // For each parent step group create variables for color
          let color: 'default' | 'primary' | 'secondary' | 'error' | undefined =
            'error';
          let coloured = theme.palette.error.main;

          // make an array of all the process step instances
          const mandatoryStatusList: number[] = [];

          StepGroup.ProcessStepDefinitionIdList.forEach((id: number) => {
            const stepDefinition =
              currentProcess?.CompleteProcessStepDefinitionDict?.[id]
                ?.ProcessStepDefinition;

            if (ConsumerDulyConfirmation.includes(stepDefinition.Id)) {
              if (!regulatedStatus) {
                return;
              }
            }

            // filter process step instance's that match current step (essentially each assigned user & status)
            const entries = currentDeal?.ProcessStepInstanceList?.filter(
              (el: ProcessStepInstance) => el.ProcessStepDefinitionId === id
            );

            entries?.forEach((ProcessStepInstance: ProcessStepInstance) => {
              const { ProcessStepDefinitionId } = ProcessStepInstance;

              // determine if the step is mandatory for the step group.
              const { IsMandatoryForGroup } =
                currentProcess?.CompleteProcessStepDefinitionDict?.[
                  ProcessStepDefinitionId
                ].ProcessStepDefinition;

              // if it is mandatory add it to the mandatory status list
              if (IsMandatoryForGroup)
                mandatoryStatusList.push(ProcessStepInstance.Status);
            });
          });

          // find the minimum mandatory status
          const minimumMandatory = Math.min(...mandatoryStatusList);

          // use the minimum mandatory status to determine the traffic light color for the parent group
          switch (minimumMandatory) {
            case 0:
              color = 'error';
              coloured = theme.palette.error.main;
              break;
            case 1:
              color = 'secondary';
              coloured = theme.palette.warning.main;
              break;
            case 2:
              color = 'primary';
              coloured = theme.palette.success.main;
              break;
          }

          // determine if the step is active
          const currentStepDefinitionId =
            currentDeal?.ProcessInstance?.ProcessStepDefinitionId;
          const isActiveStepGroup =
            StepGroup?.ProcessStepDefinitionIdList?.find(
              (i: number) => i === currentStepDefinitionId
            );

          return (
            <AntTab
              key={StepGroup.Id}
              classes={{ root: isMobile ? classes.tabRootMobile : undefined }}
              label={
                <div style={{ width: '100%' }}>
                  <StyledBadge variant="dot" color={color} showZero>
                    {StepGroup.Title}
                  </StyledBadge>

                  {isActiveStepGroup && (
                    <div
                      style={{
                        position: 'absolute',
                        borderTop: `50px solid ${coloured}`,
                        borderBottom: '50px solid transparent',
                        borderLeft: '50px solid transparent',
                        right: 0,
                        top: 0
                      }}
                    />
                  )}

                  {/* Conditional rendering of the arrow icon */}
                  {showArrowIcon(StepGroup.Title)}
                </div>
              }
            />
          );
        })}
    </AntTabs>
  );
};
