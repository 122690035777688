/**Editable input element for type text / email / number / Date, otherwise just present the readonly value */
import React from 'react';
import {
  useTheme,
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import InputBase from '@material-ui/core/InputBase';
import { handleUpdateField } from '../../functions';
import { format } from 'fecha';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { useTypedSelector } from 'redux/reducers';
import EditIcon from '@material-ui/icons/Edit';
import EditableAddress from '../EditableAddress';
import { useDeviceType } from 'hooks/useDeviceType';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      transition: 'all 0.3s ease-in-out',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: 5,
      width: '100%',
      color: (props: any): any => {
        return props.type === 'email' && theme.palette.primary.main;
      },
      cursor: (props): any => props.type === 'email' && 'pointer',
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        color: (props: any): any =>
          props.type === 'email' && theme.palette.primary.contrastText,
        border: `1px ${theme.palette.primary.light} dashed`,
        padding: 15
      }
    },
    readOnlyText: {
      transition: 'all 0.3s ease-in-out',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: 5,
      width: '100%',
      color: (props: any): any => {
        return props.type === 'email' && theme.palette.primary.main;
      }
    },
    fieldInput: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      minHeight: '35px',
      width: '100%',
      background: (props: any) => {
        return props.parentsIsFormComponent
          ? theme.palette.background.default
          : 'inherit';
      },
      borderRadius: theme.shape.borderRadius
    },
    editIcon: {
      marginRight: '10px'
    }
  })
);

const EditableInput = (props: any) => {
  let {
    value,
    variant,
    multiline,
    type,
    FieldDefinition,
    isReadOnly,
    parentsIsFormComponent
  } = props;
  if (type === 'Whole Number') type = 'number';
  if (type === 'Decimal') type = 'number';
  if (type === 'Email') type = 'email';

  const theme = useTheme();
  const currentDevice = useDeviceType();

  const classes: any = useStyles({ type, isReadOnly, parentsIsFormComponent });
  const [edit, setEdit] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { token } = useTypedSelector((s) => s.user.auth);
  const onChange: any = handleUpdateField({
    setUpdating,
    token,
    props
  });

  const [localValue, setLocalValue] = React.useState(value || '');

  let numberConditions =
    type.includes('Currency') ||
    type.includes('number') ||
    type.includes('Whole Number') ||
    type.includes('Decimal');

  if (numberConditions && edit) type = 'number';

  // submit to database
  const submit = async (value) => {
    // only update if value is different
    if (props.value !== value) {
      await onChange(value);
      if (props.requiredCreate && props.handleChange) {
        props.handleChange(value, props.Title);
      }

      if (type.includes('Calculation')) {
        props.refreshCalculationFields && props.refreshCalculationFields();
      }

      props.refresh && props.refresh();
    }

    setEdit(false);
  };

  // update local value
  const handleOnChange = (event) => {
    setLocalValue(event.target.value);
  };

  if (updating) return <CircularProgress />;
  if (edit) {
    if (type === 'Date') {
      value = value === '' ? new Date() : new Date(value);
      return (
        <DatePicker
          className={classes?.root}
          onMouseDown={(event) => {
            event.preventDefault();
            setOpen(true);
          }}
          onChange={(date) => submit(moment(date).format('YYYY-MM-DD'))}
          open={open}
          value={value}
          views={['year', 'month', 'date']}
          openTo="year"
          onClose={() => {
            setEdit(false);
            setOpen(false);
          }}
        />
      );
    } //END if Date

    if (FieldDefinition.FieldDescription === 'AddressLookup') {
      return (
        <div
          style={{
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: theme.shape.borderRadius,
            width: '100%'
          }}>
          <EditableAddress
            value={value}
            onChange={onChange}
            setEdit={setEdit}
            edit={edit}
          />
        </div>
      );
    }

    /**input element for type text / email / number / etc */
    return (
      <div
        style={{
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: theme.shape.borderRadius,
          width: '100%'
        }}>
        <InputBase
          autoFocus
          fullWidth
          defaultValue={value || ''}
          inputProps={{ color: 'red' }}
          multiline={type === 'text' ? true : false}
          onBlur={(e) => submit(e.target.value)}
          onChange={handleOnChange}
          value={localValue}
          onKeyPress={(e: any) => {
            if (!multiline && e.charCode === 13) {
              submit(e?.target?.value);
            }
          }}
          type={type}
        />
      </div>
    );
  } // END if (edit)
  else {
    if (value && type === 'Date') value = format(new Date(value), 'mediumDate');
    const isReadOnly = !props.readOnly;

    const handleInteraction = () => {
      if (isReadOnly) {
        if (type === 'Date' && !value) {
          const today = moment().format('YYYY-MM-DD');
          setLocalValue(today);
          submit(today);
        }
        setEdit(true);
      }
    };

    const interactionHandler =
      currentDevice === 'mobile' || currentDevice === 'tablet'
        ? { onTouchStart: handleInteraction }
        : { onClick: handleInteraction };

    /** list of titles that have values which appears to be url, in that case apply css color blue */
    const textUrl = ['website', 'email address'];

    const editIcon = () =>
      !props.readOnly ? (
        <EditIcon fontSize="inherit" className={classes.editIcon} />
      ) : null;

    return (
      <div
        className={!props.readOnly ? classes.text : classes.readOnlyText}
        {...interactionHandler}>
        {type === 'email' || type === 'Hyperlink' ? (
          <div className={`${classes.fieldInput}`}>
            {editIcon()}

            <Typography component={'div'}>
              <Link
                href={
                  value ? (type === 'email' ? `mailto:${value}` : value) : '#'
                }
                onClick={(e: any) => !value && e.preventDefault()}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                rel="noopener"
                style={{
                  flexGrow: 1,
                  color: 'blue',
                  padding: '.5em'
                }}
                target="_blank">
                {type === 'Hyperlink' && value
                  ? 'Click Here For Link'
                  : type === 'email' && value
                  ? value
                  : '...'}
              </Link>
            </Typography>
          </div>
        ) : type.includes('Currency') ? (
          <div className={classes.fieldInput}>
            {editIcon()}

            <NumberFormat
              decimalScale={2}
              displayType={'text'}
              prefix={'£ '}
              thousandSeparator
              value={value ? value : 0}
            />
          </div>
        ) : type.includes('Percentage') ? (
          <div className={classes.fieldInput}>
            {editIcon()}

            <NumberFormat
              decimalScale={2}
              displayType={'text'}
              suffix={' %'}
              thousandSeparator
              value={value ? value : 0}
            />
          </div>
        ) : (
          <div className={classes.fieldInput}>
            {editIcon()}

            <Typography
              color="inherit"
              style={{
                color: `${
                  textUrl.includes(FieldDefinition?.Title.toLowerCase())
                    ? 'blue'
                    : 'inherit'
                }`
              }}
              variant={variant}
              noWrap={false}>
              {value ? value : '...'}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}; //END EditableInput

export default EditableInput;
