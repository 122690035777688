import { TextField, TextFieldProps } from '@material-ui/core';
import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useState
} from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

/**
 * Interface extending NumberFormatProps for custom number formatting
 * @interface INumberFormat
 * @extends {NumberFormatProps}
 */
export interface INumberFormat extends NumberFormatProps {
  /**
   * onChange handler for the input
   * @param {Object} event - The change event
   * @param {Object} event.target - The target of the event
   * @param {string} event.target.name - The name of the input field
   * @param {string} event.target.value - The new value of the input field
   */
  onChange: (event: { target: { name: string; value: string } }) => void;
  /** The name of the input field */
  name: string;
}

/**
 * Interface for CurrencyField props
 * @interface ICurrencyField
 * @extends {Omit<TextFieldProps, 'onChange'>}
 */
export interface ICurrencyField extends Omit<TextFieldProps, 'onChange'> {
  /**
   * onChange handler for the input
   * @param {Object} event - The change event
   * @param {Object} event.target - The target of the event
   * @param {string} event.target.name - The name of the input field
   * @param {string} event.target.value - The new value of the input field
   */
  onChange: (event: { target: { name: string; value: string } }) => void;
  /** Minimum allowed amount */
  minAmount?: number;
  /** Maximum allowed amount */
  maxAmount?: number;
}

/**
 * Custom number format component for currency input
 * @component
 * @param {INumberFormat} props - The props for the component
 * @param {React.Ref<NumberFormat>} ref - The ref for the NumberFormat component
 * @returns {React.ReactElement} A NumberFormat component configured for currency input
 */
const CustomNumberFormat: ForwardRefExoticComponent<
  INumberFormat & RefAttributes<NumberFormat>
> = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="£"
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
  );
});

/**
 * CurrencyField component for inputting currency values
 * @component
 * @param {ICurrencyField} props - The props for the component
 * @returns {React.ReactElement} A TextField component configured for currency input
 */
export const LenderCurrencyField = ({
  onChange,
  value,
  InputLabelProps,
  minAmount,
  maxAmount,
  ...other
}: ICurrencyField) => {
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const isFieldFilled = Boolean(value);

  /**
   * Handles the change event of the input
   * @param {Object} event - The change event
   * @param {Object} event.target - The target of the event
   * @param {string} event.target.name - The name of the input field
   * @param {string} event.target.value - The new value of the input field
   */
  const handleChange = (event: { target: { name: string; value: string } }) => {
    const numValue = parseFloat(event.target.value);
    let errorMessage;

    if (minAmount !== undefined && numValue < minAmount) {
      errorMessage = `Amount must be at least £${minAmount.toFixed(2)}`;
    } else if (maxAmount !== undefined && numValue > maxAmount) {
      errorMessage = `Amount must not exceed £${maxAmount.toFixed(2)}`;
    }

    setError(errorMessage);
    onChange(event);
  };

  return (
    <TextField
      {...other}
      value={value}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true
      }}
      InputProps={{
        inputComponent: CustomNumberFormat as any
      }}
      onChange={handleChange}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      variant="outlined"
      error={!!error}
      helperText={error}
    />
  );
};
