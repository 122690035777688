const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

export interface ILocation {
  hostname: string;
  baseURL: string;
  globalHostName: string;
  logo: string;
}

export const globalHost = (location: Location) => {
  const stg = process.env.REACT_APP_ENV === 'staging';
  let env: '' | '-dev' = stg ? '' : '-dev';
  let globalHostName = `process-builder${env}`;
  let windowHostName = location.hostname;
  let baseURL = `https://${globalHostName}.cosnetics.co.uk/v1/`;
  let logo = `//${globalHostName}.cosnetics.co.uk/sites/${globalHostName}.cosnetics.co.uk/assets/logo.jpg`;

  const AFSCompliance = [
    'broker-platform.netlify.app',
    'afscompliance.com'
  ].includes(windowHostName);

  const CommercialTech = windowHostName.includes('commercial-tech.com');
  const Bips = windowHostName.includes('bips.tech');
  const superDev = windowHostName.includes('financial--manager-dev.web.app');

  // ! Temp testing env for test funders
  if (superDev) {
    globalHostName = 'financial-manager-dev';
    baseURL = `https://tempoawards.awardsmadeeasy.com/v1/`;
  }

  if (AFSCompliance) globalHostName = 'process-builder';

  if (CommercialTech) {
    globalHostName = windowHostName.split('.commercial-tech.com')[0];
    baseURL = `https://${globalHostName}.cosnetics.co.uk/v1/`;
  }

  if (Bips) {
    globalHostName = windowHostName.split('.bips.tech')[0];
    baseURL = `https://${globalHostName}.api.bips.tech/v1/`;
    logo = `//${globalHostName}.api.bips.tech/sites/${globalHostName}.api.bips.tech/assets/logo.jpg`;
  }

  // Supplied for mocking the environment
  if (process.env.REACT_APP_BASE_URL) {
    if (
      process.env.REACT_APP_BASE_URL ===
      'https://process-builder.cosnetics.co.uk/v1'
    )
      globalHostName = 'process-builder';

    if (
      process.env.REACT_APP_BASE_URL ===
      'https://process-builder-dev.cosnetics.co.uk/v1'
    )
      globalHostName = 'process-builder-dev';

    baseURL = process.env.REACT_APP_BASE_URL;

    const pre = baseURL.replace(/https:\/\//g, '').split('/')[0];
    globalHostName = `${pre.replace(/[^a-zA-Z ]/g, '')}`;
    logo = `//${pre}/sites/${pre}/assets/logo.jpg`;
  }

  const newLocation = {} as ILocation;

  newLocation.globalHostName = globalHostName;
  newLocation.logo = logo;
  newLocation.baseURL = baseURL;
  return newLocation;
};
