import axios from 'axios';
import { store } from 'redux/store';
import { createNotification } from 'react-redux-notify';
import { successNotif, errorNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';

interface AuthenticateDomain {
  domain: string;
  userInstanceId: number;
}

export const createDomain = async ({
  domain,
  userInstanceId
}: AuthenticateDomain) => {
  const { dispatch } = store;
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/domains`;
  const data = { domain, hostname, userInstanceId };
  const config = { headers: { token } };

  return axios
    .post(url, data, config)
    .then((res) => {
      dispatch(createNotification(successNotif('Domain created')));
      gtag.event({
        feature: 'Marketing',
        action: 'Domain Create: Success',
        message: `${domain} created on ${hostname}`
      });
    })
    .catch((e) => {
      dispatch(
        createNotification(
          errorNotif(
            'Failed to Create Domain, it is likely that this domain already exists.'
          )
        )
      );
      BugTracker.notify(e);
    });
};
