import { UserInstance } from 'types/interfaces';

export enum Status {
  Adding = 'Adding',
  Deleting = 'Deleting',
  Ready = 'Ready' // implicit
}

export interface SelectedFilters {
  userGroup: number[];
  customerType: (string | undefined)[];
  status?: (string | undefined)[];
}

export interface PendingContact {
  first_name: string;
  email: string;
  status: Status;
  job_id: string;
}

// sendgrid response:
export interface ReadyContact {
  id: string;
  first_name?: string;
  last_name?: string;
  unique_name?: string;
  email: string;
  phone_number_id?: string;
  external_id?: string;
  anonymous_id?: string;
  alternate_emails?: string[];
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state_province_region?: string;
  country?: string;
  postal_code?: string;
  phone_number?: string;
  whatsapp?: string;
  line?: string;
  facebook?: string;
  list_ids?: string[];
  segment_ids?: string[];
  custom_fields?: Record<string, any>;
  created_at: string; // ISO 8601 date-time
  updated_at: string; // ISO 8601 date-time
}

export interface Contact extends UserInstance {
  id: string; // sendgrid contact id
  status?: string;
  email: string;
}

export interface List {
  name: string;
  id: string;
  contact_count: number;
}

export interface ListFb extends List {
  hostname: string;
  userInstanceId: number;
  ts: {
    seconds: number;
    nanoseconds: number;
  };
}

export const LISTS_PATHS = {
  lists: '/marketing/lists',
  listDetail: '/marketing/lists/:listId',
  addContacts: '/marketing/lists/:listId/add-contacts',
  dashboard: '/marketing/dashboard'
};
