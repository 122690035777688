import {
  errorNotif,
  successNotif,
  warningNotif
} from 'components/Notifications';
import { Dispatch, useState } from 'react';
import { firebase } from 'redux/firebase';
import { FbFileRef } from 'types/interfaces';
import { useDispatch } from 'react-redux';
import { createNotification } from 'react-redux-notify';
import { BugTracker } from 'Utils/Bugtracker';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { IUploadPondFilesParams } from './interface';
import { handleDocumentProgress } from './functions';
import { ADD_FILE } from 'redux/actions/types';

const storage = firebase.storage();
const db = firebase.firestore();

const useFileStorage = () => {
  const dispatch = useDispatch();
  const myFiles = [];
  const dealFiles = [];
  const [uploadProgress, setUploadProgress] = useState(0);
  let uploadState = 'PAUSED';

  const resetUploadProgress = () => {
    setUploadProgress(0);
  };

  /**
   * Uploads a file using FilePond, stores it in Firebase Storage, and creates a reference in Firestore.
   *
   * This function handles the entire upload process, including:
   * - Creating a reference in Firestore
   * - Uploading the file to Firebase Storage
   * - Updating the Firestore document with the upload details
   * - Handling progress updates and state changes
   * - Error handling and notifications
   * - Creating a UUID for fraud detection (if applicable)
   *
   * @param {File} file - The file to be uploaded
   * @param {number} ProcessInstanceId - The ID of the ProcessInstance
   * @param {UserInstance} user - The UserInstance Object
   * @param {(id: string) => void} load - Function to call when upload is complete
   * @param {() => void} abort - Function to call when upload is aborted
   * @param {(percent: number) => void} progress - Function to update upload progress
   * @param {boolean} global - Flag indicating if this is a global upload
   * @param {boolean} landingpage - Flag indicating if this is a landing page upload
   * @param {EDocumentType} documentType - The type of document being uploaded
   *
   * @returns {Object} An object containing methods to control the upload
   * @returns {() => void} returns.abort - Method to abort the upload process
   * @returns {() => Promise<void>} returns.start - Method to start the upload process
   *
   */
  const uploadPondFiles = ({
    file,
    ProcessInstanceId,
    user,
    load,
    abort,
    progress,
    global,
    landingpage,
    documentType,
    updateStatus
  }: IUploadPondFilesParams & { updateStatus: (status: string) => void }): {
    abort: () => void;
    start: () => Promise<void>;
  } => {
    const db = firebase.firestore();
    const storage = firebase.storage();

    const dbRef = db
      .collection('deal')
      .doc(ProcessInstanceId.toString())
      .collection('files')
      .doc();

    const fileId = dbRef.id;
    const url = `files/${ProcessInstanceId}/${user.Id}/${file.name}${fileId}`;

    const fileRef = storage.ref().child(url);
    const uploadTask = fileRef.put(file);

    let isUploading = false;
    const uploadControl = {
      start: async () => {
        if (!isUploading) {
          isUploading = true;
          return new Promise<void>((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progressMade =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                progress(progressMade);
              },
              (error) => {
                console.error('Upload failed:', error);
                BugTracker.notify(error);
                reject(error);
              },
              async () => {
                try {
                  const { documentProgress } = await handleDocumentProgress({
                    file,
                    user,
                    updateStatus,
                    documentType
                  });

                  const fileData: FbFileRef = {
                    UserInstanceId: user.Id,
                    link: `gs://${url}`,
                    uploaded: firebase.firestore.FieldValue.serverTimestamp(),
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    users: [user.Id.toString()],
                    fileId: dbRef.id,
                    nickName: file.name,
                    documentStatus: {
                      documentType,
                      documentUUID: documentProgress.documentUUID,
                      documentStatus: documentProgress.documentStatus
                    }
                  };

                  await dbRef.set(fileData);
                  load(dbRef.id);

                  if (!global && !landingpage) {
                    dispatch(
                      createNotification(
                        successNotif(
                          'Success! To Share This Document, Please Assign It To The Users Who Need Access By Clicking "Viewers".'
                        )
                      )
                    );

                    // Finally we add them to our Deal Redux:
                    dispatch({ type: ADD_FILE, payload: fileData });
                  }
                  resolve();
                } catch (error) {
                  console.error('Error in upload completion:', error);
                  BugTracker.notify(error);
                  reject(error);
                }
              }
            );
            uploadTask.resume();
          });
        }
      },
      abort: () => {
        uploadTask.cancel();
        abort();
      }
    };

    return uploadControl;
  };

  const uploadFile = ({
    files,
    ProcessInstanceId,
    UserInstanceId
  }: {
    files: File[];
    ProcessInstanceId: number;
    UserInstanceId: number;
  }) => {
    // firestore ref
    const dbRef = db
      .collection('deal')
      .doc(ProcessInstanceId.toString())
      .collection('files');

    // Create backwards compatible URL concatination
    const fileId = dbRef.id;
    const url = `files/${ProcessInstanceId.toString()}/${UserInstanceId.toString()}/${
      files[0].name + fileId
    }`;

    const storageRef = storage.ref();
    const fileRef = storageRef.child(url);
    const uploadTask = fileRef.put(files[0]);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: {
            uploadState = firebase.storage.TaskState.PAUSED;
            break;
          }

          case firebase.storage.TaskState.RUNNING: {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log(`Upload is ${progress} % done`);
            setUploadProgress(progress);
            uploadState = firebase.storage.TaskState.RUNNING;
            setUploadProgress(progress);
            console.log(`Upload is ${progress} % done`);
            break;
          }
        }
      },
      (error) => {
        // handle unsuccessful uploads
      },
      async () => {
        // handle successful uploads
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL);
        });

        const link = `gs://${url}`;

        // Save file link to fb deal

        return dbRef
          .add({
            UserInstanceId,
            link,
            uploaded: firebase.firestore.FieldValue.serverTimestamp(),
            name: files[0].name,
            size: files[0].size,
            type: files[0].type,
            users: [UserInstanceId.toString()],
            fileId: dbRef.id,
            nickName: files[0].name
          } as FbFileRef)
          .then((res) => res)
          .catch((error) => error);
      }
    );
  };

  const moveFileToDeal = async ({
    fileMetadata,
    ProcessInstanceId,
    UserInstanceId,
    setTransferLoading
  }: {
    fileMetadata: FbFileRef;
    ProcessInstanceId: number;
    UserInstanceId: number;
    setTransferLoading: Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const dbRef = db
      .collection('deal')
      .doc(ProcessInstanceId.toString())
      .collection('files');

    const storage = firebase.storage();
    let newFileId = fileMetadata.fileId;
    if (!newFileId) {
      newFileId = db.collection('deal').doc().id;
    }

    const hasNickname = fileMetadata.nickName
      ? fileMetadata.nickName
      : fileMetadata.name;

    const typeOfName = fileMetadata.fileId
      ? fileMetadata.nickName + newFileId
      : hasNickname;

    const originalFilePath = `files/0/${UserInstanceId}/${typeOfName}`;
    const newFilePath = `files/${ProcessInstanceId}/${UserInstanceId}/${typeOfName}`;

    try {
      setTransferLoading(true);
      if (fileMetadata.fileId) {
        const existingFiles = await dbRef
          .where('fileId', '==', fileMetadata.fileId)
          .get();

        if (!existingFiles.empty) {
          setTransferLoading(false);
          dispatch(
            createNotification(
              warningNotif(
                `File "${hasNickname}" Already Exists In Deal Storage.`
              )
            )
          );
          return;
        }
      }

      const originalFileRef = storage.ref().child(originalFilePath);
      const url = await originalFileRef.getDownloadURL();
      const response = await fetch(url);
      const blob = await response.blob();

      const oldFiles = fileMetadata.fileId
        ? newFilePath
        : `files/${ProcessInstanceId}/${UserInstanceId}/${
            hasNickname + newFileId
          }`;

      const newFileRef = storage.ref().child(oldFiles);
      const uploadTask = newFileRef.put(blob);
      await new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED:
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload is ${progress}% done`);
                break;
              }
            }
          },
          (e) => {
            BugTracker.notify(e);
            reject(e);
          },
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            const url = `files/${ProcessInstanceId.toString()}/${UserInstanceId.toString()}/${typeOfName}`;
            const link = `gs://${url}`;

            const updatedMetadata = {
              ...fileMetadata,
              fileId: newFileId,
              link,
              uploaded: firebase.firestore.FieldValue.serverTimestamp()
            };
            await dbRef
              .doc(newFileId)
              .set(updatedMetadata)
              .then(() => {
                setTransferLoading(false);

                const nickname = updatedMetadata.nickName
                  ? updatedMetadata.nickName
                  : updatedMetadata.name;

                dispatch(
                  createNotification(
                    successNotif(
                      `File "${nickname}" Successfully Transferred To Deal Storage.`
                    )
                  )
                );
              })
              .catch((e) => BugTracker.notify(e));

            resolve(downloadURL);
          }
        );
      });
    } catch (e) {
      dispatch(
        createNotification(
          errorNotif(`File Error: ${e} Please Contact Support`)
        )
      );
      BugTracker.notify(e);
    }
  };

  const moveFileToGlobal = async ({
    fileMetadata,
    ProcessInstanceId,
    UserInstanceId,
    setTransferLoading
  }: {
    fileMetadata: FbFileRef;
    ProcessInstanceId: number;
    UserInstanceId: number;
    setTransferLoading: Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const globalDbRef = db.collection('deal').doc('0').collection('files');

    const storage = firebase.storage();
    let newFileId = fileMetadata.fileId;
    if (!newFileId) {
      newFileId = db.collection('files').doc().id;
    }

    const hasNickname = fileMetadata.nickName
      ? fileMetadata.nickName
      : fileMetadata.name;

    const typeOfName = fileMetadata.fileId
      ? fileMetadata.nickName + newFileId
      : hasNickname;

    const originalFilePath = `files/${ProcessInstanceId}/${UserInstanceId}/${typeOfName}`;
    const newFilePath = `files/0/${UserInstanceId}/${typeOfName}`;

    try {
      setTransferLoading(true);
      const existingFiles = await globalDbRef
        .where('fileId', '==', newFileId)
        .get();

      if (!existingFiles.empty) {
        setTransferLoading(false);
        dispatch(
          createNotification(
            warningNotif(
              `File "${hasNickname}" Already Exists In Global Storage.`
            )
          )
        );
        return;
      }

      const originalFileRef = storage.ref().child(originalFilePath);
      const url = await originalFileRef.getDownloadURL();
      const response = await fetch(url);
      const blob = await response.blob();

      const globalFileRef = storage.ref().child(newFilePath);
      const uploadTask = globalFileRef.put(blob);

      await new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {},
          (e) => {
            BugTracker.notify(e);
            reject(e);
          },
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            const url = `files/${ProcessInstanceId.toString()}/${UserInstanceId.toString()}/${typeOfName}`;
            const link = `gs://${url}`;

            const updatedMetadata = {
              ...fileMetadata,
              fileId: newFileId,
              link,
              uploaded: firebase.firestore.FieldValue.serverTimestamp()
            };

            await globalDbRef
              .doc(newFileId)
              .set(updatedMetadata)
              .then(() => {
                setTransferLoading(false);
                dispatch(
                  createNotification(
                    successNotif(
                      `File "${hasNickname}" Successfully Transferred To Global Storage.`
                    )
                  )
                );
              })
              .catch((e) => BugTracker.notify(e));

            resolve(downloadURL);
          }
        );
      });
    } catch (e) {
      dispatch(
        createNotification(
          errorNotif(`File Error: ${e} Please Contact Support`)
        )
      );
      BugTracker.notify(e);
    }
  };

  /**
   * Retrieves a list of files from Firestore based on user permissions and context.
   *
   * This function handles different access patterns:
   * 1. Global access: Returns files owned by the logged-in user with the deal of 0
   * 2. System User + Process Owner: Returns files where user is either owner OR member (OR condition)
   * 3. Admin access: Returns all files in the collection
   * 4. Regular access: Returns files where user is a member
   *
   * @param {Object} params - The parameters object
   * @param {number} params.ProcessInstanceId - The ID of the process instance
   * @param {number} params.UserInstanceId - The ID of the current user
   * @param {boolean} params.isAdmin - Whether the current user has admin privileges
   * @param {boolean} [params.global] - If true, returns only files owned by the user
   * @param {boolean} [params.isSystemUserAndProcessOwner] - If true, returns files where user is either owner or member
   *
   * @returns {Promise<{[key: string]: FbFileRef}>} A promise that resolves to an object where:
   *   - keys are document IDs
   *   - values are file reference objects (FbFileRef)
   *
   * @throws {Error} Logs error to console and returns empty object if query fails
   */
  const getFilesList = async ({
    ProcessInstanceId,
    UserInstanceId,
    isAdmin,
    global,
    isSystemUserAndProcessOwner
  }: {
    ProcessInstanceId: number;
    UserInstanceId: number;
    isAdmin: boolean;
    global?: boolean;
    isSystemUserAndProcessOwner?: boolean;
  }) => {
    let ref = {} as firebase.firestore.Query<firebase.firestore.DocumentData>;
    let combinedResults: any[] | null = null;

    // If global then get all files owned by the logged in user
    // If not global then get all files where logged in user is a member
    if (global) {
      ref = db
        .collection('deal')
        .doc(ProcessInstanceId.toString())
        .collection('files')
        .where('UserInstanceId', '==', UserInstanceId);
    } else {
      if (isSystemUserAndProcessOwner) {
        // Will get the DealOwner
        const dealOwner = db
          .collection('deal')
          .doc(ProcessInstanceId.toString())
          .collection('files')
          .where('UserInstanceId', '==', UserInstanceId);

        // Will get the Viewers (Broker when deal has been transferred)
        const dealViewer = db
          .collection('deal')
          .doc(ProcessInstanceId.toString())
          .collection('files')
          .where('users', 'array-contains', UserInstanceId);

        const [snapshot1, snapshot2] = await Promise.all([
          dealOwner.get(),
          dealViewer.get()
        ]);

        const combinedDocs = new Map();
        snapshot1.forEach((doc) => {
          combinedDocs.set(doc.id, { ...doc.data(), id: doc.id });
        });

        snapshot2.forEach((doc) => {
          if (!combinedDocs.has(doc.id)) {
            combinedDocs.set(doc.id, { ...doc.data(), id: doc.id });
          }
        });

        combinedResults = Array.from(combinedDocs.values());
      } else if (isAdmin) {
        ref = db
          .collection('deal')
          .doc(ProcessInstanceId.toString())
          .collection('files');
      } else {
        ref = db
          .collection('deal')
          .doc(ProcessInstanceId.toString())
          .collection('files')
          .where('users', 'array-contains', `${UserInstanceId}`);
      }
    }

    try {
      if (combinedResults) {
        const list = {} as { [key: string]: FbFileRef };
        combinedResults.forEach((doc) => {
          list[doc.id] = doc as FbFileRef;
        });
        return list;
      } else {
        const querySnapshot = await ref.get();
        const list = {} as { [key: string]: FbFileRef };
        querySnapshot.forEach(
          (doc) => (list[doc.id] = doc.data() as FbFileRef)
        );
        return list;
      }
    } catch (error) {
      BugTracker.notify(error);
      return {};
    }
  };

  const updatePermission = async ({
    ProcessInstanceId,
    UserInstanceId,
    key,
    checked
  }) => {
    const ref = db
      .collection('deal')
      .doc(ProcessInstanceId.toString())
      .collection('files')
      .doc(key);

    const users = checked
      ? firebase.firestore.FieldValue.arrayRemove(UserInstanceId.toString())
      : firebase.firestore.FieldValue.arrayUnion(UserInstanceId.toString());

    return ref
      .update({ users })
      .then(() => 'document successfully updated')
      .catch((error) => console.log({ error }));
  };

  const cancelDeletionRequest = ({
    ProcessInstanceId,
    key
  }: {
    ProcessInstanceId: number;
    key: string;
  }) => {
    const docRef = db
      .collection('deal')
      .doc(ProcessInstanceId.toString())
      .collection('files')
      .doc(key);

    return docRef
      .update({
        deleteRequest: null
      })
      .then(() => true)
      .catch((err) => {
        console.log({ err });
        return false;
      });
  };

  const deleteFile = async ({
    amMoreThanSubSystemUser,
    ProcessInstanceId,
    item,
    key
  }: {
    amMoreThanSubSystemUser: boolean;
    ProcessInstanceId: number;
    item: FbFileRef;
    key: string;
  }) => {
    // Backwards compatible URL concatination
    let name = item.name;
    if (item.fileId) name = item.name + item.fileId;
    const url = `files/${ProcessInstanceId.toString()}/${item.UserInstanceId.toString()}/${name}`;

    const storageRef = storage.ref();
    const deleteRef = storageRef.child(url);
    const docRef = db
      .collection('deal')
      .doc(ProcessInstanceId.toString())
      .collection('files')
      .doc(key);

    const handleFirestoreDeletion = () =>
      docRef
        .delete()
        .then(() => {
          console.log('Deleted', docRef);
          return true;
        })
        .catch((err) => {
          console.log({ err });
          return false;
        });

    const handleFirestoreDeleteRequest = () =>
      docRef
        .update({
          deleteRequest: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => true)
        .catch((err) => {
          console.log({ err });
          return false;
        });

    if (!amMoreThanSubSystemUser) {
      return handleFirestoreDeleteRequest();
    } else {
      return deleteRef
        .delete()
        .then(() => handleFirestoreDeletion())
        .catch((error) => {
          if (error.code === 'storage/object-not-found') {
            dispatch(
              createNotification(warningNotif('storage/object-not-found'))
            );
            return handleFirestoreDeletion();
          }
          return false;
        });
    }
  };

  const handleDownload = async ({ downloadUrl, item }) => {
    const response = await fetch(downloadUrl);
    const blob = await response.blob();

    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = item.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(blobUrl);
  };

  const downloadFile = async ({
    ProcessInstanceId,
    item,
    isSelecting,
    downloadPdf
  }: {
    ProcessInstanceId: number;
    item: FbFileRef;
    isSelecting: boolean;
    downloadPdf?: boolean;
  }) => {
    let storageName = item.name;
    if (item.fileId) storageName += item.fileId;
    const storageUrl = `files/${ProcessInstanceId.toString()}/${item.UserInstanceId.toString()}/${storageName}`;

    const storageRef = storage.ref();
    const gsReference = storageRef.child(storageUrl);

    try {
      const downloadUrl = await gsReference.getDownloadURL();

      if (!isSelecting) {
        if (item.name.endsWith('.pdf')) {
          if (downloadPdf) await handleDownload({ downloadUrl, item });
          else {
            dispatch(createNotification(successNotif(`Opening ${item.name}`)));

            return downloadUrl;
          }
        } else {
          await handleDownload({ downloadUrl, item });
          dispatch(
            createNotification(successNotif(`Downloading ${item.name}`))
          );
        }
      } else {
        return downloadUrl;
      }
    } catch (e) {
      dispatch(createNotification(warningNotif(`${e}: ${storageUrl}`)));
      return undefined;
    }
  };

  const downloadAllFiles = async ({
    ProcessInstanceId,
    items
  }: {
    ProcessInstanceId: number;
    items: FbFileRef[];
  }) => {
    const zip = new JSZip();

    const filePromises = Object.values(items).map(async (item: FbFileRef) => {
      let fileName = item.name;
      if (item.fileId) fileName += item.fileId;

      const storageRef = storage.ref();
      const storageUrl = `files/${ProcessInstanceId.toString()}/${item.UserInstanceId.toString()}/${fileName}`;

      try {
        const gsReference = storageRef.child(storageUrl);
        const downloadUrl = await gsReference.getDownloadURL();
        const response = await fetch(downloadUrl);
        const blob = await response.blob();

        let name = item.name;
        zip.file(name, blob);
      } catch (e) {
        dispatch(createNotification(warningNotif(`${e}: ${storageUrl}`)));
      }
    });

    await Promise.all(filePromises);

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `download_${ProcessInstanceId}.zip`);
    });
  };

  const updateName = ({
    ProcessInstanceId,
    key,
    value
  }: {
    ProcessInstanceId: number;
    key: string;
    value: string;
  }) => {
    const ref = db
      .collection('deal')
      .doc(ProcessInstanceId.toString())
      .collection('files')
      .doc(key);
    return ref
      .update({ nickName: value })
      .then(() => 'document successfully renamed')
      .catch((error) => console.log({ error }));
  };

  return {
    cancelDeletionRequest,
    dealFiles,
    deleteFile,
    downloadFile,
    downloadAllFiles,
    getFilesList,
    myFiles,
    resetUploadProgress,
    updatePermission,
    uploadFile,
    uploadPondFiles,
    uploadProgress,
    uploadState,
    updateName,
    moveFileToDeal,
    moveFileToGlobal
  };
};

export default useFileStorage;
