import React, { useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { getProcessDefinitionLite } from 'redux/actions/processes';
import { GetUserInstanceDetail } from 'redux/database';
import { useTypedSelector } from 'redux/reducers';
import { BugTracker } from 'Utils/Bugtracker';
import { createNotification } from 'react-redux-notify';
import { errorNotif } from 'components/Notifications';
import {
  FieldInstance,
  CompleteUserInstance,
  CompleteObjectInstance,
  FieldInstanceDict
} from 'types/interfaces';
import { useDealList } from 'hooks/useDealList';
import DoNotDeal from 'components/DoNotDeal';
import { GetDealColourList } from 'redux/database/Process Definition API';
import { useDispatch } from 'react-redux';
import {
  GetCompleteUserInstanceDetail,
  GetCompleteUserObjectInstanceDetail
} from 'redux/database/User Instance API';
import { getCompleteUserInstanceDetail } from 'redux/actions/GraphQlActions';

interface IProps {
  userInstance: CompleteUserInstance;
}

const OBJECT_DEFINITION_ID = 3464;
const FIELD_DEFINITION_ID = 21745;
const DIRECTORS_OBJECT_DEFINITION_ID = 2853;

export default function StartDealBtn({ userInstance }: IProps) {
  const UserInstanceId =
    userInstance && userInstance.UserInstance && userInstance.UserInstance.Id;
  const UserDefinitionId =
    userInstance &&
    userInstance.UserInstance &&
    userInstance.UserInstance.UserDefinitionId;

  const UserInstance = userInstance && userInstance.UserInstance;

  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { badCompany, getDoNotDealList, historyList } = useDealList(
    userInstance.UserInstance.UserInstanceEmail
  );

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const { token } = useTypedSelector((s) => s.user.auth);
  const { instanceSettings } = useTypedSelector((state) => state.config);
  const [options, setOptions] = React.useState([]);
  const [color, setColor] = React.useState([]);
  const [message, setMessage] = React.useState<null | string>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [openLoading, setOpenLoading] = React.useState(false);

  const checkDirectorFields = (
    CompleteObjectInstanceList: CompleteObjectInstance[],
    amountToCheck: number
  ) => {
    return CompleteObjectInstanceList.every(
      (CompleteObjectInstance: CompleteObjectInstance) => {
        const nonEmptyFieldValues = Object.values(
          CompleteObjectInstance.FieldInstanceList
        ).filter(
          (FieldInstance: FieldInstance) => FieldInstance.FieldValue !== ''
        );

        return nonEmptyFieldValues.length >= amountToCheck;
      }
    );
  };

  const processEntity = async (
    CompleteUserInstanceDetail: CompleteObjectInstance[],
    currentTarget: string
  ) => {
    const hasEntity: CompleteObjectInstance | undefined = Object.values(
      CompleteUserInstanceDetail
    ).find(
      (CompleteObjectInstance: CompleteObjectInstance) =>
        CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
        OBJECT_DEFINITION_ID
    );

    if (!hasEntity) return getData(currentTarget);
    const getEntity = Object.values(hasEntity.FieldInstanceList).find(
      (FieldInstance: FieldInstance) =>
        FieldInstance.FieldDefinitionId === FIELD_DEFINITION_ID
    );

    if (!getEntity) return getData(currentTarget);
    if (getEntity.FieldValue === 'Partnership (4+)') {
      const getDirector = Object.values(CompleteUserInstanceDetail).filter(
        (CompleteObjectInstance: CompleteObjectInstance) =>
          CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
          DIRECTORS_OBJECT_DEFINITION_ID
      );

      if (getDirector.length >= 4) {
        const getDirectorCheck = checkDirectorFields(getDirector, 4);
        if (getDirectorCheck) return getData(currentTarget);
        else {
          dispatch(
            createNotification(
              errorNotif(
                `Partnership (4+) Requires At Least 4 Directors That Contain Information.`
              )
            )
          );
          setOpenLoading(false);
          return;
        }
      }

      dispatch(
        createNotification(
          errorNotif(
            `Partnership (4+) Requires At Least 4 Directors. Please Add More Directors To The Company.`
          )
        )
      );
      setOpenLoading(false);
      return;
    }

    return getData(currentTarget);
  };

  const handleClick = async (event) => {
    const { currentTarget } = event;

    try {
      setOpenLoading(true);
      const CompleteObjectInstanceList = (await getCompleteUserInstanceDetail({
        baseUrl,
        UserInstanceId,
        action: 'CompleteObjectInstanceList'
      })) as CompleteObjectInstance[];

      console.log({ CompleteObjectInstanceList });

      if (CompleteObjectInstanceList) {
        return await processEntity(CompleteObjectInstanceList, currentTarget);
      }
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const handleClose = () => setAnchorEl(null);
  const startDeal = async (processDefinitionId: string) => {
    const obj = { token, processDefinitionId };
    const isData = await getProcessDefinitionLite(obj);
    if (isData) {
      handleClose();
      const pathname = '/deals/create';
      history.push({ pathname, state: { UserInstanceId, UserDefinitionId } });
    }
  };

  const getData = async (currentTarget) => {
    if (userInstance) {
      try {
        const getColors = await GetDealColourList({ UserInstanceId });

        if (Object.keys(getColors.data).length > 0) {
          setMessage(null);
          setOptions(getColors.data);
          setOpenLoading(false);
          setLoading(false);

          return setAnchorEl(currentTarget);
        } else {
          return setMessage('No available deals');
        }
      } catch (e) {
        BugTracker.notify(e);
      }
    } else {
      /** If we are not starting a deal from a user then we need to get all available processes **/
      /** CURRENTLY NOT USED FUNCTIONALITY */
      // const res = await getProcessDefinitions({ token, action: 'LIST' });
      // let newOptions = {};
      // Object.values(res.data).forEach((el) => {
      //   newOptions[el.Id] = el.Title;
      // });
      // setOptions(newOptions);
      // return newOptions;
    }
  };

  // if company is currently listed under extreme caution, than display the extreme alert warning, also user will not have the ability to create a new deal
  if (badCompany) {
    return (
      <div onClick={getDoNotDealList}>
        <DoNotDeal
          name={UserInstance?.Title}
          company_number={UserInstance?.UserInstanceEmail}
          UserDefinitionId={UserInstance?.UserDefinitionId}
          type="company"
        />
      </div>
    );
  }
  return (
    <div>
      <Button
        data-cy="start-deal-btn"
        color="primary"
        variant="contained"
        onClick={async (e: React.MouseEvent) => await handleClick(e)}
        style={{
          minWidth: '120px',
          height: '37px',
          position: 'relative',
          display: 'inline-flex',
          justifyContent: 'center'
        }}>
        <CircularProgress
          size={24}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
            visibility: openLoading ? 'visible' : 'hidden'
          }}
        />
        {!openLoading && (
          <>
            {mobile
              ? '+'
              : `Start ${
                  instanceSettings.ProcessesName
                    ? instanceSettings.ProcessesName
                    : 'Deal'
                } +`}
          </>
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}>
        {loading ? (
          <MenuItem onClick={handleClose}>
            <CircularProgress />
          </MenuItem>
        ) : message ? (
          <MenuItem onClick={handleClose}>
            <Typography color="textPrimary">{message}</Typography>
          </MenuItem>
        ) : (
          <>
            <MenuItem disabled>Select A Deal Type To Get Started</MenuItem>
            {Object.values(options).map((item: any, idx) => {
              return (
                <MenuItem
                  data-cy="start-deal-menu"
                  key={idx}
                  onClick={() => startDeal(item.Id)}>
                  <Typography
                    style={{
                      color: item.BgColor || 'black',
                      fontWeight: 'bold'
                    }}>
                    {item.Title}
                  </Typography>
                </MenuItem>
              );
            })}
          </>
        )}
      </Menu>
    </div>
  );
}
