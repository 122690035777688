import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaUserPlus } from 'react-icons/fa';
import { Typography } from '@material-ui/core';
import { IoMdContact } from 'react-icons/io';

import { SuccessButton } from 'common/Button';
import { UserInstance } from 'types/interfaces';
import SelectedContactsDialog from './SelectedContactsDialog';
import Table from 'components/SimpleTable';
import { mapUserDefinitionIdToName } from 'helpers';
import { TableFilters } from '../../components/TableFilters';
import { SelectedFilters } from '../../types';
import { useMarketing } from '../../../context';
import CustomerTypeRow from '../../components/CustomerTypeRow';

const columns = [
  {
    title: 'Name',
    field: 'Title'
  },
  {
    title: 'Email',
    field: 'UserInstanceEmail'
  },
  {
    title: 'User Group',
    field: 'UserDefinitionId',
    render: ({ UserDefinitionId }) =>
      mapUserDefinitionIdToName(UserDefinitionId),
    customFilterAndSearch: () => null // disables search filter on this column
  },
  {
    title: 'Customer Type',
    field: 'CustomerType',
    customFilterAndSearch: () => null,
    render: (rowData: UserInstance) => {
      return (
        <CustomerTypeRow
          currentCustomerType={rowData.CustomerType}
          userInstanceId={rowData.Id}
        />
      );
    }
  }
];

const AddContacts = () => {
  const location = useLocation<{ listName: string; listId: string }>();
  const { listName } = location.state || {};
  const [dialogOpen, setDialogOpen] = useState(false);
  const { loading, marketingData } = useMarketing();
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    userGroup: [],
    customerType: []
  });
  const [selectedContacts, setSelectedContacts] = useState<UserInstance[]>([]);

  const filteredData = marketingData.filter((d: UserInstance) => {
    const { userGroup, customerType } = selectedFilters;

    return (
      (!userGroup.length || userGroup.includes(d.UserDefinitionId)) &&
      (!customerType.length || customerType.includes(d.CustomerType))
    );
  });

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between'
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0',
            opacity: 0.8
          }}>
          <IoMdContact
            style={{
              marginRight: 10,
              fontSize: 50,
              color: 'grey'
            }}
          />
          <Typography
            variant="h3"
            style={{
              color: 'grey'
            }}>
            Add Contacts to <span style={{ color: 'black' }}>{listName}</span>
          </Typography>
        </div>

        <div style={{ margin: '15px 0' }}>
          <SuccessButton
            data-testid="open-add-contacts"
            onClick={() => setDialogOpen(true)}>
            <FaUserPlus style={{ margin: 5 }} />
            {`Add (${selectedContacts.length}) Selected Contacts`}
          </SuccessButton>
        </div>
      </div>

      <Table
        title={`Contacts (${filteredData.length})`}
        searchPlaceholder={'Name or Email'}
        columns={columns}
        data={filteredData}
        isLoading={loading}
        onSelectionChange={(rows) => setSelectedContacts(rows)}
        options={{
          selection: true,
          pageSize: 10
        }}
        components={{
          Toolbar: (toolbarProps) => (
            <TableFilters
              {...toolbarProps}
              hasSelectedRows={!!selectedContacts.length}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          )
        }}
      />
      <SelectedContactsDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        selectedContacts={selectedContacts}
      />
    </>
  );
};

export default AddContacts;
