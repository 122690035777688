import React, { Fragment } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useForm } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';
import { useMutation, useQueryClient } from 'react-query';

import { SuccessButton } from 'common/Button';
import { ISender, SENDER, SenderDetails } from '../types';
import { CustomDialog } from 'common/Dialog';
import { updateSender, createSender } from '../functions';
import { QueryKeys } from 'components/Marketing/types';
import { useTypedSelector } from 'redux/reducers';

const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

interface Props {
  sender?: ISender;
  hasWritePermissions?: boolean;
}

export default function UpsertSenderDialog({
  sender,
  hasWritePermissions
}: Props) {
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm();
  const user = useTypedSelector((s) => s.user.user);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<ISender>(sender || SENDER);

  const {
    mutate: create,
    isLoading,
    error: createErrors
  } = useMutation(createSender, {
    onSuccess: (valid) => {
      if (valid === false) return;

      queryClient.invalidateQueries(QueryKeys.FetchSenders);
      setOpen(false);
    }
  });

  const {
    mutate: update,
    isLoading: updateLoading,
    error: updateErrors
  } = useMutation(updateSender, {
    onSuccess: (valid) => {
      if (valid === false) return;

      queryClient.invalidateQueries(QueryKeys.FetchSenders);
      setOpen(false);
    }
  });

  const onSubmit = () => {
    if (sender) update(state);
    else
      create({
        ...state,
        hostname,
        userInstanceId: user.Id
      });
  };

  const errors = [
    // axios errors
    ...(Array.isArray(createErrors) ? createErrors : []),
    ...(Array.isArray(updateErrors) ? updateErrors : [])
  ];

  const loading = isLoading || updateLoading;

  const isEditMode = !!sender;

  const disabled = isEditMode && !hasWritePermissions;

  return (
    <>
      <SuccessButton
        data-testid={isEditMode ? `edit-sender-${sender.id}` : 'create-sender'}
        onClick={() => setOpen(true)}
        variant="contained"
        disabled={disabled}>
        {isEditMode ? 'Edit' : 'Create Sender'}
      </SuccessButton>

      <CustomDialog
        open={open}
        handleClose={() => setOpen(false)}
        maxSize="sm"
        aria-labelledby="form-dialog-title"
        alert={{
          title: `${isEditMode ? 'Edit Sender' : 'Create Sender'}`,
          description: `${
            isEditMode
              ? 'Here you can Update the Sender in case.'
              : 'Please Create a new Sender that will send out Campaigns'
          }`,
          type: 'info'
        }}>
        <DialogContent>
          <DialogContentText>
            <Alert severity="info">
              <Typography>
                You may only create one sender on your current plan. These
                details will be included in all or your email marketing sends.
              </Typography>
            </Alert>
          </DialogContentText>
          <form
            data-testid={
              isEditMode
                ? `upsert-sender-form-${sender.id}`
                : `upsert-sender-form`
            }
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            {Object.keys(SenderDetails).map((key) => {
              const { required, hidden, title } = SenderDetails[key];
              if (hidden) return;

              return (
                <Fragment key={key}>
                  <TextField
                    {...(register(key), { required })}
                    error={errors?.find((e) => e.field === key)}
                    fullWidth
                    id={key}
                    key={key}
                    label={title}
                    margin="dense"
                    onChange={(e) =>
                      setState((s) => ({ ...s, [key]: e.target.value }))
                    }
                    type="text"
                    value={state[key]}
                    variant="outlined"
                  />
                  {errors?.find((e) => e.field === key) && (
                    <Typography variant="caption">
                      {errors.find((e) => e.field === key).message}
                    </Typography>
                  )}
                </Fragment>
              );
            })}
            <div
              style={{
                margin: '12px 0',
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              <ButtonGroup color="inherit" variant="contained">
                <SuccessButton
                  type="submit"
                  style={{ width: 100 }}
                  data-testid={
                    isEditMode
                      ? `update-sender-btn-${sender.id}`
                      : `submit-sender-btn`
                  }>
                  {loading ? (
                    <span style={{ color: 'white ' }}>
                      <CircularProgress color="inherit" size={20} />
                    </span>
                  ) : isEditMode ? (
                    'update'
                  ) : (
                    'submit'
                  )}
                </SuccessButton>
                <Button onClick={() => setOpen(false)}>Close</Button>
              </ButtonGroup>
            </div>
          </form>
        </DialogContent>
      </CustomDialog>
    </>
  );
}
