export interface Email {
  name: string;
  created_at: string;
  email_config: {
    editor: string;
    custom_unsubscribe_url: string | null;
    ip_pool: any;
    plain_content: string;
    html_content: string;
    sender_id: number;
    subject: string;
    suppression_group_id: number;
    generate_plain_content: boolean;
  };
  send_at: string;
  send_to: {
    all: boolean;
    list_ids: string[];
    segment_ids: string[];
  };
  ts: {
    _seconds: number;
    _nanoseconds: number;
  };
  categories: any[];
  updated_at: string;
  count: number;
  hostname: string;
  id: string;
  listName: string;
  listId: string;
  designId: string;
  customArgs: any;
  sender_id: number;
  status: string;
  userInstanceId: number;
  senderFromEmail: string;
  senderReplyEmail: string;
}

export interface Campaigns {
  emails: Email[];
  emails_sorted: {
    draft: Email[];
    scheduled: Email[];
    triggered: Email[];
  };
}

export enum Broker {
  Synergy = 'synergy',
  AFS = 'afs'
}

export enum QueryKeys {
  FetchCampaigns = 'fetchCampaigns',
  FetchCampaignsStats = 'fetchCampaignsStats',
  FetchCampaign = 'fetchCampaign',
  SendEmail = 'sendEmail',
  FetchEventDetails = 'fetchEventDetails',
  FetchDesigns = 'fetchDesigns',
  FetchDesign = 'fetchDesign',
  FetchList = 'fetchList',
  FetchLists = 'fetchLists',
  FetchListContacts = 'fetchListContacts',
  FetchListContactCount = 'fetchListContactCount',
  FetchDomains = 'fetchDomains',
  FetchSenders = 'fetchSenders',
  ValidateSenderDomains = 'validateSenderDomains',
  DeleteSender = 'deleteSender',
  UpdateSender = 'updateSender',
  CreateSender = 'createSender'
}
