import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from 'components/Fields/TextField';
import { useHistory } from 'react-router-dom';
import { createDeal, getProcessDefinition } from 'redux/actions/processes';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TransitionProps } from '@material-ui/core/transitions';
import { useTypedSelector } from 'redux/reducers';
import { ProcessDefinition } from 'types/interfaces';
import { CustomDialog } from 'common/Dialog';
import {
  Box,
  DialogContent,
  MenuItem,
  Select,
  Typography,
  useTheme,
  TextField as MaterialIU_TextField
} from '@material-ui/core';
import { Check, CheckCircle, Warning } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import NumericFormat from 'react-number-format';

interface IStepZero {
  definition: ProcessDefinition;
  UserInstanceId: number;
  UserDefinitionId: number;
}

export enum ERegulatedStatus {
  REGULATED = 'Regulated',
  NON_REGULATED = 'Non-Regulated',
  NONE = 'None'
}

const Component = ({
  definition,
  UserInstanceId,
  UserDefinitionId
}: IStepZero) => {
  const theme = useTheme();
  const history = useHistory();

  const { user } = useTypedSelector((s) => s.user);
  const currentProcess = useTypedSelector((s) => s.process.currentProcess);

  const [loading, setLoading] = useState<boolean>(false);
  const [dealName, setDealName] = useState<string>('');
  const [dealValue, setDealValue] = useState<number>(0);
  const [tag, setTag] = React.useState<ERegulatedStatus>(ERegulatedStatus.NONE);

  const allChecked = dealName !== '';

  const classes = useStyles({ allChecked });
  const handleClose = () =>
    history.push(`/UserDefinition/${UserDefinitionId}/${UserInstanceId}`);

  const handleDealNameChange = (value) => setDealName(value);
  const handleDealValueChange = (value) => setDealValue(value);
  const handleTagChange = (value) => setTag(value);

  const nextStep = currentProcess.ProcessStepDefinitionSequence[0];
  const handleCreateDeal = async (): Promise<void> => {
    setLoading(true);

    const dealCreationProps = {
      ProcessInstance: {
        Title: dealName,
        ProcessDefinitionId: definition.Id,
        ProcessStepDefinitionId: nextStep,
        Value: dealValue,
        Tag: tag
      }
    };

    await getProcessDefinition({
      ProcessDefinitionId: definition.Id.toString(),
      ProcessStepDefinitionId: nextStep
    });

    let dealInRedux = await createDeal({
      deal: dealCreationProps,
      UserInstanceId,
      definition,
      userId: user.Id
    });

    if (dealInRedux.success) {
      history.push(`/deals`);
      setLoading(false);
    }
  };

  // React.useEffect(() => {
  //   dispatch({ type: 'SET_CURRENT_DEAL', payload: deal });
  // }, []);

  return (
    <CustomDialog
      maxSize="md"
      handleClose={handleClose}
      open
      alert={{
        title: ` Create New: ${definition.Title}`,
        description:
          'Please complete the Required Fields and give the deal a name.',
        type: 'warning'
      }}>
      <DialogContent>
        <Grid container direction="column" className={classes.primaryFields}>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <Box display="flex" alignItems="center">
              {dealName === '' ? (
                <Warning
                  style={{
                    marginRight: theme.spacing(1),
                    marginTop: theme.spacing(1),
                    color: theme.palette.warning.main
                  }}
                />
              ) : (
                <CheckCircle
                  color="primary"
                  style={{
                    marginRight: theme.spacing(1),
                    marginTop: theme.spacing(1),
                    color: theme.palette.success.main
                  }}
                />
              )}
              <TextField
                className={classes.requiredField}
                autoFocus
                margin="dense"
                title="Deal Name"
                type="text"
                fullWidth
                required
                handleChange={handleDealNameChange}
                placeholder="Enter Deal Name"
              />
            </Box>
          </Grid>
          {/* <div style={{ paddingTop: theme.spacing(2) }}>
            <Alert severity="info">
              <AlertTitle>Important Fields for Deal Creation</AlertTitle>

              <Typography variant="body1" color="textSecondary">
                Please provide an estimated deal value (this can be adjusted
                later) and select whether this deal is regulated or
                non-regulated. Having accurate initial estimates helps with deal
                tracking and compliance requirements.
              </Typography>
            </Alert>
          </div> */}
          {/* <Grid container item spacing={2}>
            <Grid item xs={12} style={{ position: 'relative' }}>
              <Box display="flex" alignItems="center" style={{ marginTop: 2 }}>
                <NumericFormat
                  customInput={MaterialIU_TextField}
                  margin="dense"
                  title="Deal Value"
                  fullWidth
                  variant="outlined"
                  required
                  placeholder="Enter Deal Value"
                  value={dealValue}
                  onValueChange={(values) => {
                    handleDealValueChange(values.floatValue);
                  }}
                  thousandSeparator
                  prefix="£"
                  decimalScale={2}
                  allowNegative={false}
                  fixedDecimalScale
                />
              </Box>
            </Grid>
          </Grid> */}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>

        <Button
          data-cy="create-user-button"
          onClick={handleCreateDeal}
          className={classes.proceedButton}
          disabled={!allChecked}
          variant="contained"
          startIcon={loading ? <CircularProgress size={20} /> : <Check />}>
          {loading ? `Creating ${dealName}...` : `Create ${dealName}`}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default Component;

const useStyles = makeStyles((theme) => ({
  content: { padding: theme.spacing(2) },
  appBar: { position: 'sticky' },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.primary.contrastText
  },
  dialog: {},
  primaryFields: {
    padding: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: 500
  },
  proceedButton: {
    backgroundColor: (props: { allChecked: boolean }) =>
      props.allChecked ? '#4CAF50' : theme.palette.grey[300],
    color: (props: { allChecked: boolean }) =>
      props.allChecked
        ? theme.palette.common.white
        : theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: (props: { allChecked: boolean }) =>
        props.allChecked ? '#45a049' : theme.palette.grey[400]
    }
  },
  sectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 16
  },
  requiredField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.warning.main,
        borderWidth: 2
      },
      '&:hover fieldset': {
        borderColor: theme.palette.warning.dark
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.warning.dark
      }
    }
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
