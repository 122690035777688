import {
  makeStyles,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { OverridePayment } from 'types/calculatorInterfaces';

interface ISeasonalPaymentsTableProps {
  startDate: string;
  overridePayments: string;
}

interface IFormattedRow {
  startDate: string;
  payments: string;
  amount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2)
  },
  tableContainer: {
    maxWidth: 800,
    margin: '0 auto'
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[50]
  },
  amountCell: {
    textAlign: 'right'
  }
}));

const OutputSeasonalPaymentsTable = ({
  startDate,
  overridePayments
}: ISeasonalPaymentsTableProps) => {
  const classes = useStyles();

  const parsePayments = (jsonString: string): OverridePayment[] => {
    try {
      const parsed = JSON.parse(jsonString);
      return Array.isArray(parsed) ? parsed : [];
    } catch (error) {
      console.error('Error parsing override payments:', error);
      return [];
    }
  };

  const payments = parsePayments(overridePayments);
  const seasonalPayments = payments
    .filter((payment) => 'IsMonth' in payment)
    .sort((a, b) => a.StartPeriod - b.StartPeriod);

  const rows: IFormattedRow[] = seasonalPayments.map((payment, index) => {
    const monthsToAdd = payment.StartPeriod - 1 + (index > 0 ? 1 : 0);
    const paymentDate = moment(startDate)
      .add(monthsToAdd, 'months')
      .format('M/D/YYYY');

    return {
      startDate: paymentDate,
      payments: `${payment.EndPeriod - payment.StartPeriod + 1} Months`,
      amount: payment.Amount
    };
  });

  if (rows.length === 0) return <div />;
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>Seasonal Payments</Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell>Start Date</TableCell>
              <TableCell>Payments</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.startDate}</TableCell>
                <TableCell>{row.payments}</TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={row.amount}
                    displayType={'text'}
                    thousandSeparator
                    prefix={'£'}
                    decimalScale={2}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OutputSeasonalPaymentsTable;
