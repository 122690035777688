// src/redux/actions/noticeboardActions.ts
import {
  FETCH_NOTICEBOARD_REQUEST,
  FETCH_NOTICEBOARD_SUCCESS,
  FETCH_NOTICEBOARD_FAILURE,
  CLEAR_NOTICEBOARD,
  NoticeboardInstance
} from './types';

export const fetchNoticeboardRequest = () => ({
  type: FETCH_NOTICEBOARD_REQUEST
});

export const fetchNoticeboardSuccess = (data: {
  host?: NoticeboardInstance;
  global?: NoticeboardInstance;
  brand?: NoticeboardInstance;
}) => ({
  type: FETCH_NOTICEBOARD_SUCCESS,
  payload: data
});

export const fetchNoticeboardFailure = (error: string) => ({
  type: FETCH_NOTICEBOARD_FAILURE,
  payload: error
});

export const clearNoticeboard = () => ({
  type: CLEAR_NOTICEBOARD
});
