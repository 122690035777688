import { store } from 'redux/store';
import { createNotification } from 'react-redux-notify';
import { errorNotif, successNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';

interface ValidateSenderDomains {
  senderFromEmail: string;
  senderReplyToEmail: string;
}

export const validateSenderDomains = async ({
  senderFromEmail,
  senderReplyToEmail
}: ValidateSenderDomains): Promise<boolean> => {
  const db = firebase.firestore();
  const { dispatch } = store;

  const replyRootdomain = senderReplyToEmail.split('@')[1];
  const fromRootdomain = senderFromEmail.split('@')[1];

  try {
    const [fromRootdomainSnap, replyRootdomainSnap] = await Promise.all([
      db
        .collection('domain')
        .where('domain', '==', fromRootdomain)
        .where('valid', '==', true)
        .limit(1)
        .get(),
      db
        .collection('domain')
        .where('domain', '==', replyRootdomain)
        .where('valid', '==', true)
        .limit(1)
        .get()
    ]);

    const errorMessages: string[] = [];

    if (fromRootdomainSnap.empty) {
      errorMessages.push(
        `FROM Email DOMAIN: "${fromRootdomain}" is invalid or not created.`
      );
    }

    if (replyRootdomainSnap.empty) {
      errorMessages.push(
        `REPLY Email DOMAIN: "${replyRootdomain}" is invalid or not created.`
      );
    }

    if (errorMessages.length) {
      errorMessages.forEach((message) => {
        dispatch(createNotification(errorNotif(message)));
      });

      return false;
    }

    dispatch(
      createNotification(
        successNotif(`FROM Email: "${senderFromEmail}" is Valid`)
      )
    );
    dispatch(
      createNotification(
        successNotif(`REPLY Email: "${senderReplyToEmail}" is Valid`)
      )
    );

    gtag.event({
      feature: 'Marketing',
      action: 'validateSenderDomains: Success',
      message: 'All domains are successfully linked.'
    });

    return true;
  } catch (e: any) {
    BugTracker.notify(e);
    throw e;
  }
};
