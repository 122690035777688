import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useProcess } from 'hooks/useProcess';
import { useRegulated } from 'hooks/useRegulated';
import { CompleteObjectDefinition } from 'types/interfaces';
import { regulatedFilter } from 'components/Stepper/components/Steps/ActiveStep/helper/regulatedFilter';
import { permissionFilter } from 'components/Stepper/components/Steps/ActiveStep/helper/permissionFilter';
import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';
import { sortGroups } from 'components/Stepper/components/Steps/ActiveStep/functions';
import { useTypedSelector } from 'redux/reducers';
import { UPDATE_FORM_VALUE } from 'redux/actions/types';
import { globalIds } from 'helpers/globalIdConfig';

interface IGroups {
  [group_name: string]: CompleteObjectDefinition[];
}

export const useForm = () => {
  const dispatch = useDispatch();
  let {
    currentDeal,
    currentProcess,
    currentStepId,
    sorted,
    stepdefdict,
    user,
    landingpage
  } = useProcess();

  const { value } = useTypedSelector((s) => s.formReducer);
  const { entityType } = useTypedSelector((s) => s.process);
  const { regulated } = useRegulated();

  const [groups, setGroups] = React.useState<IGroups>({});
  const [groupIds, setGroupIds] = React.useState<number[]>([0]);
  const [displayObjects, setDisplayObjects] =
    React.useState<CompleteObjectDefinition[]>(sorted);

  // Live filters
  sorted = sorted.filter((item: CompleteObjectDefinition) => {
    const havePermission = permissionFilter(
      item.ObjectDefinition.ObjectDescription,
      user
    );

    const canDisplayBasedOnParty = regulatedFilter({
      item,
      entityType,
      regulated
    });

    return havePermission && canDisplayBasedOnParty;
  });

  const handleValueChange = (ObjectInstanceId: number) =>
    dispatch({ type: UPDATE_FORM_VALUE, payload: ObjectInstanceId });

  const getMergedField = (
    CompleteObjectDefinition: CompleteObjectDefinition
  ): boolean => {
    const mergeRedflag =
      CompleteObjectDefinition.ObjectDefinition.ObjectDescription.includes(
        'merge'
      ) && !regulated.isRegulatedParty;

    return mergeRedflag;
  };

  /**
   * Helper function to check if an object is marked for both regulated and non-regulated use
   */
  const isCustomRegMarker = (item: CompleteObjectDefinition): boolean => {
    if (!regulated.isRegulatedParty) {
      return (
        item?.ObjectDefinition?.ObjectDescription?.includes('customNonReg') ||
        false
      );
    } else return false;
  };

  /**
   * Filters objects to identify red flags based on entity type and object definitions.
   *
   * @param {Object} params - The parameters object
   * @param {string} params.entityType - Type of entity being processed
   * @param {Array<CompleteObjectDefinition>} params.sorted - Array of sorted object definitions
   * @param {Object} params.regulated - Object containing regulated party information
   * @param {boolean} params.regulated.isRegulatedParty - Whether the entity is a regulated party
   * @param {Object} params.globalIds - Object containing global identifier references
   * @param {Object} params.globalIds.customer.directors - Directors-related identifiers
   *
   * @returns {Array<CompleteObjectDefinition>} Filtered array of objects that represent red flags
   *
   */
  const redflagObjects = React.useMemo(() => {
    if (entityType === 'Partnership (4+)') {
      return [];
    }

    return sorted.filter((item: CompleteObjectDefinition) => {
      const merged = getMergedField(item);
      const duplication =
        item.ObjectDefinition.ObjectDescription.includes('duplication');
      const isCustomReg = isCustomRegMarker(item);

      if (duplication && regulated.isRegulatedParty) return false;

      const isDirectorObject =
        item.ObjectDefinition.Id ===
        globalIds.customer.directors.DirectorsObjectDefinition;

      const isShareholderObject =
        item.ObjectDefinition.Id ===
        globalIds.customer.shareholders.ShareHolderObjectDefinition;

      return (
        isDirectorObject ||
        isShareholderObject ||
        ReadOnlyRedflag({ ObjectDefinition: item.ObjectDefinition }) ||
        merged ||
        isCustomReg
      );
    });
  }, [entityType, sorted]);

  const noredflag: CompleteObjectDefinition[] = sorted.filter(
    (item: CompleteObjectDefinition) =>
      !item?.ObjectDefinition?.ObjectDescription?.includes('redflag') &&
      !item?.ObjectDefinition?.ObjectDescription?.includes('group#')
  );

  const grouped: CompleteObjectDefinition[] = sorted.filter(
    (item: CompleteObjectDefinition) =>
      item?.ObjectDefinition?.ObjectDescription?.includes('group#')
  );

  const SortGroups = () => {
    const { groups, groupObjectDefinitionIdList } = sortGroups({
      currentProcess,
      grouped,
      stepdefdict,
      currentDeal
    });

    setGroups(groups);
    setGroupIds(groupObjectDefinitionIdList);
  };

  React.useEffect(() => {
    SortGroups();
  }, [currentStepId, regulated.isRegulatedParty, entityType]);

  React.useEffect(() => {
    let displayObjects: CompleteObjectDefinition[] = sorted.filter(
      (item: CompleteObjectDefinition) =>
        regulatedFilter({ item, entityType, regulated })
    );

    const numberOfGroups: number = Object.keys(groups).length;
    if (landingpage && numberOfGroups !== 0) {
      const key = Object.keys(groups)[0];
      if (key) {
        const CompleteObjectDefinition: CompleteObjectDefinition =
          groups[key][0];
        const ObjectDefinitionId = CompleteObjectDefinition.ObjectDefinition.Id;
        dispatch({ type: UPDATE_FORM_VALUE, payload: ObjectDefinitionId });
      }
    } else {
      const PartiesAndContacts = sorted.find(
        (el: CompleteObjectDefinition) => el.ObjectDefinition.Id === 3625
      )?.ObjectDefinition.Id;
      const ObjectDefinitionId = PartiesAndContacts
        ? PartiesAndContacts
        : sorted?.[0]?.ObjectDefinition?.Id;
      dispatch({ type: UPDATE_FORM_VALUE, payload: ObjectDefinitionId });
    }

    setDisplayObjects(displayObjects);
  }, [regulated.isRegulatedParty, currentStepId, groups, entityType]);

  const checkValue = useMemo(() => {
    const IndicativeQuoteObjectDefinitionId = 3565;
    const NewDisplayObjects = displayObjects.filter(
      (element) =>
        element.ObjectDefinition.Id !== IndicativeQuoteObjectDefinitionId
    );
    return value !== IndicativeQuoteObjectDefinitionId
      ? value
      : landingpage
      ? NewDisplayObjects[0].ObjectDefinition.Id
      : value;
  }, [value]);

  return {
    displayObjects,
    grouped,
    groups,
    handleValueChange,
    noredflag,
    redflagObjects,
    value: checkValue,
    SortGroups
  };
};
