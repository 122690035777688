import axios from 'axios';

import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { SelectedDesign } from '../types';

export const fetchDesign = async (
  id: string
): Promise<SelectedDesign | undefined> => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/designs/${id}`;

  try {
    const res = await axios.get(url, { headers: { token } });

    return res.data as SelectedDesign;
  } catch (e) {
    BugTracker.notify(e);
  }
};
