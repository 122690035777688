import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';

export const isPendingList = async (
  listId: string
): Promise<boolean | void> => {
  try {
    const db = firebase.firestore();

    const ref = db.collection('list').doc(listId).collection('PendingContacts');

    const snapshot = await ref.get();

    if (snapshot.empty) return false;
    else return true;
  } catch (e) {
    BugTracker.notify(e);
  }
};
