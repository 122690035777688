import { useState } from 'react';
import { ProcessSummary, AssignedUser } from 'types/interfaces';

import { Badge, Chip, Grid, Menu, Tooltip } from '@material-ui/core';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { theme } from 'theme';

import {
  AiFillCloseSquare,
  AiFillCheckSquare,
  AiFillPlusSquare
} from 'react-icons/ai';
import NumberFormat from 'react-number-format';

export const UserRender = ({
  v,
  t,
  type
}: {
  v: ProcessSummary;
  t: any;
  type:
    | 'Regulated Party'
    | 'Customers'
    | 'Contacts'
    | 'Lenders'
    | 'Suppliers'
    | string[];
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const AssignedUsers: AssignedUser[] = v.AssignedUserList.filter(
    (AssignedUser: AssignedUser) =>
      type.includes(AssignedUser.UserDefinitionTitle)
  );

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    if (AssignedUsers.length > 1) {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  if (!AssignedUsers || AssignedUsers.length < 1) return <div />;
  const RenderTypes = () => (
    <>
      {AssignedUsers.map(({ UserInstanceTitle, ProxyUserInstanceId }, idx) => (
        <div key={idx}>
          <Chip
            variant="default"
            deleteIcon={
              ProxyUserInstanceId > 0 ? (
                <PeopleAltIcon fontSize="small" />
              ) : (
                <></>
              )
            }
            label={UserInstanceTitle}
            key={idx}
            style={{ margin: 1 }}
          />
        </div>
      ))}
    </>
  );

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        id="target">
        <Badge
          badgeContent={AssignedUsers.length > 1 ? AssignedUsers.length : 0}
          color="primary">
          {AssignedUsers[0]?.UserInstanceTitle}
        </Badge>
        <Menu anchorEl={anchorEl} open={open} onClose={handleMouseLeave}>
          <div onMouseLeave={handleMouseLeave}>
            <RenderTypes />
          </div>
        </Menu>
      </div>
    </>
  );
};

interface IStatusStyles {
  [key: string]: {
    color: string;
    shape: string;
  };
}

const statusStyles: IStatusStyles = {
  '0': { color: theme.palette.error.main, shape: 'square' },
  '1': { color: theme.palette.warning.main, shape: 'triangle' },
  '2': { color: theme.palette.success.main, shape: 'star' },
  '-1': { color: theme.palette.grey[400], shape: 'disabled' }
};

const ShapeIcon = ({ shape, color }: { shape: string; color: string }) => {
  const fontSize = 21;
  switch (shape) {
    case 'star':
      return <AiFillCheckSquare style={{ fontSize, color }} />;
    case 'square':
      return <AiFillCloseSquare style={{ fontSize, color }} />;
    case 'triangle':
      return <AiFillPlusSquare style={{ fontSize, color }} />;
    case 'disabled':
      return <AiFillCloseSquare style={{ fontSize, color, opacity: 0.2 }} />;
    default:
      return null;
  }
};

export const CustomStatusRender = ({ value }) => {
  if (!value) return <div />;
  const { ProcessStepGroupList } = value;
  if (!ProcessStepGroupList) return <div />;
  return (
    <Grid container spacing={0}>
      {ProcessStepGroupList.map(
        (
          { ProcessStepGroupLowestMandatoryStatus, ProcessStepGroupTitle },
          idx
        ) => {
          const statusKey = String(ProcessStepGroupLowestMandatoryStatus);
          const { color, shape } = statusStyles[statusKey] || {};
          return (
            <Grid item key={idx}>
              <Tooltip title={ProcessStepGroupTitle}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <ShapeIcon shape={shape} color={color} />
                </div>
              </Tooltip>
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export const DealCustomRender = ({
  value,
  renderType
}: {
  value: ProcessSummary;
  renderType: 'row' | 'group';
}) => {
  if (renderType === 'row') {
    const { ProcessInstanceId } = value;
    return <p style={{ fontWeight: 800 }}>{ProcessInstanceId}</p>;
  }
  if (renderType === 'group') {
    return value;
  }
};

export const DealValueRender = ({ value }: { value: ProcessSummary }) => {
  if (!value?.Value) return <div />;

  return (
    <NumberFormat
      displayType={'text'}
      thousandSeparator={true}
      prefix={'£'}
      decimalScale={2}
      fixedDecimalScale
      value={value.Value}
    />
  );
};
