import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PercentageField, CurrencyField } from '../Utils/inputFields';
import MainField from '../components/MainField';
import { _NetAdvance, _Commission } from 'types/calculatorInterfaces';

import { useCalculator } from '../Calculator/hooks/useCalculator';
import { Tools } from '../Calculator/interfaces';

const Commission = (tools: Tools) => {
  const { state, config } = tools;
  const {
    isforAmount,
    handleChange,
    brokerCommissionAmount,
    setBrokerCommissionAmount
  } = useCalculator(tools);

  const [warning, setWarning] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [fullWidth] = React.useState(true);
  const commissionVis = config && config.CommissionAmount.show;
  const calculate = () => null;

  const shouldOpen = () => {
    return setOpen(true);
  };

  const isGrossAdvance = state._NetAdvance.Vat_Payment === 'ADD_VAT_TO_NET';
  //? Removed "DEFER_VAT" Because the want it calculated on NetAdvance Not GrossAdvance

  // If There Is a Commission Amount And The Net Advance is greater than 0 then give "Rate"
  useEffect(() => {
    if (brokerCommissionAmount === 'Amount') {
      let newState = { ...state };

      newState._Commission.Broker_Vat =
        (newState._Commission.Broker_Commission *
          newState._Commission.Broker_VatRate) /
        100;

      const Advance = isGrossAdvance
        ? newState.Amount
        : newState._NetAdvance.NetAdvance;

      newState._Commission.Broker_Rate =
        (newState._Commission.Broker_Commission / Advance) * 100;

      const myEventTargetObject = {
        target: {
          value: newState._Commission.Broker_Commission
        }
      };

      handleChange('Broker_Commission')(myEventTargetObject);
    }
  }, [state._NetAdvance.NetAdvance, isGrossAdvance]);

  // If There Is a Commission Rate and The Net Advance is greater than 0 then give "Amount"
  useEffect(() => {
    if (brokerCommissionAmount === 'Rate' || brokerCommissionAmount === 'N/A') {
      let newState = { ...state };

      newState._Commission.Broker_Vat =
        (newState._Commission.Broker_Commission *
          newState._Commission.Broker_VatRate) /
        100;

      const Advance = isGrossAdvance
        ? newState.Amount
        : newState._NetAdvance.NetAdvance;
      newState._Commission.Broker_Commission =
        (newState._Commission.Broker_Rate / 100) * Advance;

      const myEventTargetObject = {
        target: {
          value: newState._Commission.Broker_Rate
        }
      };

      handleChange('Broker_Rate', 'Rate')(myEventTargetObject);
    }
  }, [state._NetAdvance.NetAdvance, isGrossAdvance]);

  // If There Is a Commission Amount Change Then Update The Rate
  useEffect(() => {
    if (
      isGrossAdvance ? state.Amount >= 1 : state._NetAdvance.NetAdvance >= 1
    ) {
      let newState = { ...state };

      newState._Commission.Broker_Vat =
        (newState._Commission.Broker_Commission *
          newState._Commission.Broker_VatRate) /
        100;

      const Advance = isGrossAdvance
        ? newState.Amount
        : newState._NetAdvance.NetAdvance;

      newState._Commission.Broker_Rate =
        (newState._Commission.Broker_Commission / Advance) * 100;

      const myEventTargetObject = {
        target: {
          value: newState._Commission.Broker_Rate
        }
      };

      handleChange('Broker_Rate', 'Rate')(myEventTargetObject);
    }
  }, [state._Commission.Broker_Commission]);

  // If There Is a Rate Change Then Update The Commission Amount
  useEffect(() => {
    if (
      isGrossAdvance ? state.Amount >= 1 : state._NetAdvance.NetAdvance >= 1
    ) {
      let newState = { ...state };

      const Advance = isGrossAdvance
        ? newState.Amount
        : newState._NetAdvance.NetAdvance;

      newState._Commission.Broker_Commission =
        Advance * (newState._Commission.Broker_Rate / 100);

      const myEventTargetObject = {
        target: {
          value: newState._Commission.Broker_Commission
        }
      };

      handleChange('Broker_Commission')(myEventTargetObject);
    }
  }, [state._Commission.Broker_Rate]);

  useEffect(() => {
    const isLease = (product: string) =>
      product.toLowerCase().startsWith('lease');
    if (!isLease(state.FinanceProduct)) {
      const myEventTargetObject = {
        target: {
          value: 0
        }
      };

      handleChange('Broker_VatRate')(myEventTargetObject);
    }
  }, [state.FinanceProduct]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <MainField
          disabled={!commissionVis}
          name="Commission"
          onClick={shouldOpen}
          required={false}
          value={state._Commission.TotalPayable || state.CommissionAmount}
          valueToChange={state._Commission.Broker_Commission}
          handleChange={handleChange}
          type={'Broker_Commission'}
          calculate={calculate}
        />

        <Dialog fullWidth={fullWidth} maxWidth="md" open={open}>
          <DialogTitle>Commission</DialogTitle>
          <DialogContent>
            <Grid
              alignItems="flex-start"
              container
              direction="column"
              justifyContent="center">
              <Grid item style={{ width: '100%' }}>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={brokerCommissionAmount}
                    onChange={() =>
                      setBrokerComissionAmount(!brokerCommissionAmount)
                    }
                  />
                }
                label="Commission Type"
              /> */}

                <Grid container spacing={1}>
                  <Grid item sm={3} xs={12}>
                    <CurrencyField
                      InputLabelProps={undefined}
                      adornment={undefined}
                      calculate={calculate}
                      classes={undefined}
                      disabled={undefined}
                      error={undefined}
                      name="Broker Commission"
                      // onBlur={comissionBlur('Broker_Commission')}
                      onChange={handleChange('Broker_Commission')}
                      onClick={undefined}
                      onKeyUp={undefined}
                      required={undefined}
                      startAdornment={undefined}
                      value={state._Commission.Broker_Commission}
                      onBlur={() => setBrokerCommissionAmount('Amount')}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <PercentageField
                      InputLabelProps={undefined}
                      calculate={calculate}
                      classes={undefined}
                      // Requested By Shamila Wants Cost to Have Commission Rate
                      // disabled={isforAmount || !brokerCommissionAmount}
                      disabled={undefined}
                      name="Commission Rate %"
                      // onBlur={comissionBlur('Broker_Rate')}
                      onChange={handleChange('Broker_Rate', 'Rate')}
                      onClick={undefined}
                      onKeyUp={undefined}
                      required={undefined}
                      style={undefined}
                      type={undefined}
                      value={state._Commission.Broker_Rate}
                      onBlur={() => setBrokerCommissionAmount('Rate')}
                    />
                  </Grid>
                  {/* <div style={{ flexGrow: 1 }} /> */}
                  <Grid item sm={3} xs={12}>
                    <PercentageField
                      InputLabelProps={undefined}
                      calculate={calculate}
                      classes={undefined}
                      disabled={undefined}
                      name="VAT Rate %"
                      onBlur={undefined}
                      onChange={handleChange('Broker_VatRate')}
                      onClick={undefined}
                      onKeyUp={undefined}
                      required={undefined}
                      style={undefined}
                      type={undefined}
                      value={state._Commission.Broker_VatRate}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <CurrencyField
                      InputLabelProps={undefined}
                      adornment={undefined}
                      calculate={undefined}
                      classes={undefined}
                      disabled
                      error={undefined}
                      name="VAT"
                      onBlur={undefined}
                      onChange={undefined}
                      onClick={undefined}
                      onKeyUp={undefined}
                      required={undefined}
                      startAdornment={undefined}
                      value={state._Commission.Broker_Vat}
                    />
                  </Grid>
                </Grid>

                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        error={undefined}
                        name="Introducer Fee"
                        // onBlur={handleBlur(
                        //   state._Commission.Broker_Commission,
                        //   state._Commission.Introducer_Fee,
                        //   'Introducer_Fee',
                        //   // setState,
                        //   // state
                        // )}
                        onChange={handleChange('Introducer_Fee')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.Introducer_Fee}
                        onBlur={undefined}
                      />
                    </Grid>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item sm={4} xs={12}>
                      <PercentageField
                        InputLabelProps={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        name="VAT Rate %"
                        onBlur={undefined}
                        onChange={handleChange('Introducer_VatRate')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        style={undefined}
                        type={undefined}
                        value={state._Commission.Introducer_VatRate}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="VAT"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.Introducer_Vat}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        error={undefined}
                        name="Funder Fee"
                        onBlur={undefined}
                        onChange={handleChange('Funder_Fee')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.Funder_Fee}
                      />
                    </Grid>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item sm={4} xs={12}>
                      <PercentageField
                        InputLabelProps={undefined}
                        calculate={calculate}
                        classes={undefined}
                        disabled={undefined}
                        name="VAT Rate %"
                        onBlur={undefined}
                        onChange={handleChange('Funder_VatRate')}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        style={undefined}
                        type={undefined}
                        value={state._Commission.Funder_VatRate}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="VAT"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.Funder_Vat}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item style={{ width: '100%' }}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="Total Payable"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.TotalPayable}
                      />
                    </Grid>

                    <div style={{ flexGrow: 1 }} />

                    <Grid item sm={4} xs={12}>
                      <CurrencyField
                        InputLabelProps={undefined}
                        adornment={undefined}
                        calculate={undefined}
                        classes={undefined}
                        disabled
                        error={undefined}
                        name="Total Payable Inc. VAT"
                        onBlur={undefined}
                        onChange={undefined}
                        onClick={undefined}
                        onKeyUp={undefined}
                        required={undefined}
                        startAdornment={undefined}
                        value={state._Commission.TotalPayable_IncVat}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/*  <Typography>
            N.B. VAT on Commission is always included upfront regardless of VAT
            deferral choice
          </Typography>*/}
          </DialogContent>

          <DialogActions>
            {/* <Button
            color="primary"
            // onClick={() => setState(INIT_COMMISSION)}
          >
            Clear All
          </Button> */}
            <div style={{ flexGrow: 1 }} />
            {/* <Button color="primary" onClick={() => setOpen(false)}>
            Cancel
          </Button> */}
            <Button
              data-cy="comission-done"
              color="primary"
              onClick={() => setOpen(false)}
              variant="contained">
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default Commission;
