import { useState } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ClearIcon from '@material-ui/icons/Clear';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import UpsertSenderDialog from './UpsertSenderDialog';
import DeleteSenderDialog from './DeleteSenderDialog';
import { SenderDetails, ISender } from '../types';
import { useTypedSelector } from 'redux/reducers';
import Paginator from 'components/Marketing/components/Paginator';

const red = '#FF6347';
const green = '#66CDAA';

const useStyles = makeStyles((theme: Theme) => {
  const grey100 = theme.palette.grey[100];
  const grey300 = theme.palette.grey[300];
  const grey400 = theme.palette.grey[400];

  return createStyles({
    gridItem: {
      backgroundColor: grey100,
      padding: 15,
      borderRadius: 10,
      maxWidth: 400,
      border: `1px solid ${grey300}`,
      margin: 30
    },
    box: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      borderImage: `linear-gradient(to right, transparent, ${grey400}, transparent) 1`,
      marginBottom: theme.spacing(1)
    },
    itemHeading: {
      fontWeight: 500,
      color: '#68696b',
      padding: theme.spacing(0.4)
    }
  });
});

const nickname: keyof typeof SenderDetails = 'nickname';
const from_email: keyof typeof SenderDetails = 'from_email';
const reply_to: keyof typeof SenderDetails = 'reply_to';

interface Props {
  senders: ISender[];
}

const SendersGrid = ({ senders }: Props) => {
  const classes = useStyles();
  const user = useTypedSelector((s) => s.user.user);
  const isAdminOrSuperAdmin = user.SystemAccess >= 10;
  const [paginatedSenders, setPaginatedSenders] = useState<ISender[]>(senders);

  return (
    <>
      <Grid container justifyContent="center">
        {paginatedSenders.map((sender, i) => {
          const isAuthor = sender.userInstanceId === user.Id;
          const hasWritePermissions = isAuthor || isAdminOrSuperAdmin;

          return (
            <Grid
              item
              xs={4}
              className={classes.gridItem}
              key={sender.id}
              data-testid={sender.id}>
              {Object.keys(SenderDetails).map((key) => {
                const { hidden, title } = SenderDetails[key];
                const value = sender[key] || '--';

                if (key === nickname) {
                  return (
                    <Box
                      component="div"
                      className={classes.box}
                      key={`${sender.id}-${key}`}>
                      <Typography variant="h4" gutterBottom>
                        {sender.nickname}
                      </Typography>
                    </Box>
                  );
                } else if (key === from_email || key === reply_to) {
                  const domainIsValid = sender.verified;

                  return (
                    <Box
                      component="div"
                      className={classes.box}
                      key={`${sender.id}-${key}`}>
                      <Typography className={classes.itemHeading}>
                        {title}:
                      </Typography>
                      <div style={{ display: 'flex', gap: 5 }}>
                        <Typography>{value}</Typography>
                        {domainIsValid ? (
                          <VerifiedUserIcon style={{ color: green }} />
                        ) : (
                          <Tooltip title="If you have recently verified, try the refresh button in the Domain Authentication tab.">
                            <ClearIcon style={{ color: red }} />
                          </Tooltip>
                        )}
                      </div>
                    </Box>
                  );
                } else {
                  if (hidden) return null;

                  return (
                    <Box
                      component="div"
                      className={classes.box}
                      key={`${sender.id}-${key}`}>
                      <Typography className={classes.itemHeading}>
                        {title}:
                      </Typography>
                      <Typography>{value}</Typography>
                    </Box>
                  );
                }
              })}
              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                <UpsertSenderDialog
                  sender={sender}
                  hasWritePermissions={hasWritePermissions}
                />
                <DeleteSenderDialog
                  sender={sender}
                  hasWritePermissions={hasWritePermissions}
                />
              </div>
            </Grid>
          );
        })}
      </Grid>
      <Paginator allItems={senders} setPaginatedItems={setPaginatedSenders} />
    </>
  );
};

export default SendersGrid;
