import { ApolloError } from '@apollo/client';
import { store } from 'redux/store';
import { dispatchError } from '..';
import { createNotification } from 'react-redux-notify';
import { errorNotif, successNotif } from 'components/Notifications';
import { BugTracker } from 'Utils/Bugtracker';
import {
  AddDocumentsToProposal,
  CreateProposal,
  GenerateProposalDocument,
  UpdateProposalStatus
} from 'redux/database/Lender API/mutations';
import {
  IAddDocumentsResponse,
  ICosmeticsBadRequestResponse,
  ICosmeticsCreateProposalResponse,
  ICreateProposal,
  IDocumentAuth,
  IProposalList,
  IProposalStatusResponse,
  IUpdateProposalResponse,
  IUpdateProposalStatus,
  IUploadDocumentIds,
  TPossibleLenderAPITypes
} from 'redux/database/Lender API/interface';
import {
  GetFleximizeDocumentTypes,
  GetProposalList,
  GetProposalStatus
} from 'redux/database/Lender API/query';
import { ELender } from 'components/Stepper/components/LenderAPI/interface';
import firebase from 'firebase';
import { FbFileRef } from 'types/interfaces';

const { dispatch } = store;

/**
 * Retrieves a list of proposals for a given deal ID.
 *
 * @param {string} dealId - The unique identifier for the deal.
 * @returns {Promise<IProposalList[] | undefined>} - A promise that resolves to an array of proposal lists or undefined.
 */
export const getProposalList = async (
  dealId: string,
  refetch?: boolean
): Promise<IProposalList[] | undefined> => {
  try {
    const result = await GetProposalList(dealId, refetch);

    if (result instanceof ApolloError) {
      // dispatchError(result);
      return;
    }

    const files = await getDealFiles(dealId);
    const proposalListWithFiles = result.map((proposal) => ({
      ...proposal,
      files: files
    }));

    return proposalListWithFiles;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    dispatch(createNotification(errorNotif(errorMessage)));

    return;
  }
};

const getDealFiles = async (dealId: string): Promise<FbFileRef[]> => {
  const filesRef = firebase
    .firestore()
    .collection('deal')
    .doc(dealId)
    .collection('files');

  const filesSnapshot = await filesRef.get();
  if (!filesSnapshot.empty) {
    const files = filesSnapshot.docs.map((doc) => {
      const data = doc.data() as FbFileRef;
      return data;
    });

    return files;
  } else return [];
};

/**
 * Checks the status of a proposal for a given lender, proposal ID, and deal ID.
 *
 * @param {Object} params - The parameters object.
 * @param {ELender} params.lender - The lender identifier.
 * @param {string} params.proposalId - The unique identifier for the proposal.
 * @param {string} params.dealId - The unique identifier for the deal.
 * @returns {Promise<IProposalStatus | undefined>} - A promise that resolves to the proposal status or undefined.
 */
export const getProposalStatus = async ({
  lender,
  proposalId,
  dealId
}: {
  lender: ELender;
  proposalId: string;
  dealId: string;
}): Promise<IProposalStatusResponse | undefined> => {
  try {
    const result = await GetProposalStatus({
      lender,
      proposalId,
      dealId
    });

    if (result instanceof ApolloError) {
      dispatchError(result);
      return;
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    dispatch(createNotification(errorNotif(errorMessage)));

    return;
  }
};

/**
 * Creates a Fleximize proposal and checks its status.
 *
 * @param {ICreateProposal} input - The input data required to create a Fleximize proposal.
 * @returns {Promise<TPossibleLenderAPITypes | undefined>} - A promise that resolves to the proposal response or undefined.
 */

export const createProposal = async (
  input: ICreateProposal
): Promise<ICosmeticsCreateProposalResponse | string[] | undefined> => {
  try {
    const result = await CreateProposal(input);
    if (!result) throw new Error('No result returned from CreateProposal');

    console.log({ result });

    if (result instanceof ApolloError) {
      dispatchError(result);
      return;
    }

    const successResult = result as ICosmeticsCreateProposalResponse;
    if (
      successResult.message &&
      successResult.thirdPartyId &&
      successResult.meta
    ) {
      return {
        message: successResult.message,
        thirdPartyId: successResult.thirdPartyId,
        meta: successResult.meta
      };
    }

    const badResult = result as ICosmeticsBadRequestResponse;
    console.log({ badResult });
    const errors = Array.isArray(badResult.errors)
      ? badResult.errors
      : [badResult.errors];
    dispatchError(new ApolloError({ errorMessage: errors.join(', ') }));

    return errors;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    dispatch(createNotification(errorNotif(errorMessage)));

    return;
  }
};

/**
 * Adds documents to a Fleximize proposal.
 *
 * @param {IAddDocuments} input - The input data containing document IDs and related information.
 * @returns {Promise<{}>} - A promise that resolves to the result of adding documents.
 */
export const addDocumentsToProposal = async (
  input: IUploadDocumentIds,
  auth: IDocumentAuth,
  lender: ELender
): Promise<IAddDocumentsResponse[] | undefined> => {
  try {
    const result = await AddDocumentsToProposal(input, auth, lender);

    if (result instanceof ApolloError) {
      dispatchError(result);
      return;
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    dispatch(createNotification(errorNotif(errorMessage)));

    return;
  }
};

/**
 * Retrieves the list of document types allowed by a specific lender.
 *
 * @param {Object} params - The parameters object.
 * @param {ELender} params.lender - The lender identifier.
 * @returns {Promise<Array<{ id: string; type: string }> | undefined>} - A promise that resolves to the list of document types.
 */
export const getDocumentTypes = async ({
  lender
}: {
  lender: ELender;
}): Promise<Array<{ id: string; type: string }> | undefined> => {
  try {
    let result;
    switch (lender) {
      case ELender.Fleximize: {
        result = await GetFleximizeDocumentTypes();
        break;
      }
      default:
        throw new Error('Unsupported lender');
    }

    if (result instanceof ApolloError) {
      dispatchError(result);
      return;
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    dispatch(createNotification(errorNotif(errorMessage)));

    return;
  }
};

export const updateProposalStatus = async ({
  lender,
  input
}: {
  lender: ELender;
  input: IUpdateProposalStatus;
}): Promise<IUpdateProposalResponse | undefined> => {
  try {
    let result: IUpdateProposalResponse | ApolloError;
    switch (lender) {
      case ELender.Fleximize: {
        result = await UpdateProposalStatus(input);
        break;
      }
      default:
        throw new Error('Cannot Get Status');
    }

    if (result instanceof ApolloError) {
      dispatchError(result);
      return;
    }

    if (result?.success) {
      dispatch(createNotification(successNotif(result?.message)));
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    dispatch(createNotification(errorNotif(errorMessage)));

    return;
  }
};

export const generateProposalDocument = async (
  input: IUploadDocumentIds
): Promise<string | undefined> => {
  try {
    const result = await GenerateProposalDocument(input);

    if (result instanceof ApolloError) {
      dispatchError(result);
      return;
    }

    return result;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An Unexpected Error Occurred';
    dispatch(createNotification(errorNotif(errorMessage)));

    return;
  }
};
