import { useState } from 'react';
import {
  Asset,
  Calculation,
  CashFlowItem,
  PaymentFrequency
} from 'types/calculatorInterfaces';
import { INIT_COMMISSION, INIT_STATE } from '../../Utils/calculations';
import { Props, Tools, Extra, ForValue, FromValue } from '../interfaces';
import {
  onSubmit,
  handleBalloonPayment,
  funderDocCalculations
} from '../functions';
import { setForValueFromCalculationNo } from '../functions/setForValueFromCalculationNo';
import { solveFromSwitch } from '../functions/solveFromSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import PDF from '../components/PDF';
import { INIT_NETADVANCE } from '../../Utils/calculations';
import { Convert_ObjectInstance_To_Calculation } from 'Utils';
import { useTypedSelector } from 'redux/reducers';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { FieldInstance } from 'types/interfaces';

export const useCalculator = (tools: Tools) => {
  const {
    props,
    state,
    setState,
    config,
    setConfig,
    setBalloonExtras,
    extras,
    setZeroPayments,
    fromValue,
    setFromValue,
    forValue,
    setForValue,
    setRows,
    setExtras
  } = tools;
  const dispatch = useDispatch();
  const { ObjectInstance: CompleteObjectInstance } = props;

  const { currentProcess } = useTypedSelector((s) => s.process);
  const baseURL = useSelector<any>((s) => s.config.baseURL);
  const theme = useSelector<any>((s) => s.config.theme);
  const [original, setOriginal] = useState<Calculation>(props.calculation);
  const [calculated, setCalculated] = useState<boolean>(false);
  const [StartDate, setStartDate] = useState(new Date());
  const [balloonRep, setBalloonRep] = useState(0);
  const [Output, setOutput] = useState<Calculation>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [brokerCommissionAmount, setBrokerCommissionAmount] = useState<
    'Rate' | 'Amount' | 'N/A'
  >('N/A');

  const [snackBar, setSnackBar] = useState({
    open: false,
    variant: 'success',
    message: 'All is well'
  });

  const handleFromChange = (event) => {
    setFromValue(event.target.value);

    solveFromSwitch({
      setBalloonRep,
      state,
      setConfig,
      forValue,
      setState,
      fromValue: event.target.value,
      setFromValue,
      isRecalculate,
      isManual: true,
      dispatch
    });
  };

  const handleForChange = (e, v) => {
    if (v === ForValue.ForCommission) {
      setForValue(v);
      setFromValue(FromValue.FromYield);
      // Requested and Confirmed With Shamila When "forPayment" to "forCommission" it sets MonthlyPayment to 0
      state.MonthlyPayment = 0;
      state._Commission = INIT_COMMISSION;
    } else {
      setForValue(v);
    }

    solveFromSwitch({
      setBalloonRep,
      state,
      setConfig,
      forValue: v,
      setState,
      fromValue,
      setFromValue,
      isRecalculate,
      isManual: true,
      dispatch
    });
  };

  const isforAmount = [3, 2, 1, 0].indexOf(state.Calculation) > -1;
  const isContractPlus1 = state._Balloon && state._Balloon.ContractPeriod === 1;

  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
  const notASavedVersion = FieldInstanceList.find(
    (FieldInstance: FieldInstance) => FieldInstance.FieldValue !== ''
  );

  const isRecalculate = Boolean(props?.calculation?.Amount > 0);
  const clearNetAdvance = () => {
    const newState = JSON.parse(JSON.stringify(state));
    newState._NetAdvance = INIT_NETADVANCE;
    setState(newState);
  };

  const handleDateChange = (name: string, data: any) => {
    const newState = JSON.parse(JSON.stringify(state));

    if (name === 'StartDate') {
      newState.StartDate = data;
      setStartDate(data);
    }

    if (name === 'ManufacturerSubsidyDate') {
      newState._AdditionalInputs.ManufacturerSubsidyDate = data;
    }

    if (name === 'DealersSubsidyAmountDate') {
      newState._AdditionalInputs.DealersSubsidyAmountDate = data;
    }

    return setState(newState);
  };

  const sanitizeAssets = (assets: Asset): Asset => {
    let sanitizedAssets = { ...assets };
    Object.keys(sanitizedAssets).forEach((key) => {
      if (sanitizedAssets[key] === null) {
        switch (key) {
          case 'Price':
          case 'Make':
          case 'AssetCategory':
          case 'Equipments':
          case 'Vehicles':
          case 'Model':
            sanitizedAssets[key] = '';
            break;
          case 'TotalPrice':
          case 'Quantity':
          case 'Year':
          case 'Non_VATable_item':
          case 'ObjectInstanceId':
            sanitizedAssets[key] = 0;
            break;
          default:
            break;
        }
      }
    });
    return sanitizedAssets;
  };

  const sanitizeAssetsArray = (assetsArray: Asset[]): Asset[] => {
    return assetsArray.map((asset) => sanitizeAssets(asset));
  };

  const handleChange = (name: string, type?: string) => (event: any) => {
    const value = event.target['value'];
    const newState: Calculation = JSON.parse(JSON.stringify(state));
    newState._NetAdvance.isLoading = false;

    /** NUMBERS **/
    if (event?.target['value'] !== undefined) {
      if (name === 'Asset') {
        // Handle Purchase_Price
        newState._NetAdvance.Purchase_Price = value.value.price;
        // HandleVatRate
        newState._NetAdvance.Purchase_VatRate = 20;
        // Handles Vat
        newState._NetAdvance.Purchase_Vat = value.value.vat;

        // Handle NonVATableItems
        let NonVATableItemsCost_RealValue: number =
          value.value.non_VATable_item;
        newState._NetAdvance.NonVATableItemsCost =
          NonVATableItemsCost_RealValue;
        newState.NonVATableItemsCost = NonVATableItemsCost_RealValue;

        // Handle Deposit
        let Deposit_RealValue: number = value.value.deposit;
        let Deposit_VatRate: number =
          newState._NetAdvance.Deposit_VatRate / 100;
        newState._NetAdvance.Deposit_Vat = Deposit_RealValue * Deposit_VatRate;
        newState._NetAdvance.Deposit = Deposit_RealValue;
        newState.Deposit = Deposit_RealValue;

        newState._NetAdvance.isLoading = true;
        if (newState.Assets) {
          let newAsset = sanitizeAssetsArray(value.asset);
          newState.Assets = newAsset;
        }
      }

      if (name === 'Purchase_Price') {
        /** NET ADVANCE */
        let Purchase_VatRate: number =
          newState._NetAdvance.Purchase_VatRate / 100;
        newState._NetAdvance.Purchase_Vat = value * Purchase_VatRate;
        newState._NetAdvance.Purchase_Price = value;
      }

      if (name === 'Purchase_VatRate') {
        newState._NetAdvance.Purchase_Vat =
          newState._NetAdvance.Purchase_Price * (value / 100);
        newState._NetAdvance.Purchase_VatRate = value;
      }

      if (name === 'Settlement') {
        let Settlement_VatRate: number =
          newState._NetAdvance.Settlement_VatRate / 100;
        newState._NetAdvance.Settlement_Vat = value * Settlement_VatRate;
        newState._NetAdvance.Settlement = value;
      }
      if (name === 'Settlement_VatRate') {
        newState._NetAdvance.Settlement_Vat =
          newState._NetAdvance.Settlement * (value / 100);
        newState._NetAdvance.Settlement_VatRate = value;
      }

      if (name === 'NonVATableItemsCost') {
        newState._NetAdvance.NonVATableItemsCost = value;
        newState.NonVATableItemsCost = value;
      }

      if (name === 'Deposit') {
        let Deposit_VatRate: number =
          newState._NetAdvance.Deposit_VatRate / 100;
        newState._NetAdvance.Deposit_Vat = value * Deposit_VatRate;
        newState._NetAdvance.Deposit = value;
        newState.Deposit = value;
      }

      if (name === 'Deposit_VatRate') {
        newState._NetAdvance.Deposit_Vat =
          newState._NetAdvance.Deposit * (value / 100);
        newState._NetAdvance.Deposit_VatRate = value;
      }

      if (name === 'Part_Exchange') {
        let Part_Exchange_VatRate: number =
          newState._NetAdvance.Part_Exchange_VatRate / 100;
        newState._NetAdvance.Part_Exchange_Vat = value * Part_Exchange_VatRate;
        newState._NetAdvance.Part_Exchange = value;
      }
      if (name === 'Part_Exchange_VatRate') {
        newState._NetAdvance.Part_Exchange_Vat =
          newState._NetAdvance.Part_Exchange * (value / 100);
        newState._NetAdvance.Part_Exchange_VatRate = value;
      }

      if (name === 'Subsidy') {
        let Subsidy_VatRate: number =
          newState._NetAdvance.Subsidy_VatRate / 100;
        newState._NetAdvance.Subsidy_Vat = value * Subsidy_VatRate;
        newState._NetAdvance.Subsidy = value;
      }
      if (name === 'Subsidy_VatRate') {
        newState._NetAdvance.Subsidy_Vat =
          newState._NetAdvance.Subsidy * (value / 100);
        newState._NetAdvance.Subsidy_VatRate = value;
      }

      if (name === 'VatDeferral') {
        newState._NetAdvance.VatDeferral = value;
      }

      /** NET ADVANCE STRINGS */
      const string_fields_netAdvance = ['Vat_Payment'];
      if (string_fields_netAdvance.includes(name)) {
        newState._NetAdvance[name] = value;

        if (value === 'VAT_UPFRONT' || value === 'ADD_VAT_TO_NET') {
          newState._NetAdvance.VatDeferral = 0;
        }
      }

      /** STRINGS **/
      const string_fields = ['Counting', 'PaymentFrequency'];
      const stringToNumberFields = ['InitialRentals', 'Term', 'FundingProfile'];
      if (string_fields.includes(name)) {
        newState[name] = value;
      }

      if (stringToNumberFields.includes(name)) {
        newState[name] = parseFloat(value);
      }

      if (newState[name] !== undefined) {
        newState[name] = value;
      }

      /** BALLOON */
      if (name === 'Balloon') {
        newState._Balloon.Balloon_Vat =
          (value * newState._Balloon.Balloon_VatRate) / 100;
        newState._Balloon.Balloon = value;
      }

      if (name === 'Balloon_VatRate') {
        newState._Balloon.Balloon_Vat =
          newState._Balloon.Balloon * (value / 100);
        newState._Balloon.Balloon_VatRate = value;
      }

      if (name === 'ContractPeriod') {
        newState._Balloon.ContractPeriod = parseInt(value);
      }

      /** DOCUMENT FEES */
      switch (name) {
        case 'DocFee': {
          newState._DocumentFee.DocFee = value;
          newState._DocumentFee.DocFee_Vat =
            ((value + newState._DocumentFee.DocFeeUpsell) *
              newState._DocumentFee.DocFee_VatRate) /
            100;

          break;
        }
        case 'DocFeeUpsell': {
          newState._DocumentFee.DocFeeUpsell = value;
          newState._DocumentFee.DocFee_Vat =
            ((newState._DocumentFee.DocFee + value) *
              newState._DocumentFee.DocFee_VatRate) /
            100;

          break;
        }
        case 'DocFee_VatRate': {
          newState._DocumentFee.DocFee_VatRate = value;

          newState._DocumentFee.DocFee_Vat =
            (newState._DocumentFee.DocFee *
              newState._DocumentFee.DocFee_VatRate) /
            100;
          break;
        }
        case 'DocFee_PaymentDate': {
          newState._DocumentFee[name] = value;

          break;
        }
        case 'OptionFee': {
          newState._DocumentFee.OptionFee = value;
          newState._DocumentFee.OptionFee_Vat =
            (value * newState._DocumentFee.OptionFee_VatRate) / 100;
          break;
        }
        case 'OptionFee_VatRate': {
          newState._DocumentFee.OptionFee_VatRate = value;
          newState._DocumentFee.OptionFee_Vat =
            (newState._DocumentFee.OptionFee * value) / 100;
          break;
        }
        case 'AnnualServiceFee': {
          newState._DocumentFee.AnnualServiceFee = value;
          newState._DocumentFee.AnnualServiceFee_Vat =
            (value * newState._DocumentFee.AnnualServiceFee_VatRate) / 100;
          break;
        }
        case 'AnnualServiceFee_VatRate': {
          newState._DocumentFee.AnnualServiceFee_VatRate = value;
          newState._DocumentFee.AnnualServiceFee_Vat =
            (value * newState._DocumentFee.AnnualServiceFee) / 100;
          break;
        }
      }

      /** ADDITIONAL INPUTS */

      if (name === 'RefundOfSalesProceeds') {
        newState._AdditionalInputs.RefundOfSalesProceeds = value;
      }

      if (name === 'SecondaryPeriodRental') {
        newState._AdditionalInputs.SecondaryPeriodRental = value;
      }

      // if (name === 'CustomerType') {
      //   newState._AdditionalInputs[name] = value;
      // }

      if (name === 'BaseRate') {
        newState._AdditionalInputs[name] = value;
      }

      if (name === 'MinimumBaseRatePerAnnum') {
        newState._AdditionalInputs.MinimumBaseRatePerAnnum = value;
      }

      if (name === 'ManufacturerSubsidy') {
        newState._AdditionalInputs.ManufacturerSubsidy = value;
      }

      if (name === 'DealersSubsidyAmount') {
        newState._AdditionalInputs.DealersSubsidyAmount = value;
      }

      /** TOTALS */
      /** NETADVANCE */
      let NetAdvance: number =
        newState._NetAdvance.Purchase_Price +
        newState._NetAdvance.Settlement +
        newState._NetAdvance.NonVATableItemsCost -
        newState._NetAdvance.Deposit -
        newState._NetAdvance.Part_Exchange -
        newState._NetAdvance.Subsidy;
      newState._NetAdvance.NetAdvance = NetAdvance;

      let VatTotal: number =
        newState._NetAdvance.Purchase_Vat +
        newState._NetAdvance.Settlement_Vat +
        newState._NetAdvance.Deposit_Vat +
        newState._NetAdvance.Part_Exchange_Vat +
        newState._NetAdvance.Subsidy_Vat;

      newState._NetAdvance.VatTotal = VatTotal;

      let TotalDeposit: number =
        newState._NetAdvance.Deposit +
        newState._NetAdvance.Part_Exchange +
        newState._NetAdvance.Subsidy;

      newState._NetAdvance.TotalDeposit = TotalDeposit;

      /** COMMISSION */
      if (type !== 'Rate') {
        if (name === 'Broker_Commission') {
          newState._Commission.Broker_Commission = value;
        }
      } else {
        if (name === 'Broker_Rate') {
          newState._Commission.Broker_Rate = value;
        }
      }

      // if (name !== 'Broker_Commission') {
      //   newState._Commission.Broker_Commission =
      //     NetAdvance * (newState._Commission.Broker_Rate / 100);
      // }

      if (name === 'Broker_VatRate') {
        newState._Commission.Broker_VatRate = value;
        newState._Commission.Broker_Vat =
          newState._Commission.Broker_Commission * (value / 100);
      }

      if (name === 'Introducer_Fee') {
        newState._Commission.Introducer_Vat =
          (value * newState._Commission.Introducer_VatRate) / 100;
        newState._Commission.Introducer_Fee = value;
      }
      if (name === 'Introducer_VatRate') {
        newState._Commission.Introducer_Vat =
          newState._Commission.Introducer_Fee * (value / 100);
        newState._Commission.Introducer_VatRate = value;
      }

      if (name === 'Funder_Fee') {
        newState._Commission.Funder_Vat =
          (value * newState._Commission.Funder_VatRate) / 100;
        newState._Commission.Funder_Fee = value;
      }
      if (name === 'Funder_VatRate') {
        newState._Commission.Funder_Vat =
          newState._Commission.Funder_Fee * (value / 100);
        newState._Commission.Funder_VatRate = value;
      }

      const TotalPayable = newState._Commission.Broker_Commission;
      newState._Commission.TotalPayable = TotalPayable;

      const TotalPayable_IncVat =
        TotalPayable +
        newState._Commission.Broker_Vat +
        newState._Commission.Funder_Vat;
      newState._Commission.TotalPayable_IncVat = TotalPayable_IncVat;

      /** VAT / AMOUNT CALCULATION - Must be after Net Advance Calculation (order Matters...)*/
      if (isforAmount) state.Amount = 0;
      if (
        newState._NetAdvance.Vat_Payment === 'ADD_VAT_TO_NET' ||
        newState._NetAdvance.Vat_Payment === 'DEFER_VAT'
      ) {
        newState.Amount =
          newState._NetAdvance.NetAdvance + newState._NetAdvance.VatTotal;
        newState._Balloon.TotalBalloon =
          newState._Balloon.Balloon + newState._Balloon.Balloon_Vat;

        // newState._DocumentFee.DocFeeUpsell =
        //   newState._DocumentFee.DocFeeUpsell === ''
        //     ? 0
        //     : newState._DocumentFee.DocFeeUpsell;
        newState._DocumentFee.TotalOptionFee =
          newState._DocumentFee.OptionFee + newState._DocumentFee.OptionFee_Vat;
        newState._DocumentFee.TotalDocFee =
          newState._DocumentFee.DocFee +
          newState._DocumentFee.DocFeeUpsell +
          newState._DocumentFee.DocFee_Vat;
        newState.CommissionAmount = TotalPayable_IncVat;
      } else {
        newState.Amount = newState._NetAdvance.NetAdvance;
        newState._Balloon.TotalBalloon = newState._Balloon.Balloon;
        newState._DocumentFee.TotalDocFee =
          newState._DocumentFee.DocFee + newState._DocumentFee.DocFeeUpsell ||
          0;
        newState._DocumentFee.TotalOptionFee = newState._DocumentFee.OptionFee;

        newState.CommissionAmount = TotalPayable;
      }

      return setState(newState);
    }
  };

  const overridePaymentsWithExtras = (extraPayments, overridePayments) => {
    let updatedOverridePayments = [...overridePayments];

    extraPayments.forEach((extra) => {
      const index = updatedOverridePayments.findIndex(
        (override) =>
          override.StartPeriod === extra.StartPeriod &&
          override.EndPeriod === extra.EndPeriod
      );

      if (index !== -1 && extra.Amount > 0) {
        updatedOverridePayments[index] = { ...extra, ExPayOverRide: '1' };
      }
    });

    return updatedOverridePayments;
  };

  const splitDeferralPayments = (payments: Extra[]) => {
    const result: Extra[] = [];

    payments.forEach((payment) => {
      if (payment.isDeferral) {
        const startPeriod = payment.StartPeriod;
        const endPeriod = payment.EndPeriod;

        const parentPaymentIndex = payments.findIndex(
          (p) =>
            p.StartPeriod <= startPeriod &&
            p.EndPeriod >= endPeriod &&
            !p.isDeferral
        );
        const parentPayment = payments[parentPaymentIndex];
        const parentEndPeriod = parentPayment
          ? parentPayment.EndPeriod
          : endPeriod;

        if (parentPayment) {
          const deferralPart = {
            ...payment,
            StartPeriod: startPeriod,
            EndPeriod: startPeriod,
            isDeferral: true
          };
          result.push(deferralPart);

          if (parentEndPeriod > startPeriod) {
            const secondPart = {
              ...parentPayment,
              StartPeriod: startPeriod + 1,
              EndPeriod: parentEndPeriod,
              isDeferral: false
            };
            result.push(secondPart);
          }

          if (startPeriod > parentPayment.StartPeriod) {
            const firstPart = {
              ...parentPayment,
              EndPeriod: startPeriod - 1,
              isDeferral: false
            };
            result.push(firstPart);
          }

          payments.splice(parentPaymentIndex, 1);
        }
      } else {
        result.push(payment);
      }
    });

    return result;
  };

  const checkForMissingExtras = (payments: Extra[], extras: Extra[]) => {
    const hasDeferralAtStart = payments.find(
      (payment: Extra) => payment.isDeferral && payment.StartPeriod === 1
    );

    if (hasDeferralAtStart) {
      extras.forEach((extra: Extra) => {
        const isMissing = !payments.some(
          (payment: Extra) =>
            payment.StartPeriod <= extra.StartPeriod &&
            payment.EndPeriod >= extra.EndPeriod &&
            payment.ExPayOverRide === extra.ExPayOverRide
        );

        if (isMissing) {
          console.log('Something Crazy', isMissing);
          payments.push(extra);
        }
      });
    }
  };

  const submitForm = async () => {
    const { zeroPayments } = await customPaymentStructure();
    const { ExtraPayments, OverridePayments } =
      convertExtrasToStateObject(zeroPayments);

    console.log({ OverridePayments });
    const updatedOverridePayments = overridePaymentsWithExtras(
      ExtraPayments,
      OverridePayments
    );

    console.log({ updatedOverridePayments });

    const hasDeferral: Extra | undefined = updatedOverridePayments.find(
      (payment: Extra) => payment?.isDeferral
    );

    console.log({ hasDeferral });
    let splitDeferral;
    if (hasDeferral) {
      checkForMissingExtras(updatedOverridePayments, extras);
      splitDeferral = splitDeferralPayments(updatedOverridePayments);
    }

    state.OverridePayments = hasDeferral
      ? splitDeferral
      : updatedOverridePayments;
    state.ExtraPayments = ExtraPayments;

    let isLoanDeal = false;
    if (Object.keys(currentProcess).length !== 0) {
      const Title = currentProcess?.ProcessDefinition?.Title;
      isLoanDeal = Title.includes('Loan');
    }
    onSubmit({
      isRecalculate,
      balloonRep,
      state,
      StartDate,
      forValue,
      fromValue,
      setSnackBar,
      setLoading,
      setErrorMessage,
      setOutput,
      setCalculated,
      setState,
      props,
      freq: state.PaymentFrequency,
      baseURL,
      isLoanDeal
    });
  };

  const customPaymentStructure = async () => {
    let zeroPayments: Extra[] = [];
    let balloonExtras: Extra[] = [];
    let vatExtras: Extra[] = [];

    // 1. Handle Payment Frequency
    zeroPayments = handlePaymentFrequency(state.PaymentFrequency);

    // 1.5. Handle Balloon Payment and Contract Period
    if (forValue !== ForValue.ForBalloon)
      zeroPayments = handleBalloonPayment(state, zeroPayments);

    console.log({ zeroPayments }, '1.5.');
    // 1.2 Handle Terminal Pause
    zeroPayments = handleTerminalPause(state, zeroPayments);
    console.log({ zeroPayments }, '1.2.');

    // 3. Handle Vat Deferrals
    vatExtras = await handleVatDeferrals(state, vatExtras);

    // 4. Handle Balloon Repayment Values
    if (forValue === ForValue.ForBalloon) {
      balloonExtras = handleBalloonRepayments(
        state,
        balloonRep,
        setBalloonExtras,
        balloonExtras
      );
    }

    ({ zeroPayments, balloonExtras } = await validateExtras(
      extras,
      vatExtras,
      zeroPayments,
      balloonExtras
    ));

    setZeroPayments(zeroPayments);
    setBalloonExtras(balloonExtras);

    return { balloonExtras, zeroPayments };
  };

  const convertExtrasToStateObject = (array: Extra[]) => {
    const ExtraPayments = array.filter(
      (obj: Extra) => obj.ExPayOverRide === '1'
    );
    const OverridePayments = array.filter(
      (obj: Extra) => obj.ExPayOverRide === '2'
    );
    return { ExtraPayments, OverridePayments };
  };

  const handlePaymentFrequency = (val: PaymentFrequency) => {
    let i: number;
    let extrasArray: Extra[] = [];
    const blank = (i: number): Extra => ({
      StartPeriod: i,
      EndPeriod: i,
      Amount: 0,
      ExPayOverRide: '2'
    });

    if (val === PaymentFrequency.Annually) {
      for (i = 1; i <= state.Term; i++) {
        if (i % 12 !== 0) {
          extrasArray.push(blank(i));
        }
      }
    }
    if (val === PaymentFrequency.SemiAnnually) {
      for (i = 1; i <= state.Term; i++) {
        if (i % 6 !== 0) {
          extrasArray.push(blank(i));
        }
      }
    }
    if (val === PaymentFrequency.Quarterly) {
      for (i = 1; i <= state.Term; i++) {
        if (i % 3 !== 0) {
          extrasArray.push(blank(i));
        }
      }
    }

    return extrasArray;
  };

  /**
   * Issue: When there's a balloon payment at the end (Term), the function was still
   * creating an additional zero payment override at the same period.
   *
   * Fix: Check if balloon payment exists (Amount > 0) at Term period before creating
   * zero payment overrides. Only create override at Term if no balloon exists.
   *
   * - Only prevents duplicate override at the Term period when balloon exists
   */
  const handleTerminalPause = (state: Calculation, zeroPayments: Extra[]) => {
    const { Term, FundingProfile } = state;
    const typedTerm = parseInt(Term.toString());

    const blank = (i: number): Extra => ({
      StartPeriod: i,
      EndPeriod: i,
      Amount: 0,
      ExPayOverRide: '2'
    });

    const hasBalloon = zeroPayments.some(
      (payment) => payment.Amount > 0 && payment.StartPeriod === typedTerm
    );

    switch (state.PaymentFrequency) {
      case PaymentFrequency.Annually: {
        const totalUnits = Term / 12;
        const TerminalPause = totalUnits - FundingProfile - 1;
        for (let i = 1; i <= TerminalPause; i++) {
          if (i === 1 && !hasBalloon) {
            zeroPayments.push(blank(typedTerm));
          }
          const unitInMonths = i * 12;
          zeroPayments.push(blank(typedTerm - unitInMonths));
        }
        break;
      }
      case PaymentFrequency.SemiAnnually: {
        const totalUnits = Term / 6;
        const TerminalPause = totalUnits - FundingProfile - 1;
        for (let i = 1; i <= TerminalPause; i++) {
          if (i === 1 && !hasBalloon) {
            zeroPayments.push(blank(typedTerm));
          }
          const unitInMonths = i * 6;
          zeroPayments.push(blank(typedTerm - unitInMonths));
        }
        break;
      }

      case PaymentFrequency.Quarterly: {
        const totalUnits = Term / 3;
        const TerminalPause = totalUnits - FundingProfile - 1;
        for (let i = 1; i <= TerminalPause; i++) {
          if (i === 1 && !hasBalloon) {
            zeroPayments.push(blank(typedTerm));
          }
          const unitInMonths = i * 3;
          zeroPayments.push(blank(typedTerm - unitInMonths));
        }
        break;
      }

      case PaymentFrequency.Monthly: {
        const TerminalPause = Term - FundingProfile - 1;
        if (TerminalPause === 0 && !hasBalloon) {
          zeroPayments.push(blank(typedTerm));
        } else {
          for (let i = 1; i <= TerminalPause; i++) {
            if (i === 1 && !hasBalloon) {
              zeroPayments.push(blank(typedTerm));
            }
            zeroPayments.push(blank(typedTerm - i));
          }
        }
        break;
      }
    }

    return zeroPayments;
  };

  const handleBalloonRepayments = (
    state: Calculation,
    balloonRep,
    setBalloonExtras,
    balloonExtras: Extra[]
  ) => {
    if (!balloonRep) {
      setBalloonExtras([]);
      return [];
    }

    // handle the Balloon Repayments
    if (balloonRep !== '' || balloonRep !== undefined) {
      // setBalloonExtras([]); // Do we want to reset the extras array?? not sure this is good
      let i: number;
      let balloonExtras: Extra[] = [];
      // In the case of a 36 month payment or a 37 month payment leave them as they are balloon payments...
      let len = state.Term - 1;
      let ExPayOverRide = '2';
      if (state._Balloon && state._Balloon.ContractPeriod === 1) {
        len = state.Term;
        ExPayOverRide = '2';
      }

      let blank = (i: number): Extra => ({
        StartPeriod: i,
        EndPeriod: i,
        Amount: balloonRep,
        ExPayOverRide
      });

      balloonExtras.push(blank(0));

      // create an override payment each month except the last.
      if (state.PaymentFrequency === PaymentFrequency.Monthly) {
        for (i = 1; i <= len; i++) balloonExtras.push(blank(i));
      }

      // If quarterly is selected create an override payment every quarter except the last.
      if (state.PaymentFrequency === PaymentFrequency.Quarterly) {
        for (i = 1; i <= len; i++) i % 3 === 0 && balloonExtras.push(blank(i));
      }

      // If semi Annually is selected create an override every 6 months except the last.
      if (state.PaymentFrequency === PaymentFrequency.SemiAnnually) {
        for (i = 1; i <= len; i++) i % 6 === 0 && balloonExtras.push(blank(i));
      }

      // If Annually is selected create an override every year except last payment.
      if (state.PaymentFrequency === PaymentFrequency.Annually) {
        for (i = 1; i <= len; i++) i % 12 === 0 && balloonExtras.push(blank(i));
      }

      console.log({ balloonExtras });
      return balloonExtras;
    } else {
      return balloonExtras;
    }
  };

  const validateExtras = async (
    extras: Extra[],
    vatExtras: Extra[],
    zeroPayments: Extra[],
    balloonExtras: Extra[]
  ) => {
    // console.log('validateExtras: START', {
    //   extras,
    //   vatExtras,
    //   zeroPayments,
    //   balloonExtras
    // });

    // Whenever it's
    // Does the VAT deferral collide with any other payments
    if (zeroPayments && zeroPayments.length > 0 && vatExtras.length > 0) {
      zeroPayments.forEach((e, i) => {
        // For each Zero payment check if Vat deferral matches and update if so
        if (e.StartPeriod === vatExtras[0].StartPeriod) {
          zeroPayments[i].ExPayOverRide = '1';
          zeroPayments[i].Amount = vatExtras[0].Amount;
        }
      });

      // Otherwise push the vat payment into the zeroPayments array
      const matches = zeroPayments.find(
        (e) => e.StartPeriod === vatExtras[0].StartPeriod
      );
      if (!matches) zeroPayments.push(vatExtras[0]);
    }

    // If there are no zero payments but there are vat extras then make zeroPayments equal vat extras
    if (zeroPayments.length === 0 && vatExtras.length > 0)
      zeroPayments = vatExtras;

    // We need a scenario for BalloonExtras
    if (zeroPayments.length > 0 && balloonExtras.length > 0) {
      // we need to make a let zeroPayments be a master with all payments shedule in.
      balloonExtras.forEach((e) => {
        zeroPayments.forEach((el, i) => {
          if (e.StartPeriod === el.StartPeriod) {
            const typedPayment =
              parseInt(zeroPayments[i].Amount.toString()) +
              parseInt(e.Amount.toString());

            zeroPayments[i].ExPayOverRide = e.ExPayOverRide;
            zeroPayments[i].Amount = typedPayment;
          }
        });

        const matches = zeroPayments.find(
          (el) => el.StartPeriod === e.StartPeriod
        );
        if (!matches) zeroPayments.push(e);
      });
    }
    if (zeroPayments && zeroPayments.length === 0 && balloonExtras.length > 0) {
      zeroPayments = balloonExtras;
    }

    // if there are Zero Payments
    if (zeroPayments.length > 0 && extras.length > 0) {
      // Initial loop to merge and sum the payments
      extras.forEach((e) => {
        // For each extra payment, loop over ZeroPayments
        zeroPayments.forEach((el, i) => {
          // If the zeroPayment falls between the selected range...
          const startsAt = e.StartPeriod <= el.StartPeriod;
          const endsAt = e.EndPeriod >= el.EndPeriod;
          if (startsAt && endsAt) {
            const typedPayment =
              parseInt(zeroPayments[i].Amount.toString()) +
              parseInt(e.Amount.toString());

            if (el.isDeferral) {
              zeroPayments[i].ExPayOverRide = e.ExPayOverRide;
              zeroPayments[i].Amount = typedPayment;
            } else {
              const payment = e.ExPayOverRide === '2' ? e.Amount : typedPayment;
              zeroPayments[i].ExPayOverRide = e.ExPayOverRide;
              zeroPayments[i].Amount = payment;
            }
          }
        });

        // Check if the extra should be added to zeroPayments
        const matches = zeroPayments.find(
          (el) => el.StartPeriod === e.StartPeriod
        );

        if (!matches) zeroPayments.push(e);
      });

      // Remove any zeroPayments that Extras already contain.
      zeroPayments = zeroPayments.filter((zp) =>
        extras.every((e) => e.isMonth || zp.StartPeriod !== e.EndPeriod)
      );
    }

    if (zeroPayments.length === 0 && extras.length > 0) {
      zeroPayments = extras;
    }

    console.log({ balloonExtras });

    return {
      extras,
      vatExtras,
      zeroPayments,
      balloonExtras
    };
  };

  const handleVatDeferrals = (state: Calculation, vatExtras) => {
    if (state._NetAdvance && state._NetAdvance.Vat_Payment === 'DEFER_VAT') {
      let { VatDeferral, VatTotal } = state._NetAdvance;
      let extrasArray = [...vatExtras];
      let VatPayment = {
        StartPeriod: VatDeferral,
        EndPeriod: VatDeferral,
        Amount: VatTotal,
        ExPayOverRide: '1',
        isDeferral: true
      };
      extrasArray.push(VatPayment);
      return extrasArray;
    }
    return vatExtras;
  };

  let firstPaymentDate = (StartDate, freq) => {
    let firstPay: Date | number = new Date(StartDate);
    switch (freq) {
      case PaymentFrequency.Monthly:
        firstPay = firstPay.setMonth(firstPay.getMonth() + 1);
        break;
      case PaymentFrequency.Quarterly:
        firstPay = firstPay.setMonth(firstPay.getMonth() + 3);
        break;
      case PaymentFrequency.SemiAnnually:
        firstPay = firstPay.setMonth(firstPay.getMonth() + 6);
        break;
      case PaymentFrequency.Annually:
        firstPay = firstPay.setMonth(firstPay.getMonth() + 12);
        break;
      default:
        firstPay = firstPay.setMonth(firstPay.getMonth() + 1);
    }
    return firstPay;
  };

  const buildPagePDF = async () => {
    if (Output) {
      const FUNDER_DOC = funderDocCalculations(Output);
      const blob = await pdf(<PDF state={FUNDER_DOC} theme={theme} />).toBlob();
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    }
  };

  const clearAll = () => {
    setState(INIT_STATE);
    setOutput(INIT_STATE);
    setRows([]);
    setExtras([]);

    setCalculated(false);
  };

  const resetToSaved = (props: Props) => {
    const CompleteObjectInstance = props.ObjectInstance;
    const CompleteObjectDefinition = {
      ObjectDefinition: props.ObjectDefinition,
      FieldDefinitionDict: props.FieldDefinitionDict
    };
    const savedCalc = Convert_ObjectInstance_To_Calculation(
      CompleteObjectInstance,
      CompleteObjectDefinition
    );
    const { newforValue, newfromValue } = setForValueFromCalculationNo(
      savedCalc.Calculation
    );
    setForValue(newforValue);
    setFromValue(newfromValue);
    setCalculated(true);
    setState(savedCalc);
    return setOutput(savedCalc);
  };

  const reInitCalculatorState = (calculation: Calculation) => {
    if (!isRecalculate) {
      const { newforValue, newfromValue } = setForValueFromCalculationNo(
        calculation.Calculation
      );

      if (newforValue === ForValue.ForBalloon) {
        const { CashFlow } = calculation;
        if (CashFlow.length > 0) {
          const entry = calculation.CashFlow.find(
            (i: CashFlowItem) => i.Description === 'Repay' && i.Receipt > 0
          );
          if (entry) {
            // console.log({ entry });
            setBalloonRep(entry.Receipt);
          }
        }
      }

      return { calculation, forValue: newforValue, fromValue: newfromValue };

      // setFreq(calculation.PaymentFrequency); //WIP - Set date too?
      // setOriginal(calculation);
      // setForValue(newforValue);
      // setFromValue(newfromValue);
      // setOutput(convertDbCalcToStateCalc(calculation));
      // setCalculated(true);
      // return setState(calculation); // Maybe in stead of set Sate we want to run a kind of handle click when state is updated
    } else if (calculation.CashFlow.length >= 1) {
      const { newforValue, newfromValue } = setForValueFromCalculationNo(
        calculation.Calculation
      );

      return { calculation, forValue: newforValue, fromValue: newfromValue };
    }
    return { calculation, forValue, fromValue };
    // else {
    // setOriginal(calculation);
    // setState(calculation);
    // setCalculated(false);
    // }
  };

  // useEffect(() => {
  //   // TODO : MAYBE WE MOVE THIS TO AN ONCLICK ON THE FROM AND FOR FIELDS
  //   // THEN WE CAN PUT IN THE RE_INIT-CALC_STATE FUNCTION
  //   solveFromSwitch({
  //     state,
  //     setConfig,
  //     forValue,
  //     setState,
  //     fromValue,
  //     setFromValue,
  //     isRecalculate,
  //     isManual: false
  //   });
  // }, [fromValue, forValue]); // SWITCH UI LOGIC

  // useEffect(() => {
  //   setPeriod(period);
  // }, [state.Term]);

  // useEffect(() => {
  //   // console.log('calculation has changed', props.calculation);
  //   const { calculation, forValue, fromValue } = reInitCalculatorState(
  //     props.calculation
  //   );
  //   console.log({ calculation });
  //   // isRecalculate && resetToSaved();
  // }, [props.calculation]);

  // useEffect(() => {
  //   const { calculation } = reInitCalculatorState(
  //     isRecalculate ? props.calculation : INIT_STATE
  //   );
  //   console.log('REINIT_CALCULATOR_STATE', { calculation });
  //   setState(calculation);
  //   return () => {
  //     reInitCalculatorState(INIT_STATE);
  //   };
  // }, []);

  const initLayout = async () => {
    const { calculation, forValue, fromValue } = await reInitCalculatorState(
      isRecalculate ? props.calculation : INIT_STATE
    );
    //isRecalculate && (await resetToSaved(props));
    solveFromSwitch({
      setBalloonRep,
      state,
      setConfig,
      forValue,
      setState,
      fromValue,
      setFromValue,
      isRecalculate,
      isManual: false,
      dispatch
    });

    isRecalculate && setCalculated(true);
    setOutput(calculation);
    return { calculation, forValue, fromValue };
  };

  return {
    balloonRep,
    brokerCommissionAmount,
    buildPagePDF,
    calculated,
    setCalculated,
    clearAll,
    clearNetAdvance,
    config,
    customPaymentStructure,
    errorMessage,
    firstPaymentDate,
    forValue,
    fromValue,
    handleChange,
    handleDateChange,
    handleForChange,
    handleFromChange,
    initLayout,
    isContractPlus1,
    isforAmount,
    isRecalculate,
    loading,
    notASavedVersion,
    original,
    Output,
    resetToSaved,
    setBalloonRep,
    setBrokerCommissionAmount,
    setForValue,
    setForValueFromCalculationNo,
    setFromValue,
    setLoading,
    setOutput,
    setSnackBar,
    setState,
    snackBar,
    StartDate,
    state,
    submitForm
  };
};
