import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  useTheme
} from '@material-ui/core';
import { Delete, Lock, Warning, CloudDownload } from '@material-ui/icons';
import QuickUserFromId from 'components/User/QuickUserFromId';
import {
  ELenderStatus,
  IProposal,
  IProposalObjects,
  ELenderDecision,
  IProposalAttachedFiles
} from '../interface';
import { useProposalHeaderStyles } from './styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  FbFileRef,
  FieldInstance,
  CompleteObjectInstance
} from 'types/interfaces';
import { useTypedSelector } from 'redux/reducers';
import {
  IDocumentAuth,
  IUpdateProposalStatus,
  IUploadDocumentIds
} from 'redux/database/Lender API/interface';
import {
  addDocumentsToProposal,
  generateProposalDocument,
  updateProposalStatus
} from 'redux/actions/GraphQlActions/LenderAPIActions';
import firebase from 'firebase';
import { isFileExplorer, isFileStorage } from '../helper';
import ProposalSubmissionProgress from './ProposalSubmissionProgress';
import useProgressStatus from '../hooks/useProgressStatus';
import ProposalFileDisplay from './ProposalFileDisplay';
import { useDispatch } from 'react-redux';
import { createNotification } from 'react-redux-notify';
import { warningNotif } from 'components/Notifications';
import ProposalErrors from './ProposalErrors';

interface IProposalWithDisable extends IProposal {
  disabled: boolean;
}

interface IProposalHeader {
  proposal: IProposalWithDisable;
  attachedFiles: IProposalAttachedFiles[];
  setAttachedFiles: Dispatch<SetStateAction<IProposalAttachedFiles[]>>;
  ObjectInstanceList: IProposalObjects;
  onSubmit: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  onDelete: (proposalId: string) => void;
  updateProposal: (proposal: IProposal) => void;
  handleStatusChange: () => Promise<void>;
  handleFileUpload: ({
    url,
    item
  }: {
    url: string;
    item: any;
  }) => Promise<void>;
  handleFileRemove: (key: string) => void;
  setStatus: Dispatch<SetStateAction<ELenderStatus>>;
  status: ELenderStatus;
  setFiles: Dispatch<SetStateAction<FbFileRef[]>>;
  files: FbFileRef[];
  validationErrors: string[];
}

// TODO Refactor the Header
const ProposalHeader = ({
  proposal,
  attachedFiles,
  setAttachedFiles,
  ObjectInstanceList,
  onSubmit,
  onDelete,
  updateProposal,
  handleStatusChange,
  handleFileUpload,
  handleFileRemove,
  setStatus,
  status,
  setFiles,
  files,
  validationErrors
}: IProposalHeader) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useProposalHeaderStyles();

  const { currentDeal, currentStepId } = useTypedSelector((s) => s.process);
  const { baseURL } = useTypedSelector((s) => s.config);
  const { token } = useTypedSelector((s) => s.user.auth);
  const { processInstanceFields } = useTypedSelector((s) => s.process);
  const { user } = useTypedSelector((s) => s.user);

  const [uploadError, setUploadError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [resolvedErrors, setResolvedErrors] = useState<Set<number>>(new Set());
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const {
    showProgress,
    setShowProgress,
    isCompleted,
    statusMessage,
    resetProgress,
    updateProgress
  } = useProgressStatus();

  const isProposalCreated = status !== ELenderStatus.DRAFT;
  const handleButtonClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setLoading(true);

    try {
      if (isProposalCreated) {
        await handleStatusChange();
      } else {
        await onSubmit();
      }
    } catch (error) {
      console.error('Error in handleButtonClick:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadFiles = async () => {
    setShowProgress(true);
    updateProgress('Preparing file upload...', false, true);

    const fileExplorerDocumentPaths: string[] = [];
    const fileStorageDocumentIds: string[] = [];

    updateProgress('Processing attached files...', false, true);
    attachedFiles.forEach((file) => {
      if (isFileExplorer(file.type)) {
        const fileUrl = file.url;
        if (fileUrl) {
          fileExplorerDocumentPaths.push(fileUrl);
        } else {
          updateProgress(
            'Error: Unable to process FileExplorer URL',
            false,
            true
          );
        }
      } else if (isFileStorage(file.type)) {
        const fileId = file.type.fileId || '';
        fileStorageDocumentIds.push(fileId);
      } else {
        updateProgress('Error: Unknown file type encountered', false, true);
      }
    });

    updateProgress('Submitting proposal information...', false, true);
    const input: IUploadDocumentIds = {
      dealId: currentDeal.ProcessInstance.Id,
      fileExplorerDocumentPaths,
      fileStorageDocumentIds,
      baseURL,
      generatedProposalFile: null,
      proposalId: proposal.id.toString(),
      userInstanceId: user.Id,
      userInstanceEmail: user.UserInstanceEmail
    };

    updateProgress('Creating authentication token...', false, true);
    const firebaseToken = await firebase.auth().currentUser?.getIdToken();

    const auth: IDocumentAuth = {
      CosneticsToken: token,
      FirebaseToken: firebaseToken || ''
    };

    updateProgress('Creating file upload request...', false, true);
    try {
      const response = await addDocumentsToProposal(
        input,
        auth,
        proposal.lenderTitle
      );

      if (response && response[0]?.success) {
        updateProgress('File upload completed successfully!', true, true);
        let importedFiles: FbFileRef[] = files;
        await Promise.all(
          attachedFiles.map(async (element: IProposalAttachedFiles) => {
            importedFiles.push(element.type as FbFileRef);
          })
        );

        setFiles(importedFiles);
        setAttachedFiles([]);
      } else {
        const errorMessage =
          response?.[0]?.reason || 'Failed to upload files to proposal';
        setUploadError(errorMessage);

        updateProgress(
          'Error: Failed to upload files to proposal. Please try again.',
          true,
          true
        );
      }
    } catch (error) {
      if (error && typeof error === 'object' && 'reason' in error) {
        setUploadError((error as { reason: string }).reason);
      } else {
        setUploadError('An unexpected error occurred while uploading files');
      }

      updateProgress(
        'Error: Failed to upload files to proposal. Please try again.',
        true,
        true
      );
    }
  };

  useEffect(() => {
    setResolvedErrors(new Set());
  }, [validationErrors]);

  const handleErrorToggle = (index: number) => {
    setResolvedErrors((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const renderStatusContent = () => {
    return (
      <Box className={classes.statusContainer}>
        <Select
          value={status}
          onChange={async (e) => {
            const status = e.target.value as ELenderStatus;
            const input: IUpdateProposalStatus = {
              dealId: currentDeal.ProcessInstance.Id.toString(),
              status: status,
              decision: proposal.decision,
              proposalId: proposal.id
            };

            const overrideStatus = await updateProposalStatus({
              lender: proposal.lenderTitle,
              input
            });

            if (overrideStatus?.success) {
              const newProposal: IProposal = {
                ...proposal,
                requestedStatus: status
              };

              setStatus(status);
              updateProposal(newProposal);
            }
          }}
          className={classes.statusSelect}
          variant="outlined"
          margin="dense"
          renderValue={(selected) => (
            <Box className={classes.statusValue}>
              <Typography variant="body2" className={classes.selectLabel}>
                Status:
              </Typography>
              <span
                className={`${classes.statusBall} ${
                  classes[`status${selected}`]
                }`}
              />
              {selected}
            </Box>
          )}>
          {Object.values(ELenderStatus).map((status) => (
            <MenuItem key={status} value={status}>
              <span
                className={`${classes.statusBall} ${
                  classes[`status${status}`]
                }`}
              />
              {status}
            </MenuItem>
          ))}
        </Select>

        <Select
          margin="dense"
          value={proposal.decision}
          onChange={async (e) => {
            const decision = e.target.value as ELenderDecision;
            const input: IUpdateProposalStatus = {
              dealId: currentDeal.ProcessInstance.Id.toString(),
              status: proposal.requestedStatus,
              decision: decision,
              proposalId: proposal.id
            };

            const overrideDecision = await updateProposalStatus({
              lender: proposal.lenderTitle,
              input
            });

            if (overrideDecision?.success) {
              const newProposal: IProposal = {
                ...proposal,
                decision
              };

              updateProposal(newProposal);
            }
          }}
          className={classes.statusSelect}
          variant="outlined"
          renderValue={(selected) => (
            <Box className={classes.statusValue}>
              <Typography variant="body2" className={classes.selectLabel}>
                Decision:
              </Typography>
              <span
                className={`${classes.statusBall} ${
                  classes[`decision${selected}`]
                }`}
              />
              {selected}
            </Box>
          )}>
          {Object.values(ELenderDecision).map((decision) => (
            <MenuItem key={decision} value={decision}>
              <span
                className={`${classes.statusBall} ${
                  classes[`decision${decision}`]
                }`}
              />
              {decision}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  useEffect(() => {
    setShowAlert(attachedFiles.length > 0 && isProposalCreated);
  }, [attachedFiles, isProposalCreated]);

  useEffect(() => {
    if (uploadError) {
      const timer = setTimeout(() => {
        setUploadError(null);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [uploadError]);

  const isDisabled = proposal.disabled || showProgress || loading;
  const buttonStyle = {
    backgroundColor: isDisabled
      ? theme.palette.action.disabledBackground
      : theme.palette.success.main,
    color: isDisabled ? theme.palette.text.disabled : 'white',
    '&:hover': {
      backgroundColor: isDisabled
        ? theme.palette.action.disabledBackground
        : theme.palette.success.dark
    },
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    opacity: isDisabled ? 0.7 : 1
  };

  const buttonText = isProposalCreated ? 'Check Status' : 'Create Proposal';

  return (
    <>
      {showProgress && (
        <Box mb={2}>
          <ProposalSubmissionProgress
            statusMessage={statusMessage}
            isCompleted={isCompleted}
            onRemove={resetProgress}
          />
        </Box>
      )}

      {uploadError && (
        <Box mb={2}>
          <Alert severity="error" onClose={() => setUploadError(null)}>
            <AlertTitle>Upload Failed</AlertTitle>
            <Typography variant="h6" color="textSecondary">
              {uploadError}
            </Typography>
          </Alert>
        </Box>
      )}

      <Box style={{ width: '100%' }}>
        {showAlert && (
          <div style={{ paddingBottom: theme.spacing(2) }}>
            <Alert
              severity="warning"
              icon={<Warning style={{ color: theme.palette.warning.main }} />}
              action={
                <Button
                  disabled={isDisabled}
                  variant="contained"
                  size="small"
                  onClick={handleUploadFiles}>
                  UPLOAD FILES
                </Button>
              }>
              <AlertTitle style={{ fontWeight: 'bold' }}>
                Selected Files Need To Be Attached To Proposal
              </AlertTitle>
              The selected files need to be attached. Please click UPLOAD FILES
              to submit them.
            </Alert>
          </div>
        )}

        <Box
          style={isProposalCreated ? { paddingBottom: theme.spacing(2) } : {}}
          className={classes.topRow}>
          <QuickUserFromId UserInstanceId={proposal.lenderId.toString()} />

          <Box className={classes.actionButtons}>
            {proposal.status ? (
              <Button
                variant="contained"
                style={buttonStyle}
                size="small"
                onClick={handleButtonClick}
                className={classes.submitButton}
                disabled={proposal.disabled || showProgress || loading}>
                {buttonText}
              </Button>
            ) : (
              <>
                {!isProposalCreated && (
                  <Button
                    variant="contained"
                    style={buttonStyle}
                    size="small"
                    onClick={handleButtonClick}
                    className={classes.submitButton}
                    disabled={proposal.disabled || showProgress || loading}>
                    Create Proposal
                  </Button>
                )}
              </>
            )}
            {proposal?.isImported ? (
              <Tooltip title="This proposal Is Created and Cannot Be Deleted">
                <IconButton size="small" disabled>
                  <Lock />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton
                size="small"
                disabled={showProgress || loading}
                onClick={() => onDelete(proposal.uniqueId)}
                className={classes.deleteIcon}>
                <Delete color="error" />
              </IconButton>
            )}

            {isProposalCreated && (
              <div style={{ paddingLeft: theme.spacing(1) }}>
                <Tooltip title="This Will Generate a Proposal Document">
                  <IconButton
                    size="small"
                    onClick={async () => {
                      const PartiesContactsODI = 3625;
                      const partiesContacts:
                        | CompleteObjectInstance
                        | undefined = Object.values(
                        currentDeal.CompleteObjectInstanceDict
                      ).find(
                        (CompleteObjectInstance: CompleteObjectInstance) => {
                          return (
                            CompleteObjectInstance.ObjectInstance
                              .ObjectDefinitionId === PartiesContactsODI
                          );
                        }
                      );
                      if (partiesContacts) {
                        const getFields =
                          processInstanceFields[currentDeal.ProcessInstance.Id];
                        const getProposal = getFields.find(
                          (FieldInstance: FieldInstance) =>
                            FieldInstance.Id === 0 &&
                            FieldInstance.Title.includes('Proposal')
                        );
                        const input: IUploadDocumentIds = {
                          dealId: currentDeal.ProcessInstance.Id,
                          baseURL,
                          generatedProposalFile: {
                            documentStepDefinitionId: getProposal
                              ? parseInt(getProposal?.FieldValue)
                              : null,
                            processStepDefinitionId: currentStepId,
                            targetObjectInstance:
                              ObjectInstanceList.targetObjectInstance,
                            currentlySelectedTargetObjectInstance:
                              ObjectInstanceList.selectedObjectInstance
                          },
                          fileExplorerDocumentPaths: [],
                          fileStorageDocumentIds: [],
                          proposalId: proposal.id.toString(),
                          userInstanceId: user.Id,
                          userInstanceEmail: user.UserInstanceEmail
                        };
                        const generateDocumentId =
                          await generateProposalDocument(input);
                        const getFile = await firebase
                          .firestore()
                          .collection('deal')
                          .doc(currentDeal.ProcessInstance.Id.toString())
                          .collection('files')
                          .doc(generateDocumentId)
                          .get();
                        if (getFile.exists) {
                          const getFbFileRef = getFile.data() as FbFileRef;
                          setFiles([...files, getFbFileRef]);
                        }
                      } else {
                        dispatch(
                          createNotification(
                            warningNotif(
                              'Failed To Generate Document, Please Select a Primary Contact On Parties & Contacts Page'
                            )
                          )
                        );
                      }
                    }}
                    disabled={isDisabled}>
                    <CloudDownload
                      style={{ color: theme.palette.success.main }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </Box>
        </Box>

        {isProposalCreated && (
          <Box className={classes.bottomRow}>
            <Box className={classes.leftBottomRow}>{renderStatusContent()}</Box>

            {proposal.uploadDocuments && (
              <Box
                className={classes.rightBottomRow}
                onClick={(e) => e.stopPropagation()}>
                <ProposalFileDisplay
                  attachedFiles={attachedFiles}
                  files={files}
                  handleFileUpload={handleFileUpload}
                  handleFileRemove={handleFileRemove}
                  isDisabled={isDisabled}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <div style={{ paddingTop: theme.spacing(2) }}>
        <ProposalErrors
          errors={validationErrors}
          resolvedErrors={resolvedErrors}
          onErrorToggle={handleErrorToggle}
        />
      </div>
    </>
  );
};

export default ProposalHeader;
