import { Checkbox, MenuItem, Select } from '@material-ui/core';
import { firebase } from 'redux/firebase';
import { useEffect, useState } from 'react';
import { EInstanceType, ProxyInstanceSettings } from 'types/interfaces';
import Table from 'components/SimpleTable';
import { initProxySettings } from 'redux/reducers/initObjects';

interface InstanceDict {
  [key: string]: ProxyInstanceSettings;
}

export const PluginManager = () => {
  const [instances, setInstances] = useState<InstanceDict>({} as InstanceDict);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection('instance')
      .onSnapshot((querySnapshot) => {
        const instanceDict = {} as InstanceDict;
        const hostnameMap: { [key: string]: ProxyInstanceSettings } = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data() as ProxyInstanceSettings;
          const existing = hostnameMap[data.hostname];

          const activePluginCount = Object.values(data.features).filter(
            Boolean
          ).length;
          const existingActivePluginCount = existing
            ? Object.values(existing.features).filter(Boolean).length
            : 0;

          if (!existing || activePluginCount > existingActivePluginCount) {
            hostnameMap[data.hostname] = {
              ...initProxySettings,
              ...data,
              features: {
                ...initProxySettings.features,
                ...data.features
              },
              instanceType: data.instanceType || EInstanceType.DEFAULT
            };
          }
        });

        Object.values(hostnameMap).forEach((instance) => {
          const id = `${instance.PlatformInstanceId}_${instance.hostname}`;
          instanceDict[id] = instance;
        });

        setInstances(instanceDict);
      });
    return () => unsub();
  }, []);

  const handleUpdate = (
    name: string,
    prev: boolean | undefined,
    rowData: ProxyInstanceSettings
  ) => {
    const id = `${rowData.PlatformInstanceId}_${rowData.hostname}`;
    const ref = firebase.firestore().collection('instance').doc(id);
    const keyName = `features.${name}`;
    return ref.update({ [keyName]: !prev });
  };

  const handleTypeChange = (
    instanceType: EInstanceType,
    rowData: ProxyInstanceSettings
  ) => {
    const id = `${rowData.PlatformInstanceId}_${rowData.hostname}`;
    const ref = firebase.firestore().collection('instance').doc(id);
    return ref.update({ instanceType });
  };

  const columns = [
    { field: 'PlatformInstanceId', title: 'Platform_Instance' },
    { field: 'hostname', title: 'Hostname' },
    {
      field: 'instanceType',
      title: 'Instance Type',
      render: (rowData: ProxyInstanceSettings) => (
        <Select
          value={rowData.instanceType || EInstanceType.DEFAULT}
          onChange={(event) =>
            handleTypeChange(event.target.value as EInstanceType, rowData)
          }
          style={{ minWidth: 120 }}>
          <MenuItem value={EInstanceType.DEFAULT}>Default</MenuItem>
          <MenuItem value={EInstanceType.FUNDER}>Funder</MenuItem>
          <MenuItem value={EInstanceType.INTRODUCER}>Introducer</MenuItem>
          <MenuItem value={EInstanceType.ACCOUNTS}>Accounts</MenuItem>
        </Select>
      )
    },
    {
      field: 'features.bips_weekly',
      title: 'Bips Weekly',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.bips_weekly}
          onChange={() =>
            handleUpdate('bips_weekly', rowData.features.bips_weekly, rowData)
          }
        />
      )
    },
    {
      field: 'features.message_hub',
      title: 'Message Hub',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.message_hub}
          onChange={() =>
            handleUpdate('message_hub', rowData.features.message_hub, rowData)
          }
        />
      )
    },
    {
      field: 'features.address_lookup',
      title: 'Address Lookup',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.address_lookup}
          onChange={() =>
            handleUpdate(
              'address_lookup',
              rowData.features.address_lookup,
              rowData
            )
          }
        />
      )
    },
    {
      field: 'features.afs_cribsheets',
      title: 'Afs Cribsheets',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.afs_cribsheets}
          onChange={() =>
            handleUpdate(
              'afs_cribsheets',
              rowData.features.afs_cribsheets,
              rowData
            )
          }
        />
      )
    },
    {
      field: 'features.afs_legacydealviewer',
      title: 'Legacy deal viewer',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.afs_legacydealviewer}
          onChange={() =>
            handleUpdate(
              'afs_legacydealviewer',
              rowData.features.afs_legacydealviewer,
              rowData
            )
          }
        />
      )
    },
    {
      field: 'features.audit',
      title: 'Audit',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.audit}
          onChange={() =>
            handleUpdate('audit', rowData.features.audit, rowData)
          }
        />
      )
    },
    {
      field: 'features.calculator',
      title: 'Calculator',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.calculator}
          onChange={() =>
            handleUpdate('calculator', rowData.features.calculator, rowData)
          }
        />
      )
    },
    {
      field: 'features.chatter',
      title: 'Chatter',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.chatter}
          onChange={() =>
            handleUpdate('chatter', rowData.features.chatter, rowData)
          }
        />
      )
    },
    {
      field: 'features.marketing',
      title: 'Marketing Campaigns',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.marketing}
          onChange={() =>
            handleUpdate('marketing', rowData.features.marketing, rowData)
          }
        />
      )
    },
    {
      field: 'features.fca',
      title: 'FCA',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.fca}
          onChange={() => handleUpdate('fca', rowData.features.fca, rowData)}
        />
      )
    },
    {
      field: 'features.redflag',
      title: 'Redflag',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.redflag}
          onChange={() =>
            handleUpdate('redflag', rowData.features.redflag, rowData)
          }
        />
      )
    },
    {
      field: 'features.rfgql',
      title: 'Address Lookup',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.rfgql}
          onChange={() =>
            handleUpdate('rfgql', rowData.features.rfgql, rowData)
          }
        />
      )
    },
    {
      field: 'features.storage',
      title: 'Storage',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.storage}
          onChange={() =>
            handleUpdate('storage', rowData.features.storage, rowData)
          }
        />
      )
    },
    {
      field: 'features.test_flight',
      title: 'Test Flight',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.test_flight}
          onChange={() =>
            handleUpdate('test_flight', rowData.features.test_flight, rowData)
          }
        />
      )
    },
    {
      field: 'features.vrm',
      title: 'VRM',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.vrm}
          onChange={() => handleUpdate('vrm', rowData.features.vrm, rowData)}
        />
      )
    },
    {
      field: 'features.extreme_caution',
      title: 'Extreme Caution List',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.extreme_caution}
          onChange={() =>
            handleUpdate(
              'extreme_caution',
              rowData.features.extreme_caution,
              rowData
            )
          }
        />
      )
    },
    {
      field: 'features.noticeboard_instance',
      title: 'Noticeboard Instance',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.noticeboard_instance}
          onChange={() =>
            handleUpdate(
              'noticeboard_instance',
              rowData.features.noticeboard_instance,
              rowData
            )
          }
        />
      )
    },
    {
      field: 'features.dashboard',
      title: 'Dashboard',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.dashboard}
          onChange={() =>
            handleUpdate('dashboard', rowData.features.dashboard, rowData)
          }
        />
      )
    },
    {
      field: 'features.deal',
      title: 'Deal',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.deal}
          onChange={() => handleUpdate('deal', rowData.features.deal, rowData)}
        />
      )
    },
    {
      field: 'features.data',
      title: 'Data',
      render: (rowData: ProxyInstanceSettings) => (
        <Checkbox
          checked={rowData.features.data}
          onChange={() => handleUpdate('data', rowData.features.data, rowData)}
        />
      )
    }
  ];

  return (
    <div style={{ display: 'block', width: '100%', overflowX: 'auto' }}>
      <Table
        columns={columns}
        title="Plugins Manager"
        data={Object.values(instances)}
        options={{
          padding: 'dense'
        }}
      />
    </div>
  );
};
