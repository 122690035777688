import React, {
  useEffect,
  useState,
  useCallback,
  SetStateAction,
  useMemo
} from 'react';
import {
  Button,
  Card,
  CardContent,
  DialogContent,
  Divider,
  Grid,
  GridSize,
  IconButton,
  List,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Close, Filter, FilterList, Person } from '@material-ui/icons';
import { CustomDialog } from 'common/Dialog';
import { Alert, Pagination } from '@material-ui/lab';
import { GetLevel12Search } from 'redux/database/Process Summary API';
import { theme } from 'theme';
import { getDealData } from 'redux/actions/processes/getDealData';
import { debounce } from '.';
import { store } from 'redux/store';
import { SET_GLOBAL_HOSTNAME } from 'redux/actions/types';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { BugTracker } from 'Utils/Bugtracker';

interface IDealProcess {
  HostName: string | null;
  ProcessInstanceId: number;
  HostTitle: string;
  ProcessInstanceTitle: string | null;
  UserInstanceId: number;
  UserInstanceTitle: string | null;
  ProcessDefinitionId: number;
  ProcessDefinitionTitle: string | null;
  ProcessStepDefinitionId: number;
  ProcessStepDefinitionTitle: string | null;
  UserDefinitionId: number;
  UserDefinitionTitle: string | null;
  LastModified: string;
  Guid: string | null;
  ThirdPartyId: string | null;
  AssignedUserList: any[];
  ProcessStepGroupList: any[];
  SelectedFieldList: any[];
}

const DEALS_PER_API_CALL = 50;
const ITEMS_PER_PAGE = 10;
interface IFilter {
  'Deal Id': string;
  'Deal Title': string;
  'Customer Name': string;
  'Lender Name': string;
  'Supplier Name': string;
}

const INIT_FILTER: IFilter = {
  'Deal Id': '',
  'Deal Title': '',
  'Customer Name': '',
  'Lender Name': '',
  'Supplier Name': ''
};

/**
 * Component for searching and displaying Level 12 & 15 access deals across instances.
 * Handles pagination, filtering, and grouping of deals with their associated users.
 *
 * @component
 * @param {boolean} openLevel12Access - Controls the visibility of the dialog
 * @param {Function} setOpenLevel12Access - Callback to update dialog visibility
 * @param {Function} setOpen - Callback to open deal details
 */
const Level12Search = ({
  openLevel12Access,
  setOpenLevel12Access,
  setOpen
}: {
  openLevel12Access: boolean;
  setOpenLevel12Access: React.Dispatch<SetStateAction<boolean>>;
  setOpen: (open: boolean) => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [allDealProcesses, setAllDealProcesses] = useState<IDealProcess[]>([]);
  const [displayedDealProcesses, setDisplayedDealProcesses] = useState<
    IDealProcess[]
  >([]);
  const [searchFields, setSearchFields] = useState<string | undefined>(
    undefined
  );
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [closed, setClosed] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilter>(INIT_FILTER);

  /**
   * Opens a specific deal and sets up the global hostname for that instance
   * @param {Object} dealProcess - Deal process data containing instance and process information
   * @returns {Promise<void>}
   */
  const openDeal = async (dealProcess: any) => {
    setLoading(true);
    const {
      ProcessInstanceId,
      ProcessStepDefinitionId,
      ProcessDefinitionId,
      HostName
    } = dealProcess.additionalInfo;

    store.dispatch({
      type: SET_GLOBAL_HOSTNAME,
      payload: {
        hostname: HostName,
        baseURL: `https://${HostName}/v1/`,
        globalHostName: HostName
      }
    });

    const data = await getDealData({
      ProcessInstanceId,
      ProcessStepDefinitionId,
      ProcessDefinitionId
    });

    if (data) {
      setLoading(false);
      setOpenLevel12Access(false);
      setOpen(true);
    }
  };

  /**
   * Fetches initial deal data based on search query and closed status
   * @param {string} [query] - Optional search query string
   * @returns {Promise<void>}
   */
  const getData = async (query?: string) => {
    try {
      const results = await GetLevel12Search({
        query,
        closed
      });

      setAllDealProcesses(results.data);
      updateDisplayedData(1, results.data);
      setFilters(INIT_FILTER);
    } catch (error) {
      console.error('Error fetching deal processes:', error);
      BugTracker.notify(error);
    }
  };

  /**
   * Extracts unique deal IDs from process data
   * @param {IDealProcess[]} data - Array of deal processes
   * @returns {number[]} Array of unique ProcessInstanceIds
   */
  const getUniqueDeals = (data: IDealProcess[]) => {
    const uniqueDeals = Array.from(
      new Set(data.map((item) => item.ProcessInstanceId))
    );
    return uniqueDeals;
  };

  /**
   * Loads additional deal data when scrolling through pagination
   * Uses offset-based pagination where each offset represents 50 deals
   * @param {number} offset - Pagination offset (0 = first 50, 1 = next 50, etc.)
   * @returns {Promise<void>}
   */
  const loadMoreData = async (offset: number) => {
    try {
      const results = await GetLevel12Search({
        query: searchFields || undefined,
        closed,
        offkeyset: offset.toString()
      });

      if (results.data.length > 0) {
        setAllDealProcesses((prev) => [...prev, ...results.data]);
      }
    } catch (error) {
      console.error('Error fetching more deal processes:', error);
      BugTracker.notify(error);
    }
  };

  /**
   * Calculates total number of pages based on unique deals
   * @param {IDealProcess[]} data - Array of deal processes
   * @returns {number} Total number of pages
   */
  const getTotalPages = (data: IDealProcess[]) => {
    const uniqueDeals = getUniqueDeals(data);
    return Math.ceil(uniqueDeals.length / ITEMS_PER_PAGE);
  };

  /**
   * Updates displayed deals based on current page
   * Handles loading more data when approaching the end of current dataset
   * @param {number} page - Current page number
   * @param {IDealProcess[]} [data] - Optional array of deal processes, defaults to allDealProcesses
   */
  const updateDisplayedData = (
    page: number,
    data: IDealProcess[] = allDealProcesses
  ) => {
    const uniqueDeals = getUniqueDeals(data);
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, uniqueDeals.length);

    // Check if we need to load more data
    // If we're showing deals close to the end of our current set of unique deals
    // AND the number of unique deals is close to or at a multiple of DEALS_PER_API_CALL
    if (
      endIndex >= uniqueDeals.length - ITEMS_PER_PAGE &&
      uniqueDeals.length >= DEALS_PER_API_CALL - ITEMS_PER_PAGE &&
      uniqueDeals.length % DEALS_PER_API_CALL < ITEMS_PER_PAGE
    ) {
      const nextOffset = Math.floor(uniqueDeals.length / DEALS_PER_API_CALL);
      loadMoreData(nextOffset);
    }

    // Filter data to only include deals for current page
    const dealsForPage = uniqueDeals.slice(startIndex, endIndex);
    const displayData = data.filter((item) =>
      dealsForPage.includes(item.ProcessInstanceId)
    );

    setDisplayedDealProcesses(displayData);
  };

  const debouncedGetData = useCallback(
    debounce((query) => {
      getData(query || undefined);
    }, 1000),
    [closed]
  );

  /**
   * Handles search field changes with debounced API calls
   * @param {React.ChangeEvent<HTMLInputElement>} e - Change event from search input
   */
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchFields(value);
    setPageIndex(1);
    debouncedGetData(value);
  };

  /**
   * Toggles between open and closed deals
   * @param {React.ChangeEvent<{value: unknown}>} e - Change event from select input
   */
  const handleSelectChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = e.target.value as string;
    setClosed(selectedValue === 'closed');
    setPageIndex(1);
  };

  /**
   * Updates individual filter criteria
   * @param {string} filterName - Name of the filter to update
   * @param {string} value - New filter value
   */

  const handleFilterChange = (filterName: string, value: string) => {
    setFilters((prev) => ({
      ...prev,
      [filterName]: value
    }));
  };

  /**
   * Handles pagination changes and updates displayed data
   * @param {React.ChangeEvent<unknown>} event - Change event from pagination
   * @param {number} value - New page number
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageIndex(value);
    updateDisplayedData(value);
  };

  /**
   * Groups deal processes by ProcessInstanceId and user type
   * Creates a hierarchical structure for easier rendering
   * @param {IDealProcess[]} data - Array of deal processes
   * @returns {Object} Grouped deal data with user types and additional info
   */
  const groupData = (data: IDealProcess[]) => {
    const grouped: { [key: number]: any } = {};

    data.forEach((item) => {
      const id = item.ProcessInstanceId;
      const userType = item.UserDefinitionTitle || 'Unknown';

      if (!grouped[id]) {
        grouped[id] = {};
      }

      if (!grouped[id][userType]) {
        grouped[id][userType] = [];
      }

      grouped[id][userType].push(item);

      if (!grouped[id].additionalInfo) {
        grouped[id].additionalInfo = { ...item };
      }
    });

    return grouped;
  };

  /**
   * Filters grouped deal data based on current filter criteria
   * @param {Object} groupedData - Grouped deal data from groupData function
   * @returns {Object} Filtered deal data matching current filter criteria
   */
  const filterGroupedData = useCallback(
    (groupedData: any) => {
      return Object.entries(groupedData).reduce(
        (acc: any, [dealId, dealGroup]: [string, any]) => {
          const matchesDealId =
            filters['Deal Id'] === '' ||
            dealGroup.additionalInfo?.ProcessInstanceId.toString().includes(
              filters['Deal Id']
            );

          const matchesDealTitle =
            filters['Deal Title'] === '' ||
            dealGroup.additionalInfo?.ProcessInstanceTitle?.toLowerCase().includes(
              filters['Deal Title'].toLowerCase()
            );

          const customers = dealGroup.Customers || [];
          const matchesCustomerName =
            filters['Customer Name'] === '' ||
            customers.some((customer: any) =>
              customer.UserInstanceTitle?.toLowerCase().includes(
                filters['Customer Name'].toLowerCase()
              )
            );

          const lenders = dealGroup.Lenders || [];
          const matchesLenderName =
            filters['Lender Name'] === '' ||
            lenders.some((lender: any) =>
              lender.UserInstanceTitle?.toLowerCase().includes(
                filters['Lender Name'].toLowerCase()
              )
            );

          const suppliers = dealGroup.Suppliers || [];
          const matchesSupplier =
            filters['Supplier Name'] === '' ||
            suppliers.some(
              (supplier: any) =>
                supplier.UserInstanceTitle === filters['Supplier Name']
            );

          if (
            matchesDealId &&
            matchesCustomerName &&
            matchesLenderName &&
            matchesDealTitle &&
            matchesSupplier
          ) {
            acc[dealId] = dealGroup;
          }

          return acc;
        },
        {}
      );
    },
    [filters]
  );

  useEffect(() => {
    if (openLevel12Access) {
      getData(searchFields || undefined);
    }
  }, [openLevel12Access, closed]);

  const groupedData = useMemo(() => {
    return groupData(displayedDealProcesses);
  }, [displayedDealProcesses]);

  const filteredData = useMemo(() => {
    return filterGroupedData(groupedData);
  }, [groupedData, filters]);

  return (
    <CustomDialog
      maxSize="md"
      open={openLevel12Access}
      handleClose={() => setOpenLevel12Access(false)}
      alert={{
        title: `Level 12 & Level 15 Access Only`,
        description:
          'Exclusive Access for Levels 12 & 15 to Discover Deals Across Instances',
        type: 'info'
      }}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Search Customers, Lenders, Deal Ids"
              value={searchFields}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={2} style={{ paddingTop: theme.spacing(2) }}>
            <Select
              variant="outlined"
              fullWidth
              margin="dense"
              value={closed ? 'closed' : 'open'}
              onChange={handleSelectChange}>
              <MenuItem value="closed">Closed Deals</MenuItem>
              <MenuItem value="open">Open Deals</MenuItem>
            </Select>
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              paddingTop: theme.spacing(1.5)
            }}>
            <Tooltip arrow title={'Filter Loaded Data'}>
              <IconButton
                onClick={() => {
                  setShowFilters(!showFilters);
                  setFilters(INIT_FILTER);
                }}>
                <FilterList
                  style={{
                    color: showFilters ? theme.palette.success.main : 'gray'
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>

          {showFilters && (
            <Grid
              container
              direction="row"
              spacing={1}
              style={{
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                paddingBottom: theme.spacing(2)
              }}>
              {Object.keys(filters).map((filterName: string, idx: number) => (
                <Grid item key={idx}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder={filterName}
                    onChange={(e) =>
                      handleFilterChange(filterName, e.target.value)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          )}

          <>
            {Object.values(filteredData).length >= 1 ? (
              <Grid container spacing={1}>
                {Object.keys(filteredData).map((dealId, idx) => {
                  const dealGroup = filteredData[dealId];
                  const userTypes = Object.keys(dealGroup).filter(
                    (k) => k !== 'additionalInfo'
                  );

                  const numUserTypes = userTypes.length;

                  let gridValue: GridSize = 12;
                  if ([1, 2, 3, 4, 6, 12].includes(numUserTypes)) {
                    gridValue = (12 / numUserTypes) as GridSize;
                  }

                  return (
                    <Grid item xs={12} key={idx}>
                      <Button
                        style={{
                          textTransform: 'none',
                          width: '100%',
                          textAlign: 'left',
                          display: 'block'
                        }}
                        onClick={() => openDeal(filteredData[dealId])}>
                        <Paper
                          elevation={1}
                          style={{
                            margin: theme.spacing(1),
                            padding: theme.spacing(1)
                          }}>
                          <div>
                            <Typography variant="h6">
                              {`Deal ID: ${dealId}`}
                            </Typography>
                            <Typography variant="h6">
                              {`Customer: ${
                                dealGroup?.Customers?.[0]?.UserInstanceTitle ??
                                'Unknown'
                              }`}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                              {`Deal Title: ${
                                dealGroup.additionalInfo
                                  ?.ProcessInstanceTitle ?? 'N/A'
                              }`}
                            </Typography>
                          </div>

                          <Typography variant="body2">
                            {`Process Definition: ${
                              dealGroup.additionalInfo
                                ?.ProcessDefinitionTitle ?? 'N/A'
                            }`}
                          </Typography>
                          <Typography variant="body2">
                            {`Instance: ${
                              dealGroup.additionalInfo?.HostName ?? 'N/A'
                            }`}
                          </Typography>

                          <Divider style={{ margin: theme.spacing(0.5) }} />
                          <Grid container spacing={1}>
                            {userTypes.map((userType, idx) => (
                              <Grid item xs={gridValue} key={idx}>
                                <Card
                                  elevation={1}
                                  style={{
                                    height: '100%'
                                  }}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontWeight: 'bold',
                                      margin: 10
                                    }}>{`User Definition: ${userType}`}</Typography>

                                  <CardContent>
                                    {openLevel12Access && (
                                      <List dense>
                                        {dealGroup[userType].map(
                                          (user, idx) => {
                                            return (
                                              <Typography
                                                variant="h6"
                                                color="textSecondary"
                                                key={idx}>
                                                {user.UserInstanceTitle}
                                              </Typography>
                                            );
                                          }
                                        )}
                                      </List>
                                    )}
                                  </CardContent>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        </Paper>
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Alert
                severity="warning"
                style={{
                  width: '100%',
                  padding: theme.spacing(1),
                  marginLeft: theme.spacing(1),
                  marginRight: theme.spacing(1)
                }}>
                <Typography>
                  {`We Couldn't Locate Any Data Matching Your Search Criteria. Please Try Adjusting Your Search Terms Or Explore Other Categories.`}
                </Typography>
              </Alert>
            )}
          </>

          <Grid
            container
            spacing={3}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              padding: theme.spacing(1)
            }}>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Showing{' '}
                {Object.keys(filteredData).length > 0
                  ? (pageIndex - 1) * ITEMS_PER_PAGE + 1
                  : 0}{' '}
                -{' '}
                {(pageIndex - 1) * ITEMS_PER_PAGE +
                  Object.keys(filteredData).length}{' '}
                deals of {getUniqueDeals(allDealProcesses).length} total deals
              </Typography>
            </Grid>
            <Grid item>
              <Pagination
                count={getTotalPages(allDealProcesses)}
                page={pageIndex}
                onChange={handlePageChange}
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </CustomDialog>
  );
};

export default Level12Search;
