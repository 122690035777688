const FundingCircleProposal = {
  broker_email: '',
  callback_url: '',
  partner_reference: '',
  business_reference: {
    companies_house_id: '',
    business_name: ''
  },
  ebo_list: [
    {
      ebo_name: {
        first_name: '',
        last_name: ''
      },
      dob: '',
      percent_shares_held: 0,
      current_address: {
        house_number_or_name: '',
        postcode: '',
        abode_number: '',
        street: '',
        city: ''
      },
      previous_addresses: [
        {
          house_number_or_name: '',
          postcode: '',
          abode_number: '',
          street: '',
          city: ''
        }
      ]
    }
  ],
  customer_info: {
    contact_name: {
      first_name: '',
      last_name: ''
    },
    email: '',
    phone_number: ''
  },
  business_info: {
    company_type: '',
    self_stated_industry: '',
    full_time_employees: 0,
    self_stated_turnover: {
      currency: '',
      value: 0
    },
    profit_loss_amount: {
      currency: '',
      value: 0
    },
    overdraft_maximum_available: {
      currency: '',
      value: 0
    },
    overdraft_current_usage: {
      currency: '',
      value: 0
    },
    other_debt_amount: {
      currency: '',
      value: 0
    },
    self_stated_2019_turnover: {
      currency: '',
      value: 0
    },
    company_established_or_registered_in_northern_ireland: false,
    company_part_of_group_operating_in_northern_ireland: false,
    company_provides_specific_service_to_northern_ireland: false
  },
  loan_info: {
    amount_requested: {
      currency: '',
      value: 0
    },
    term_requested_months: 0
  },
  loan_purpose: {
    type: '',
    buy_land_property_to_rent: false,
    change_of_business: false,
    settle_personal_debt: false,
    opportunity_outside_uk: false,
    other_company: false
  },
  client_consent: {
    client_application_consent: false,
    client_agreement_privacy_and_tac: false
  },
  broker_commission: 0
};

export default FundingCircleProposal;
