export const TOGGLE_THEME_MODE = 'TOGGLE_THEME_MODE';
export const UPDATED_THEME = 'UPDATED_THEME';
export const RELOAD_THEME = 'RELOAD_THEME';
export const UPDATE_LOADING = 'UPDATE_LOADING';

export const UPDATE_CALCULATOR_OBJECT = 'UPDATE_CALCULATOR_OBJECT';
export const UPDATE_EXTRA_PAYMENTS = 'UPDATE_EXTRA_PAYMENTS';
export const TOGGLE_CASHFLOW_SCREEN = 'TOGGLE_CASHFLOW_SCREEN';
export const SET_SOLVE_LAYOUT_VIEW = 'SET_SOLVE_LAYOUT_VIEW';

export const SET_UPDATE_USER = 'SET_UPDATE_USER';
export const SET_COSNETICS_STATE = 'SET_COSNETICS_STATE';
export const SET_DRAW = 'SET_DRAW';
export const SET_INSTANCE_SETTINGS = 'SET_INSTANCE_SETTINGS';
export const SET_GLOBAL_HOSTNAME = 'SET_GLOBAL_HOSTNAME';
export const SET_SESSION = 'SET_SESSION';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const THEME_NAME = 'THEME_NAME';
export const UPDATE_DARK_MODE = 'UPDATE_DARK_MODE';

export const SET_GLOBAL_NOTICEBOARD = 'SET_GLOBAL_NOTICEBOARD';
export const SET_GLOBAL_NOTICEBOARD_TIME = 'SET_GLOBAL_NOTICEBOARD_TIME';
export const SET_INSTANCE_NOTICEBOARD = 'SET_INSTANCE_NOTICEBOARD';
export const SET_INSTANCE_NOTICEBOARD_TITLE = 'SET_INSTANCE_NOTICEBOARD_TITLE';
export const SET_INSTANCE_NOTICEBOARD_TIME = 'SET_INSTANCE_NOTICEBOARD_TIME';
export const SET_BRAND_NOTICEBOARD = 'SET_BRANDNOTICEBOARD';
export const SET_BRAND_NOTICEBOARD_TIME = 'SET_BRAND_NOTICEBOARD_TIME';
export const SET_BRAND_NOTICEBOARD_TITLE = 'SET_BRAND_NOTICEBOARD_TITLE';

export const SET_USER_DEFINITION = 'SET_USER_DEFINITION';
export const SET_USER_DEFINITIONS = 'SET_USER_DEFINITIONS';
export const SET_LIST = 'SET_LIST';

export const SET_CURRENT_OVERVIEW = 'SET_CURRENT_OVERVIEW';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_PROCESS_DEFINITION = 'SET_PROCESS_DEFINITION';
export const SET_PROCESS_DEFINITION_FULL = 'SET_PROCESS_DEFINITION_FULL';
export const SET_PROCESS_DEFINITIONS = 'SET_PROCESS_DEFINITIONS';
export const SET_PROCESS_DEFINITIONS_SUMMARY =
  'SET_PROCESS_DEFINITIONS_SUMMARY';
export const SET_CURRENT_PROCESS_DEFINITION = 'SET_CURRENT_PROCESS_DEFINITION';
export const SET_PROCESS_SUMMARIES = 'SET_PROCESS_SUMMARIES';
export const SET_CURRENT_DEAL = 'SET_CURRENT_DEAL';
export const SET_CURRENT_STEP_ID = 'SET_CURRENT_STEP_ID';
export const SET_CURRENT_INSTANCE = 'SET_CURRENT_INSTANCE';
export const SET_MY_PROCESSES = 'SET_MY_PROCESSES';
export const SET_REGULATED_STATUS = 'SET_REGULATED_STATUS';
export const SET_ENTITY_TYPE = 'SET_ENTITY_TYPE';
export const SET_USER_RELATIONSHIPS = 'SET_USER_RELATIONSHIPS';

export const REMOVE_FILE = 'REMOVE_FILE';
export const CHANGE_FILE_NAME = 'CHANGE_FILE_NAME';
export const SET_DEAL_FILES = 'SET_DEAL_FILES';
export const ADD_FILE = 'ADD_FILE';
export const ADD_USER_TO_FILE = 'ADD_USER_TO_FILE';
export const REMOVE_USER_FROM_FILE = 'REMOVE_USER_FROM_FILE';

export const AUTH = 'AUTH';
export const HOSTNAME = 'HOSTNAME';

export const SET_ALL_LIST = 'SET_ALL_LIST';
export const SET_PROCESS_FIELDS = 'SET_PROCESS_FIELDS';
export const SET_EVENTS = 'SET_EVENTS';

export const PROXY_SETTINGS = 'PROXY_SETTINGS';
export const SET_TARGET = 'SET_TARGET';
export const PROXY_INSTANCE_SETTINGS = 'PROXY_INSTANCE_SETTINGS';

export const UPDATE_FORM_VALUE = 'UPDATE_FORM_VALUE';

export const SET_PUBLIC_SETTINGS = 'SET_PUBLIC_SETTINGS';

export const CLEAR_PROCESS_OVERVIEW = 'CLEAR_PROCESS_OVERVIEW';
export const CLEAR_DEAL_PROCESS = 'CLEAR_DEAL_PROCESS';
export const SET_DEAL_PROCESSES = 'SET_DEAL_PROCESSES';

export const CLEAR_CURRENT_DEAL = 'CLEAR_CURRENT_DEAL';
export const CLEAR_DEAL_FILES = 'CLEAR_DEAL_FILES';

export const SET_CALCULATION = 'SET_CALCULATION';
export const SET_CALCULATION_ID = 'SET_CALCULATION_ID';

export const UPDATE_DEAL = 'UPDATE_DEAL';
export const CLEAR_DEAL_SUMMARY = 'CLEAR_DEAL_SUMMARY';
export const UPDATE_OBJECT = 'UPDATE_OBJECT';

export const SET_RULE_SCHEMA = 'SET_RULE_SCHEMA';
export const SET_DND_USERS = 'SET_DND_USERS';
export const ADD_DND_USER = 'ADD_DND_USER';
export const REMOVE_DND_USER = 'REMOVE_DND_USER';
export const UPDATE_DND_USER = 'UPDATE_DND_USER';
export const SET_CHECKLIST_SCHEMA = 'SET_CHECKLIST_SCHEMA';
export const UPDATE_CHECKLIST_RULE = 'UPDATE_CHECKLIST_RULE';

// Noticeboard
export const FETCH_NOTICEBOARD_REQUEST = 'FETCH_NOTICEBOARD_REQUEST';
export const FETCH_NOTICEBOARD_SUCCESS = 'FETCH_NOTICEBOARD_SUCCESS';
export const FETCH_NOTICEBOARD_FAILURE = 'FETCH_NOTICEBOARD_FAILURE';
export const CLEAR_NOTICEBOARD = 'CLEAR_NOTICEBOARD';
export interface NoticeboardInstance {
  timestamp?: number;
}
