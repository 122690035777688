import { useRef, useState } from 'react';

import { getDealData } from 'redux/actions/processes/getDealData';
import { theme } from 'theme';
import { displayDealListData, SelectByDealStatus } from '../functions/Actions';
import { handleColumnDrag } from '../functions/ColumRendering';
import { tableIcons } from '../tableIcons';

import { IColumnOrder } from '../interface';
import { ProcessSummary } from 'types/interfaces';

import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from '@material-table/core';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { Button, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { BiFilterAlt } from 'react-icons/bi';
import { Search } from '@material-ui/icons';
import { useTypedSelector } from 'redux/reducers';
import Level12Search from './Level12Search';
import { useDispatch } from 'react-redux';
import { SET_DEAL_PROCESSES } from 'redux/actions/types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const Table = ({
  saveColumnLayout,
  isActiveDealList,
  processSummaries,
  closedData,
  title,
  setSaveJSON,
  getAllData,
  setIsActiveDealList,
  setOpen
}: {
  saveColumnLayout: any;
  isActiveDealList: boolean;
  processSummaries: ProcessSummary[];
  closedData: ProcessSummary[];
  title: string;
  setSaveJSON: (saveJSON: IColumnOrder[]) => void;
  getAllData: () => void;
  setIsActiveDealList: (isActiveDealList: boolean) => void;
  setOpen: (open: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const [loadingDeal, setLoading] = useState<boolean>(false);
  const [columnOrder, setColumnOrder] = useState(saveColumnLayout);
  const [filters, setFilters] = useState<any>({});
  const [openLevel12Access, setOpenLevel12Access] = useState<boolean>(false);
  const disabled = process.env.REACT_APP_ENV === 'production';

  const { user } = useTypedSelector((s) => s.user);
  const { isMobile } = useTypedSelector((s) => s.config.navStatus);
  const NavlinkForward = React.forwardRef(
    (props: any, ref: React.Ref<unknown>) => <NavLink ref={ref} {...props} />
  );

  const handleFilterChange = (
    eventOrValue: string | React.ChangeEvent<HTMLInputElement>,
    columnId: string
  ) => {
    const value =
      typeof eventOrValue === 'string'
        ? eventOrValue
        : eventOrValue.target.value;

    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        [columnId]: value
      };
    });
  };

  const clearFilters = () => {
    setFilters({});
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    if (value.includes('Active Deals')) {
      setIsActiveDealList(true);
    } else if (value.includes('Closed Deals')) {
      setIsActiveDealList(false);
    }
  };

  const openDeal = async ({ rowData }) => {
    setLoading(true);
    const { ProcessInstanceId, ProcessStepDefinitionId, ProcessDefinitionId } =
      rowData;
    const data = await getDealData({
      ProcessInstanceId,
      ProcessStepDefinitionId,
      ProcessDefinitionId
    });

    if (data) {
      setLoading(false);
      clearFilters();
      return setOpen(true);
    }
  };

  const data: ProcessSummary[] = displayDealListData({
    isActiveDealList,
    processSummaries,
    closedData
  });

  const filteredData = data.filter((row: ProcessSummary) =>
    (Object.entries(filters) as [string, string][]).every(
      ([field, filter]: [string, string]) => {
        const column = columnOrder.find(
          (col) => col.tableData.id === Number(field)
        );

        if (!column) {
          return true;
        }

        let columnValue;
        if (column.field) {
          columnValue = row[column.field];
        } else if (column.customFilterAndSearch) {
          return column.customFilterAndSearch(filter, row);
        }

        if (
          typeof columnValue === 'string' ||
          typeof columnValue === 'number'
        ) {
          return String(columnValue)
            .toLowerCase()
            .includes(String(filter).toLowerCase());
        }

        return false;
      }
    )
  );

  const FilterRow = (rowProps) => {
    const { columns } = rowProps;
    const inactiveFilters = ['Status', 'Last Modified', 'Deal Status'];

    return (
      <tr>
        {columns.map((col, index) => {
          const isInactiveFilter = inactiveFilters.includes(col.title);
          if (isInactiveFilter) {
            return <td key={index} />;
          }

          return (
            <td key={index} style={{ padding: 5 }}>
              <FilterTextField
                initialFilter={filters[col.tableData.id.toString()] || ''}
                handleFilterChange={handleFilterChange}
                columnId={col.tableData.id.toString()}
              />
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <>
      {user.SystemAccess >= 12 && (
        <Level12Search
          setOpenLevel12Access={setOpenLevel12Access}
          openLevel12Access={openLevel12Access}
          setOpen={setOpen}
        />
      )}

      <MaterialTable
        columns={columnOrder}
        data={filteredData}
        icons={tableIcons}
        isLoading={loadingDeal}
        onRowClick={async (event, rowData, togglePanel) => {
          dispatch({ type: SET_DEAL_PROCESSES, payload: rowData });
          await openDeal({ rowData });
        }}
        options={{
          columnsButton: true,
          filtering: true,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          headerStyle: {
            zIndex: 1,
            textAlign: 'left',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          },

          grouping: true
        }}
        components={{
          Actions: () => {
            return (
              <>
                <Button>
                  <SelectByDealStatus
                    isActiveDealList={isActiveDealList}
                    handleChange={handleChange}
                  />
                </Button>
                <Button onClick={getAllData}>
                  <RefreshIcon style={{ color: 'grey' }} />
                </Button>

                {user.SystemAccess >= 12 && (
                  <Tooltip title="Level 12 & Level 15 Search">
                    <Button onClick={() => setOpenLevel12Access(true)}>
                      <Search style={{ color: 'red' }} />
                    </Button>
                  </Tooltip>
                )}
                <Button
                  style={{ color: 'grey' }}
                  key={'support'}
                  component={NavlinkForward}
                  disabled={disabled}
                  to={process.env.REACT_APP_ENV !== 'production' && '/support'}
                  startIcon={<LiveHelpIcon />}>
                  {!isMobile && 'Support'}
                </Button>
              </>
            );
          },
          FilterRow: FilterRow
        }}
        title={title}
        onColumnDragged={() =>
          handleColumnDrag({ saveColumnLayout, setSaveJSON, setColumnOrder })
        }
        onChangeColumnHidden={() => {
          const updatedColumns = [...saveColumnLayout].sort((a, b) => {
            const orderA = a.tableData?.columnOrder ?? 0;
            const orderB = b.tableData?.columnOrder ?? 0;
            return orderA - orderB;
          });

          const columnOrderToSave = updatedColumns.map((column) => ({
            title: column.title,
            columnOrder: column.tableData?.columnOrder ?? 0,
            hidden: column.hidden ? column.hidden : false
          }));

          setSaveJSON(columnOrderToSave);
        }}
      />
    </>
  );
};

export default Table;

export const debounce = (func, delay) => {
  let debounceTimer;
  const debounced = function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func(...args), delay);
  };
  debounced.cancel = () => clearTimeout(debounceTimer);
  return debounced;
};

const FilterTextField = ({
  columnId,
  initialFilter = '',
  handleFilterChange
}: {
  columnId: string;
  initialFilter: string;
  handleFilterChange: (
    event: string | React.ChangeEvent<HTMLInputElement>,
    columnId: string
  ) => void;
}) => {
  const [value, setValue] = useState(initialFilter);

  const debouncedHandleFilterChangeRef: any = useRef(
    debounce((value, columnId) => {
      handleFilterChange(value, columnId);
    }, 1000)
  );

  const handleInputChange = (e) => {
    setValue(e.target.value);
    debouncedHandleFilterChangeRef.current(e.target.value, columnId);
  };

  const handleBlur = () => {
    debouncedHandleFilterChangeRef.current.cancel();
    handleFilterChange(value, columnId);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      debouncedHandleFilterChangeRef.current.cancel();
      handleFilterChange(value, columnId);
    }
  };

  return (
    <TextField
      value={value}
      onChange={handleInputChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <BiFilterAlt />
          </InputAdornment>
        )
      }}
    />
  );
};
