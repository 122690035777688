import axios from 'axios';
import { store } from 'redux/store';
import { createNotification } from 'react-redux-notify';
import { errorNotif, successNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';

interface CreateList {
  name: string;
  userInstanceId: number;
}

export const createList = async ({ name, userInstanceId }: CreateList) => {
  const { dispatch } = store;
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

  const data = { name, hostname, userInstanceId };
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/lists`;
  const config = { headers: { token } };

  return await axios
    .post(url, data, config)
    .then((res) => {
      dispatch(createNotification(successNotif(`list: ${name} created`)));
      gtag.event({
        feature: 'Marketing',
        action: 'List Create: Success',
        message: name
      });

      return res;
    })
    .catch((e) => {
      const { errors } = e.response.data.response.body;

      errors.forEach((error) => {
        dispatch(createNotification(errorNotif(error.message)));
        BugTracker.notify(error);
      });

      return e;
    });
};
