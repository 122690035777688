import { CompleteUserDefinition } from 'types/interfaces';
import { useTypedSelector } from 'redux/reducers';
import { LinearProgress } from '@material-ui/core';
import _ from 'lodash';
import { BugTracker } from 'Utils/Bugtracker';

// useQuery test imports
import { useQuery } from '@apollo/client';
import { GET_COMPLETE_USER_INSTANCE_DETAIL } from 'graphql/UserInstanceAPI/GetUserInstanceDetail/queries';
import { GET_USER_DEFINITION } from 'graphql/UserDefinitionAPI/queries';
import { apolloClient } from 'graphql/apolloClient';

// Forms
import { CustomerForm } from './CustomerForm';
import { ContactForm } from './ContactForm';

const DetailsForm = ({ data, classes }: { data: any; classes: any }) => {
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  // Here determine if the user is a contact or a customer and display the appropriate form
  // We will also need to get the object data for the user
  const isContact = data.UserDefinitionId === 528;
  const DirectorDetailsId = 3479;
  const DirectorNumberFieldDefinitionId = 21837;
  const isCustomer = data.UserDefinitionId === 523;
  const CompanyDetailsId = 2839;
  const CustomerDetailsId = 3474;
  const EntityObjectDefinitionId = 3464;
  const EntityTypeFieldDefinitionId = 21745;

  const userInstanceId = data?.Id ? parseInt(data.Id) : undefined;
  const userDefinitionId = data?.UserDefinitionId
    ? parseInt(data.UserDefinitionId)
    : undefined;

  if (!userInstanceId) {
    BugTracker.notify(
      'src/components/User/Profile/DetailsForm: Missing required user instance ID',
      new Error('User instance ID is required')
    );
    return null;
  }

  // Implementing Apollo useQuery instead of useEffect
  const {
    data: CompleteUser,
    error: e,
    loading: l
  } = useQuery(GET_COMPLETE_USER_INSTANCE_DETAIL, {
    variables: { baseUrl, userInstanceId },
    client: apolloClient,
    skip: !userInstanceId,
    onError: (error) => {
      BugTracker.notify(
        'src/components/User/Profile/DetailsForm, Failed to fetch complete user details:',
        error
      );
    }
  });

  const CompleteObjectInstanceList =
    CompleteUser?.GetUserInstanceDetail?.CompleteObjectInstanceList;
  // END

  // Getting user definition fetching
  const {
    data: UserDefinitionData,
    error: e1,
    loading: l1
  } = useQuery(GET_USER_DEFINITION, {
    variables: { baseUrl, userDefinitionId },
    client: apolloClient,
    skip: !userDefinitionId,
    onError: (error) => {
      BugTracker.notify(
        'src/components/User/Profile/DetailsForm, Failed to fetch user definition:',
        error
      );
    }
  });
  // END

  const CompleteUserDefinition: CompleteUserDefinition =
    UserDefinitionData?.GetUserDefinitions;

  //? Do we want to also convert this into a readable error like the other Apollo Errors we have in Actions?
  if (e || l || e1 || l1)
    return (
      <div style={{ width: '100%', padding: '8px' }}>
        <LinearProgress />
      </div>
    );

  const props = {
    CompanyDetailsId,
    CompleteObjectInstanceList,
    CompleteUserDefinition,
    CustomerDetailsId,
    DirectorDetailsId,
    DirectorNumberFieldDefinitionId,
    EntityObjectDefinitionId,
    EntityTypeFieldDefinitionId,
    UserInstance: data
  };

  if (isCustomer) return <CustomerForm {...props} />;
  if (isContact) return <ContactForm {...props} />;
  return null;
};

export default DetailsForm;
