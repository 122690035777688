import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { Dashboard as DashboardLayout } from 'layouts';
import {
  Paper,
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from 'components/Fields/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTypedSelector } from 'redux/reducers';

const useStyles = makeStyles((theme) => ({
  paper: { margin: theme.spacing(4), padding: theme.spacing(4) },
  titlebk: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2)
  },
  titleFont: {
    color: theme.palette.primary.contrastText
  },
  successBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.success.main,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.contrastText
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.error.main,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.contrastText
  }
}));

const Component = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [eMessage, setEMessage] = React.useState(null);
  const [ticketNo, setTicketNo] = React.useState(null);
  const [htmlEnc, setHtmlEnc] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [topicId, setTopicId] = React.useState(0);
  const user = useTypedSelector((s) => s.user.user);

  const handleChange = (event: any) => setTopicId(event.target.value);

  const MESSAGE = {
    alert: true,
    autorespond: true,
    source: 'API',
    name: `${user.Title}`,
    email: `${user.UserInstanceEmail}`,
    phone: '3185558634X123',
    subject: `${subject}`,
    ip: '123.211.233.122',
    message: `data:text/html,${decodeURIComponent(htmlEnc)}`,
    topicId
  };

  const submit = () => {
    setEMessage(null);
    setLoading(true);

    const config: AxiosRequestConfig = {
      method: 'post',
      url: `https://osticket.api.bips.tech/v1/osticketapi.ashx`,
      data: MESSAGE
    };

    axios(config)
      .then((res) => {
        setTicketNo(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setEMessage(e.message);
        setLoading(false);
      });
  };

  const SuccessMessage = () => (
    <Grid className={classes.successBox} item>
      <h3>
        Your ticket has been successfully submitted with reference number:
      </h3>
      <h1>{ticketNo}</h1>
      <p>You will be notified in due course by email.</p>
    </Grid>
  );

  return (
    <DashboardLayout title="Support">
      <Paper className={classes.paper}>
        <div className={classes.titlebk}>
          <Typography variant="h4" className={classes.titleFont}>
            Welcome to the Support Center
          </Typography>
        </div>
        <br />
        <p>
          In order to streamline support requests and better serve you, we
          utilize a support ticket system. If you have a found a bug or
          behaviour that is unexpected or impeding your use of the platform
          please fill in a ticket below and we will review your query. Please
          try and be as detailed and specific as possible.
        </p>
        <br />

        <Grid container direction="column" spacing={2}>
          {ticketNo ? (
            <SuccessMessage />
          ) : (
            <React.Fragment>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Topic</Typography>
                <FormControl style={{ minWidth: 200, margin: 10 }}>
                  {/* <InputLabel id="demo-simple-select-label">Topic</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange}
                    value={topicId}>
                    <MenuItem value={0}>I found a bug</MenuItem>
                    <MenuItem value={1}>
                      {`I'm getting a red error message notification`}
                    </MenuItem>
                    <MenuItem value={2}>{`I'm getting logged out`}</MenuItem>
                    <MenuItem
                      value={3}>{`I have an idea for a new feature`}</MenuItem>
                    <MenuItem value={4}>{`User Experience Feedback`}</MenuItem>
                    <MenuItem
                      value={5}>{`I have an issue with the Quote`}</MenuItem>
                    <MenuItem
                      value={6}>{`I have an issue with the Proposal`}</MenuItem>
                    <MenuItem
                      value={
                        7
                      }>{`My Quote Calculation Seems Incorrect`}</MenuItem>
                    <MenuItem
                      value={
                        8
                      }>{`I Can't Raise My Commission Invoice`}</MenuItem>
                    <MenuItem value={9}>{`I can't Send a Document`}</MenuItem>
                    <MenuItem value={10}>{`Other`}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <TextField
                  handleChange={(v: string) => setSubject(v)}
                  title="Subject"
                  type="text"
                  value={subject}
                />
              </Grid>

              <Grid item>
                <TextField
                  handleChange={(v) => setHtmlEnc(v)}
                  multiline
                  title="Message"
                  type="Formatted Text"
                  value={htmlEnc}
                />
              </Grid>

              {eMessage && (
                <Grid className={classes.errorBox} item>
                  <h3>{eMessage}</h3>
                  <br />
                  <p>
                    Please ensure you have a valid email address associated with
                    your account, your current email is {MESSAGE.email}
                  </p>
                </Grid>
              )}

              <Grid item>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button color="primary" onClick={submit} variant="contained">
                    Submit
                  </Button>
                )}
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Paper>
    </DashboardLayout>
  );
};

export default Component;
