import { ELenderAPIType } from '../../interface';
import InvestecProposal from '.';
import { createTypeSafeIds } from '../../utils';

export const getInvestecIds = () => {
  return createTypeSafeIds(InvestecProposal, (_) => ({
    brokerId: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      forcedValue: 'BrokerName'
    },
    operator: {
      FieldDefinitionId: 0,
      isReadonly: true,
      required: true,
      forcedValue: 'BrokerName'
    },
    messageId: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true
    },
    submitDate: {
      FieldDefinitionId: 0,
      isReadonly: true,
      required: true,
      forcedValue: 'currentDateTime'
    },
    client: {
      id: {
        FieldDefinitionId: 0,
        isReadonly: true,
        required: true,
        forcedValue: 'ProcessInstanceId'
      },
      companyType: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: [ELenderAPIType.Dropdown, ELenderAPIType.FinanceParent],
          options: [
            'LIMITEDCOMPANY',
            'LOCALAUTHORITY',
            'PARTNERSHIP',
            'SOLETRADER',
            'OTHER',
            'PRIVATEINDIVIDUAL'
          ]
        }
      },
      statusConfirmed: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      },
      soleTrader: {
        title: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'MR',
              'MS',
              'MISS',
              'MRS',
              'DR',
              'REV',
              'MRMRS',
              'LORD',
              'LADY'
            ]
          }
        },
        forename: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        surname: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        tradingAs: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        dateOfBirth: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.DateTime, ELenderAPIType.Requirement],
            requirement: {
              dateLength: 18
            }
          }
        },
        proprietorAddress: {
          address: {
            townCity: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            streetName: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  minLength: 2
                }
              }
            },
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  minLength: 5
                }
              }
            },
            atAddressSince: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.DateTime
              }
            }
          },

          contact: {
            telephone: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Phone
              }
            },
            email: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: [ELenderAPIType.Requirement],
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          }
        }
      },
      company: {
        companyName: {
          FieldDefinitionId: 21866,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              { entityType: 'Private Individual', FieldDefinitionId: 21810 },
              { entityType: 'Non-Reg', FieldDefinitionId: 18317 }
            ]
          }
        },
        companyRegNo: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.FianceDependent,
            options: ['Limited Company']
          }
        },
        noOfPartners: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        },
        noOfSalariedPartners: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        },
        director: [
          {
            title: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: [
                  'MR',
                  'MS',
                  'MISS',
                  'MRS',
                  'DR',
                  'REV',
                  'MRMRS',
                  'LORD',
                  'LADY'
                ]
              }
            },
            forename: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [{ entityType: 'Non-Reg', FieldDefinitionId: 18420 }]
              }
            },
            surname: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [{ entityType: 'Non-Reg', FieldDefinitionId: 18427 }]
              }
            },
            address: {
              buildingName: {
                FieldDefinitionId: 18414,
                isReadonly: false,
                info: 'This contains the Full Address, Please Split it correctly'
              },
              townCity: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              streetName: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Requirement,
                  requirement: {
                    minLength: 2
                  }
                }
              },
              postcode: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Requirement,
                  requirement: {
                    minLength: 5
                  }
                }
              },
              atAddressSince: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.DateTime
                }
              }
            },
            dateOfBirth: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: [
                  ELenderAPIType.DateTime,
                  ELenderAPIType.EntityChange,
                  ELenderAPIType.Requirement
                ],
                newIdList: [
                  { entityType: 'Non-Reg', FieldDefinitionId: 18427 }
                ],
                requirement: {
                  dateLength: 18
                }
              }
            },
            contact: {
              telephone: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Phone
                }
              },
              email: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: [ELenderAPIType.Requirement],
                  requirement: {
                    maxLength: 255,
                    pattern:
                      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                    message: 'Please enter a valid email address'
                  }
                }
              }
            },
            searchPermission: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Boolean
              }
            }
          }
        ],
        partner: [
          {
            forename: {
              FieldDefinitionId: 23977,
              isReadonly: false,
              FieldDefinition: {
                type: [
                  ELenderAPIType.EntityChange,
                  ELenderAPIType.ConcatenatedBySpace
                ],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23497,
                    additionalTypes: [ELenderAPIType.ConcatenatedBySpace]
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 21810,
                    additionalTypes: [ELenderAPIType.ConcatenatedBySpace]
                  },
                  {
                    entityType: 'Non-Reg',
                    FieldDefinitionId: 18420,
                    additionalTypes: [ELenderAPIType.ConcatenatedBySpace]
                  }
                ]
              }
            },
            title: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Dropdown,
                options: [
                  'MR',
                  'MS',
                  'MISS',
                  'MRS',
                  'DR',
                  'REV',
                  'MRMRS',
                  'LORD',
                  'LADY'
                ]
              }
            },
            surname: {
              FieldDefinitionId: 23977,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23497
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 21810
                  },
                  { entityType: 'Non-Reg', FieldDefinitionId: 18427 }
                ]
              }
            },
            address: {
              buildingName: {
                FieldDefinitionId: 23984,
                isReadonly: false,
                info: 'This contains the Full Address, Please Split it correctly',
                FieldDefinition: {
                  type: ELenderAPIType.EntityChange,
                  newIdList: [
                    {
                      entityType: 'Sole Trader',
                      FieldDefinitionId: 23684
                    },
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 23962
                    },
                    { entityType: 'Non-Reg', FieldDefinitionId: 18414 }
                  ]
                }
              },
              townCity: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              streetName: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Requirement,
                  requirement: {
                    minLength: 2
                  }
                }
              },
              postcode: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Requirement,
                  requirement: {
                    minLength: 5
                  }
                }
              },
              atAddressSince: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.DateTime
                }
              }
            },
            dateOfBirth: {
              FieldDefinitionId: 23980,
              isReadonly: false,
              FieldDefinition: {
                type: [
                  ELenderAPIType.DateTime,
                  ELenderAPIType.EntityChange,
                  ELenderAPIType.Requirement
                ],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23501
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 2188
                  },
                  { entityType: 'Non-Reg', FieldDefinitionId: 18416 }
                ],
                requirement: {
                  dateLength: 18
                }
              }
            },
            contact: {
              telephone: {
                FieldDefinitionId: 21874,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: [
                    ELenderAPIType.Requirement,
                    ELenderAPIType.EntityChange
                  ],

                  requirement: {
                    minLength: 8,
                    maxLength: 80,
                    pattern: '^(0|\\+)\\d+$',
                    message:
                      'Phone number must be at least 8 digits and start with 0 or +'
                  },
                  newIdList: [
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 23624
                    },
                    { entityType: 'Non-Reg', FieldDefinitionId: 18525 }
                  ]
                }
              },
              email: {
                FieldDefinitionId: 21873,
                isReadonly: false,
                FieldDefinition: {
                  type: [
                    ELenderAPIType.EntityChange,
                    ELenderAPIType.Requirement
                  ],
                  requirement: {
                    maxLength: 255,
                    pattern:
                      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                    message: 'Please enter a valid email address'
                  },
                  newIdList: [
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 0
                    },
                    { entityType: 'Non-Reg', FieldDefinitionId: 18452 }
                  ]
                }
              }
            }
          }
        ]
      },
      address: {
        buildingName: {
          FieldDefinitionId: 23662,
          isReadonly: false,
          info: 'This contains the Full Address, Please Split it correctly',
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 0
              },
              { entityType: 'Non-Reg', FieldDefinitionId: 18432 }
            ]
          }
        },
        townCity: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        streetName: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              minLength: 2
            }
          }
        },

        postcode: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              minLength: 5
            }
          }
        },
        atAddressSince: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.DateTime
          }
        }
      },
      contact: {
        telephone: {
          FieldDefinitionId: 23976,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 23624
              },
              { entityType: 'Non-Reg', FieldDefinitionId: 18525 }
            ]
          }
        },
        email: {
          FieldDefinitionId: 21874,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.EntityChange, ELenderAPIType.Requirement],
            newIdList: [{ entityType: 'Non-Reg', FieldDefinitionId: 18452 }],
            requirement: {
              maxLength: 255,
              pattern:
                '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
              message: 'Please enter a valid email address'
            }
          }
        }
      },
      sicCode: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.EntityChange,
          newIdList: [{ entityType: 'Non-Reg', FieldDefinitionId: 21881 }]
        }
      },
      natureOfBusiness: {
        FieldDefinitionId: 21867,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.EntityChange,
          newIdList: [
            {
              entityType: 'Private Individual',
              FieldDefinitionId: 0
            },
            { entityType: 'Non-Reg', FieldDefinitionId: 18449 }
          ]
        }
      },
      vatNo: {
        FieldDefinitionId: 21875,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.EntityChange,
          newIdList: [
            {
              entityType: 'Private Individual',
              FieldDefinitionId: 0
            },
            { entityType: 'Non-Reg', FieldDefinitionId: 23547 }
          ]
        }
      },
      dateEstablished: {
        FieldDefinitionId: 23533,
        isReadonly: false,
        FieldDefinition: {
          type: [
            ELenderAPIType.EntityChange,
            ELenderAPIType.DateTime,
            ELenderAPIType.EntityDependent
          ],
          options: [
            'Limited Liability Partnership',
            'PLC',
            'Unlimited Company',
            'Sole Trader',
            'Partnership of 3 and less',
            'Partnership (4+)',
            'Government Body',
            'Non-Profit',
            'Local Authority',
            'Other-Regulated',
            'Other-Non-Regulated'
          ],
          newIdList: [
            {
              entityType: 'Private Individual',
              FieldDefinitionId: 0
            },
            { entityType: 'Non-Reg', FieldDefinitionId: 18450 }
          ]
        }
      }
    },
    brokerRef: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true
    },
    summary: {
      FieldDefinitionId: 18309,
      isReadonly: false,
      required: true
    },
    currency: {
      FieldDefinitionId: 0,
      isReadonly: true,
      required: true,
      forcedValue: 'GBP'
    },
    startDate: {
      FieldDefinitionId: 0,
      isReadonly: false,
      FieldDefinition: {
        type: ELenderAPIType.DateTime
      }
    },
    period: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Number
      }
    },
    equipType_LoanReason: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true
    },
    cost: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: [ELenderAPIType.Currency, ELenderAPIType.Requirement],
        requirement: {
          minAmount: 1
        }
      }
    },
    otherCosts: [
      {
        costType: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['UG', 'SW', 'OTHINT']
          }
        },

        amount: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        }
      }
    ],
    otherInformation: {
      FieldDefinitionId: 0,
      isReadonly: false,
      FieldDefinition: {
        type: ELenderAPIType.Requirement,
        requirement: {
          maxLength: 2000
        }
      }
    },
    invoiceChain: {
      FieldDefinitionId: 21944,
      isReadonly: false,
      FieldDefinition: {
        type: ELenderAPIType.Boolean
      }
    },
    profile: {
      FieldDefinitionId: 0,
      isReadonly: false
    },
    jointHirer: [
      {
        title: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'MR',
              'MS',
              'MISS',
              'MRS',
              'DR',
              'REV',
              'MRMRS',
              'LORD',
              'LADY'
            ]
          }
        },
        forename: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        surname: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        address: {
          townCity: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          streetName: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                minLength: 2
              }
            }
          },
          postcode: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                minLength: 5
              }
            }
          },
          atAddressSince: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.DateTime
            }
          }
        },
        dateOfBirth: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.DateTime, ELenderAPIType.Requirement],
            requirement: {
              dateLength: 18
            }
          }
        },
        contact: {
          telephone: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Phone
            }
          },
          email: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 255,
                pattern:
                  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                message: 'Please enter a valid email address'
              }
            }
          }
        }
      }
    ],
    guarantor: [
      {
        guarantorType: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['PRIVATEINDIVIDUAL', 'COMPANY']
          }
        },
        guaranteeType: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['FULL', 'REPURCHASE', 'RESIDUALVALUE', 'BALLOON']
          }
        },
        title: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: [
              'MR',
              'MS',
              'MISS',
              'MRS',
              'DR',
              'REV',
              'MRMRS',
              'LORD',
              'LADY'
            ]
          }
        },
        forename: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        surname: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true
        },
        address: {
          townCity: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          streetName: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                minLength: 2
              }
            }
          },
          postcode: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                minLength: 5
              }
            }
          },
          atAddressSince: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.DateTime
            }
          }
        },
        dateOfBirth: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.DateTime, ELenderAPIType.Requirement],
            requirement: {
              dateLength: 18
            }
          }
        },
        contact: {
          telephone: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Phone
            }
          },
          email: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 255,
                pattern:
                  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                message: 'Please enter a valid email address'
              }
            }
          }
        },
        searchPermission: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        }
      }
    ],
    frequency: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Dropdown,
        options: ['MONTHLY', 'QUARTERLY', 'HALFANNUALLY', 'ANNUALLY']
      }
    },
    paymentMethod: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Dropdown,
        options: ['CHEQUE', 'DIRECTDEBIT', 'STANDINGORDER']
      }
    },
    asset: [
      {
        equipmentType: {
          FieldDefinitionId: 18308,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.Requirement],
            requirement: {
              maxLength: 40
            }
          }
        },

        make: {
          FieldDefinitionId: 18309,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.Requirement],
            requirement: {
              maxLength: 30
            }
          }
        },
        model: {
          FieldDefinitionId: 18310,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.Requirement],
            requirement: {
              maxLength: 30
            }
          }
        },
        serialRegNo: {
          FieldDefinitionId: 21938,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.Requirement],
            requirement: {
              maxLength: 30
            }
          }
        },
        otherSerialNo: {
          FieldDefinitionId: 18454,
          isReadonly: false,
          FieldDefinition: {
            type: [ELenderAPIType.Requirement],
            requirement: {
              maxLength: 30
            }
          }
        },
        newUsed: {
          FieldDefinitionId: 18311,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['NEW', 'USED']
          }
        },
        cost: {
          FieldDefinitionId: 18314,
          isReadonly: false,
          required: true,
          info: 'Cost has to be Greater than 0',
          FieldDefinition: {
            type: [ELenderAPIType.Currency, ELenderAPIType.Requirement],
            requirement: {
              minAmount: 1
            }
          }
        },
        dateOfManufacture: {
          FieldDefinitionId: 18312,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.DateTime
          }
        },
        supplier: {
          companyName: {
            FieldDefinitionId: 21944,
            isReadonly: false,
            required: true
          },
          address: {
            townCity: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            streetName: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  minLength: 2
                }
              }
            },
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  minLength: 5
                }
              }
            },
            atAddressSince: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.DateTime
              }
            }
          },
          contact: {
            email: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          }
        }
      }
    ]
  }));
};
