import { useTypedSelector } from 'redux/reducers';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Paper,
  Divider,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Time from 'common/Time';
import { BsPinAngle } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { useDispatch } from 'react-redux';
import {
  SET_GLOBAL_NOTICEBOARD,
  SET_GLOBAL_NOTICEBOARD_TIME,
  SET_INSTANCE_NOTICEBOARD,
  SET_INSTANCE_NOTICEBOARD_TIME,
  SET_INSTANCE_NOTICEBOARD_TITLE,
  SET_BRAND_NOTICEBOARD,
  SET_BRAND_NOTICEBOARD_TIME,
  SET_BRAND_NOTICEBOARD_TITLE
} from 'redux/actions/types';
import { useNoticeboardBuilder } from 'hooks/useNoticeBoardBuilder';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      background: `linear-gradient(90deg, ${theme.palette.background.default} 10%, ${theme.palette.primary['maintrans']})`,
      overflow: 'scroll',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2)
    },
    email: { padding: theme.spacing(0) }
  })
);

const NoticeBoard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const configSettings = useTypedSelector((s) => s.config.settings);
  const { user } = useTypedSelector((s) => s.user);
  const { BrandList } = useTypedSelector((s) => s.config.settings);
  const { selectedBrand } = useNoticeboardBuilder();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const transform = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      const src = node.attribs?.src || '';
      const isBase64 = src.startsWith('data:image');
      if (isBase64) {
        return (
          <div
            key={index}
            style={{
              backgroundImage: `url(${src})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}>
            <img
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              alt=""
              style={{ visibility: 'hidden', height: '50%', width: '50%' }}
            />
          </div>
        );
      }
    }

    if (node?.children?.[0]?.data?.includes('http')) {
      return (
        <a href={node?.children?.[0]?.data} target="_blank" rel="noreferrer">
          {node?.children?.[0]?.data}
        </a>
      );
    }

    return convertNodeToElement(node, index, transform);
  };

  useEffect(() => {
    const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
    const brandsArray = BrandList?.split(',') || [];
    const brandId = brandsArray[0]; // Get the first brand

    const unsubscribe = firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance')
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const data = doc.data();
            setIsLoading(true);

            if (data) {
              if (data[hostname] && data[hostname].currentNoticeboardInstance) {
                const instanceHost = data[hostname].currentNoticeboardInstance;

                if (instanceHost) {
                  dispatch({
                    type: SET_INSTANCE_NOTICEBOARD,
                    payload: instanceHost.text
                  });

                  if (instanceHost.title) {
                    dispatch({
                      type: SET_INSTANCE_NOTICEBOARD_TITLE,
                      payload: instanceHost.title
                    });
                  }

                  dispatch({
                    type: SET_INSTANCE_NOTICEBOARD_TIME,
                    payload: instanceHost.timestamp
                  });
                }
              }

              if (data.currentNoticeboardInstance) {
                const global = data.currentNoticeboardInstance;

                dispatch({
                  type: SET_GLOBAL_NOTICEBOARD,
                  payload: global.text
                });
                dispatch({
                  type: SET_GLOBAL_NOTICEBOARD_TIME,
                  payload: global.timestamp
                });
              }

              if (brandId && data[brandId]?.currentNoticeboardInstance) {
                const brandNotice = data[brandId].currentNoticeboardInstance;
                dispatch({
                  type: SET_BRAND_NOTICEBOARD,
                  payload: brandNotice.text
                });
                if (brandNotice.title) {
                  dispatch({
                    type: SET_BRAND_NOTICEBOARD_TITLE,
                    payload: brandNotice.title
                  });
                }
                dispatch({
                  type: SET_BRAND_NOTICEBOARD_TIME,
                  payload: brandNotice.timestamp
                });
              }
            }
          }

          setIsLoading(false);
        },
        (e) => {
          BugTracker.notify(e);
          setIsLoading(false);
        }
      );

    return () => {
      unsubscribe();
      setIsLoading(false);
    };
  }, [BrandList]);

  return (
    <div data-cy="noticeboard">
      <div>
        <BsPinAngle style={{ marginRight: 8 }} />
        {'Noticeboard'}
      </div>
      <div style={{ height: theme.spacing(2) }} />

      <Paper
        style={{ padding: theme.spacing(2) }}
        variant="elevation"
        elevation={3}>
        {configSettings?.GlobalNoticeBoard !== '' &&
        configSettings?.GlobalNoticeBoard ? (
          <>
            <Typography variant="h3">Bips Wide Notice</Typography>
            <Typography variant="caption">
              <Time
                time={configSettings?.GlobalNoticeBoardLastModified}
                type="timeago"
              />
            </Typography>
            <div style={{ height: theme.spacing(2) }} />
            <div className={classes.content}>
              {ReactHtmlParser(configSettings?.GlobalNoticeBoard, {
                transform
              })}
            </div>
          </>
        ) : (
          <>
            <div style={{ height: theme.spacing(2) }} />
            <Divider />
            <div style={{ height: theme.spacing(2) }} />
            <Typography variant="h3">Platform Wide Notice</Typography>
            <Typography variant="caption">
              <Time
                time={configSettings?.BrandNoticeBoardLastModified}
                type="timeago"
              />
            </Typography>
            <div style={{ height: theme.spacing(2) }} />
            <div className={classes.content}>
              {ReactHtmlParser(configSettings?.BrandNoticeBoard, {
                transform
              })}
            </div>
          </>
        )}

        {(configSettings && configSettings.InstanceNoticeBoard !== '') ||
          (configSettings.InstanceNoticeBoard !== ' ' && (
            <>
              <div style={{ height: theme.spacing(2) }} />
              <Divider />
              <div style={{ height: theme.spacing(2) }} />
              <Typography variant="h3">
                {configSettings.InstanceNoticeBoardTitle
                  ? configSettings.InstanceNoticeBoardTitle
                  : configSettings?.Title}{' '}
                Notice
              </Typography>

              {isLoading && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <CircularProgress />
                </div>
              )}

              {!isLoading && (
                <>
                  <Typography variant="caption">
                    <Time
                      time={configSettings?.InstanceNoticeBoardLastModified}
                      type="timeago"
                    />
                  </Typography>
                  <div style={{ height: theme.spacing(2) }} />
                  <div className={classes.content}>
                    {ReactHtmlParser(configSettings?.InstanceNoticeBoard, {
                      transform
                    })}
                  </div>
                </>
              )}
            </>
          ))}
        {configSettings && configSettings?.BrandNoticeBoard && (
          // Display selected brand notice
          <>
            <div style={{ height: theme.spacing(2) }} />
            <Divider />
            <div style={{ height: theme.spacing(2) }} />
            <Typography variant="h3">
              {configSettings?.BrandNoticeBoardTitle ||
                `${selectedBrand} Notice`}
            </Typography>

            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <Typography variant="caption">
                  <Time
                    time={configSettings?.BrandNoticeBoardLastModified}
                    type="timeago"
                  />
                </Typography>
                <div style={{ height: theme.spacing(2) }} />
                <div className={classes.content}>
                  {ReactHtmlParser(configSettings?.BrandNoticeBoard, {
                    transform
                  })}
                </div>
              </>
            )}
          </>
        )}
      </Paper>
    </div>
  );
};
export default NoticeBoard;
