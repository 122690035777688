import React, { SetStateAction } from 'react';
import { Box, Fade, Typography, useTheme } from '@material-ui/core';
import { ELenderStatus, IProposal, IProposalObjects } from './interface';
import { useLenderAPIStyles } from './styles';
import { theme } from 'theme';
import RenderForm from './components';
import { Alert, AlertTitle } from '@material-ui/lab';
import MockDataButton from './components/Mocks/NorthridgeMock';

const LenderAPI = ({
  proposals,
  setProposals,
  selectedLenderId,
  ObjectInstanceList
}: {
  proposals: {
    ObjectInstanceId: number;
    proposal: IProposal;
  }[];
  setProposals: React.Dispatch<
    SetStateAction<
      {
        ObjectInstanceId: number;
        proposal: IProposal;
      }[]
    >
  >;
  selectedLenderId: number;
  ObjectInstanceList: IProposalObjects;
}) => {
  const classes = useLenderAPIStyles();
  const theme = useTheme();

  const onDelete = (proposalId: string) => {
    console.log({ proposalId, proposals });
    setProposals((prevProposals) =>
      prevProposals.filter((p) => p.proposal.uniqueId !== proposalId)
    );
  };

  const updateProposal = (updatedProposal: IProposal) => {
    setProposals((prevProposals) =>
      prevProposals.map((p) => {
        if (p.proposal.uniqueId === updatedProposal.uniqueId) {
          return { ...p, proposal: updatedProposal };
        }
        return p;
      })
    );
  };

  return (
    <Fade in timeout={300}>
      <div className={classes.root}>
        {/* {process.env.NODE_ENV === 'development' && (
          <MockDataButton
            setProposals={setProposals}
            ObjectInstanceId={ObjectInstanceList.targetObjectInstance.Id}
          />
        )} */}
        <div style={{ padding: theme.spacing(1) }}>
          {proposals.map(({ proposal }) => (
            <Box key={proposal.id} className={classes.proposal}>
              {proposal.requestedStatus === ELenderStatus.DRAFT ? (
                <div style={{ paddingBottom: theme.spacing(1) }}>
                  <Alert severity="warning">
                    <AlertTitle>Draft Mode Active</AlertTitle>
                    <Typography variant="h6" color="textSecondary">
                      Warning: Your proposal is currently in draft mode. Changes
                      will not be saved if you navigate away from this page or
                      close the window.
                    </Typography>
                  </Alert>
                </div>
              ) : (
                <div style={{ paddingBottom: theme.spacing(1) }}>
                  <Alert severity="info">
                    <Typography variant="h6" color="textSecondary">
                      The Proposal Identification: {proposal.id}
                    </Typography>
                  </Alert>
                </div>
              )}

              <RenderForm
                proposal={proposal}
                ObjectInstanceList={ObjectInstanceList}
                disabled={selectedLenderId !== proposal.lenderId}
                onDelete={onDelete}
                updateProposal={updateProposal}
              />
            </Box>
          ))}
        </div>
      </div>
    </Fade>
  );
};

export default LenderAPI;
