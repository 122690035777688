import { useHistory, matchPath } from 'react-router-dom';

import { PrivateRoute } from 'Routes';
import List from './routes/List';
import ListDetail from './routes/ListDetail';
import AddContacts from './routes/AddContacts';
import { SecondaryButton } from 'common/Button';
import { LISTS_PATHS } from './types';

const Lists = () => {
  const history = useHistory();

  const currentPath = history.location.pathname;

  const isListItemPath = matchPath(currentPath, {
    path: '/marketing/lists/:listId',
    exact: true,
    strict: false
  });

  const isListsPath = currentPath === LISTS_PATHS.lists;

  const renderBackButton = () => {
    return (
      <SecondaryButton
        onClick={() => {
          // make sure back button always takes you from listDetail to lists no matter where you redirected from
          if (isListItemPath) history.push(LISTS_PATHS.lists);
          else if (isListsPath) history.push(LISTS_PATHS.dashboard);
          else history.goBack();
        }}
        style={{ marginBottom: 10, color: 'grey' }}>
        {`« Back`}
      </SecondaryButton>
    );
  };

  return (
    <>
      {renderBackButton()}
      <PrivateRoute exact path={LISTS_PATHS.lists} component={() => <List />} />
      <PrivateRoute
        exact
        path={LISTS_PATHS.listDetail}
        component={() => <ListDetail />}
      />
      <PrivateRoute
        exact
        path={LISTS_PATHS.addContacts}
        component={() => <AddContacts />}
      />
    </>
  );
};

export default Lists;
